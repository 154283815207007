$(document).ready(function () {
    var isDropdownExist = $('#SubmenuLinks').val();
    if (isDropdownExist === "0") {
        $(".ChangeSite-list").clone().appendTo(".ChangeSite-container");
        $('.fullWidth').closest('.container').css({ width: '100%', padding: '0', 'max-width': '100%' });
    }
    else {
        return false;
    }
});

// Below function for checking whether browser is IE
function isIE() {
    var ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    var isIe = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

    return isIe;
}

function FormAccordionContinueButton(eventAccordion, currentAccordion) {
    var productChecked = $(".size input:checked").length > 0;
    const scrollDelayDuration = 1000;
    const sampleScrollTopValue = 40;
    if (!productChecked) {
        $("#formMessage").show();
        $('html, body').delay(scrollDelayDuration).animate({
            scrollTop: ($('#SampleBody').offset().top - sampleScrollTopValue)
        });
    } else {
        $("#formMessage").hide();
        var $currentItem = eventAccordion.$items.filter(".accordion-expanded:last"),
            currentIndex = eventAccordion.$items.index($currentItem),
            $nextItem = eventAccordion.$items.eq(currentIndex + 1);
    }
    if (currentAccordion.hasClass("validate-form")) {
        var valid = ValidatePageItems(this);
        if (!valid) return false;
    }

    if ($nextItem) {
        // $currentItem.addClass('accordion-inactive');
        $nextItem.removeClass("accordion-inactive");
    }

    eventAccordion.contract($currentItem, $nextItem);
    // Accordion.expand($nextItem);
    return false;
}


(function ($) {
    "use strict";

    var Accordion = function (el, options) {
        this.settings = options;
        this.$el = $(el);
        this.$items = this.$el.find("> .accordion-item, > span > .accordion-item");
        this.$titles = this.$items.find("> .accordion-title");

        // If accordion is within a align-height container
        // It needs to calculate its maxHeight on resize
        // For Tena.Alignment to set the right height
        this.shouldSetMaxHeight = this.$el.closest(".align-height").length;
        this.prependArrow = this.$el.hasClass("accordion-prepend-arrow");
        this.keepItemsOpen = this.$el.hasClass("accordion-keep-open");

        this.init();
    };

    Accordion.prototype = {
        init: function () {
            var prototypeAccordion = this,
                $firstItem = prototypeAccordion.$items.first();

            if ($firstItem.find("> .accordion-content").css("display") === "none") {
                $firstItem.addClass("accordion-collapsed");
            }

            $firstItem.attr("class", function (i, c) {
                return c.replace(/(^|\s)accordion-collapsed-\S+/g, "");
            });

            prototypeAccordion.$titles.each(function () {
                var $icon = $(this).find("i"),
                    arrow = '<i class="icon-arrow-down"></i>';

                if (!$icon.length && !prototypeAccordion.$el.hasClass("accordion-no-icon")) {
                    if (prototypeAccordion.prependArrow) {
                        $(this)
                            .wrapInner('<span class="accordion-title-content"></span>')
                            .prepend(arrow);
                    } else {
                        $(this).prepend(arrow);
                    }
                }
            });

            if (!prototypeAccordion.$el.hasClass("accordion-collapsed") &&
                !$firstItem.hasClass("accordion-collapsed") &&
                !$firstItem.hasClass("accordion-expanded")
            ) {
                prototypeAccordion.expand(prototypeAccordion.$items.first());
            }

            prototypeAccordion.$items.each(function () {
                var $item = $(this);
                if (!$item.hasClass("accordion-expanded")) {
                    $item.addClass("accordion-collapsed");
                }
            });

            prototypeAccordion.bindEvents();

            if (prototypeAccordion.shouldSetMaxHeight) {
                prototypeAccordion.$el.addClass("dynamic-height");
            }
        },

        bindEvents: function () {
            var eventAccordion = this;

            eventAccordion.$el.on(
                "click",
                "> .accordion-item > .accordion-title, > span > .accordion-item .accordion-title",
                function (e) {
                    var $this = $(this),
                        // $item = $this.parent();
                        $item = $this.closest(".accordion-item"),
                        disableClass = $item.attr("data-accordion-disable");

                    // Disable click on specific element
                    // Example:
                    // <div class="accordion-item" data-accordion-disable="field-checkbox-container">
                    if (disableClass) {
                        if ($(e.target).hasClass(disableClass) || $(e.target).parents()
                            .hasClass(disableClass)) {
                            return;
                        }
                    }

                    if ($item.hasClass("accordion-inactive")) return;
                    if ($item.hasClass("accordion-expanded")) {
                        eventAccordion.contract($item);
                    } else {
                        eventAccordion.expand($item);
                    }
                    return false;
                }
            );

            eventAccordion.$el.find(".js-accordion-continue").on("click", function () {
                FormAccordionContinueButton(eventAccordion, $(this));

            });

            if (eventAccordion.shouldSetMaxHeight) {
                $(window).on("resize", function () {
                    eventAccordion.setMaxHeight();
                });

                eventAccordion.setMaxHeight();
            }
        },

        setMaxHeight: function () {
            var maxHeight = 0,
                maxHeightAccordion = this,
                $currentItem = maxHeightAccordion.$items.filter(".accordion-expanded");

            $currentItem.removeClass("accordion-expanded");

            maxHeightAccordion.$items.each(function () {
                var $item = $(this);
                $item.addClass("accordion-expanded");
                if (maxHeightAccordion.$el.outerHeight(true) > maxHeight) {
                    maxHeight = maxHeightAccordion.$el.outerHeight(true);
                }
                $item.removeClass("accordion-expanded");
            });

            $currentItem.addClass("accordion-expanded");
            maxHeightAccordion.$el.attr("data-dynamic-height", maxHeight);
        },

        expand: function ($item) {
            var $content = $item.find("> .accordion-content"),
                maxHeightAccordion = this,
                height,
                $icon = $item.find("> .accordion-title i"),
                scrollTop = maxHeightAccordion.getScrollPosition($item);

            if (!maxHeightAccordion.keepItemsOpen) {
                maxHeightAccordion.$items.filter(".accordion-expanded").each(function () {
                    maxHeightAccordion.contract($(this));
                });
            }

            $icon.removeClass("icon-arrow-down").addClass("icon-arrow-up");
            $item.addClass("accordion-expanded").removeClass("accordion-collapsed");
            height = $content.height();

            if (scrollTop) {
                $(document).scrollTop(scrollTop);
            }

            $content
                .css({
                    height: 0,
                    opacity: 0
                })
                .animate({
                    height: height,
                    opacity: 1
                },
                    function () {
                        $content.css({ height: "" });
                    }
                );

            $.event.trigger("accordion.changed", {
                show: $item
            });
        },

        contract: function ($item, $nextItem) {
            var $content = $item.find("> .accordion-content"),
                $icon = $item.find("> .accordion-title i"),
                contractAccordion = this;

            $content
                .css({
                    height: $content.height()
                })
                .animate({
                    height: 0,
                    opacity: 0
                },
                    function () {
                        $content.css({ height: "" });
                        $icon.removeClass("icon-arrow-up").addClass("icon-arrow-down");
                        $item.removeClass("accordion-expanded").addClass("accordion-collapsed");
                    }
                )
                .delay();

            if ($nextItem) {
                $content.promise().done(function () {
                    contractAccordion.expand($nextItem);
                });
            }
        },

        getScrollPosition: function ($item) {
            var scrollAccordion = this,
                $current = scrollAccordion.$items.filter(".accordion-expanded"),
                currentHeight = $current.find("> .accordion-content").height(),
                currentIndex = scrollAccordion.$items.index($current),
                itemIndex = scrollAccordion.$items.index($item),
                $itemBefore = scrollAccordion.$items.eq(itemIndex - 1),
                scrollPos = 0,
                currentScrollPos = $(window).scrollTop();
            const scrollPosTopOffset = 70;
            if ($item.offset().top === "undefined") return scrollPos;

            if (itemIndex > currentIndex) {
                scrollPos = $item.offset().top - currentHeight - scrollPosTopOffset;
            } else {
                scrollPos = $item.offset().top - scrollPosTopOffset;
            }

            if (currentScrollPos < scrollPos) {
                scrollPos = false;
            }

            if (scrollPos && itemIndex > 0 && $itemBefore.length) {
                scrollPos = $itemBefore.offset().top - scrollPosTopOffset;
            }

            return scrollPos;
        }
    };

    $.fn.accordion = function (options) {
        var args = Array.prototype.slice.call(arguments, 1),
            $this,
            data;

        return this.each(function () {
            $this = $(this);
            data = $this.data("accordion");

            if (!data) {
                data = new Accordion(this, options)
                $this.data("accordion", (data));
            }
            if (typeof options === "string") {
                data[options](args);
            }
        });
    };

    $.fn.accordion.Constructor = Accordion;
})(jQuery);
(function ($) {
    "use strict";

    var BP = {
        init: function (options) {
            var $wrapper;

            if (typeof options.wrapper === "string") {
                var prop = options.wrapper.indexOf(".") > -1 ? "class" : "id";

                $wrapper = $("<div />")
                    .prop(prop, options.wrapper.substring(1))
                    .appendTo("body");
            } else {
                $wrapper = options.wrapper;
            }

            if (!$wrapper.length) {
                return;
            }

            document.BP = {
                wrapper: $wrapper,
                sizes: options.sizes,
                currentIndex: -1
            };

            $.each(options.sizes, function () {
                var $size = $("<div/>");

                $size.addClass(this).appendTo(document.BP.wrapper);
            });

            BP.bindEvents();
            BP.setCurrentIndex();
        },

        bindEvents: function () {
            $(window).on("resize", function () {
                BP.setCurrentIndex();
            });
        },

        setCurrentIndex: function () {
            var $breakpoint = document.BP.wrapper.children().filter(":visible"),
                oldIndex = document.BP.currentIndex,
                isFirstRun,
                isDesktop,
                size;

            size = $breakpoint.length ? $breakpoint.prop("class") : "";
            document.BP.currentIndex = BP.sizeIndex(size);

            if (document.BP.currentIndex < 0) {
                document.BP.currentIndex = document.BP.sizes.length;
                isDesktop = true;
            }

            isFirstRun = oldIndex === -1;

            if (isFirstRun && isDesktop) {
                return;
            }

            if (oldIndex !== document.BP.currentIndex) {
                $.event.trigger("breakpoint.changed");
            }
        },

        sizeIndex: function (size) {
            return $.inArray(size, document.BP.sizes);
        },

        isSize: function (operator, size) {
            var index = BP.sizeIndex(size),
                operators = {
                    "==": function (a) {
                        return document.BP.currentIndex === a;
                    },
                    "<": function (a) {
                        return document.BP.currentIndex < a;
                    },
                    ">": function (a) {
                        return document.BP.currentIndex > a;
                    },
                    "<=": function (a) {
                        return document.BP.currentIndex <= a;
                    },
                    ">=": function (a) {
                        return document.BP.currentIndex >= a;
                    }
                };

            return operators[operator](index);
        }
    };

    $.breakpoint = {
        init: function (options) {
            options = $.extend({
                wrapper: "#js-grid-breakpoints",
                sizes: []
            },
                options
            );

            BP.init(options);
        },

        size: function (operator, size) {
            return BP.isSize(arguments[0], arguments[1]);
        }
    };
})(jQuery);

function BwCalculations(divId, arg, calculator) {
    $("#bw-1-" + divId).text(calculator.calcBRWBInterventionsPerDay(arg));
    $(".better tbody tr:first td." + divId).text(calculator.calcBRWBInterventionsPerDay(arg));

    $("#bw-2-" + divId).text(calculator.calcBRWBPadChange(arg));
    $(".better tbody tr:nth-child(2) td." + divId).text(calculator.calcBRWBPadChange(arg));

    $("#bw-3-" + divId).text(calculator.calcBRWBLeakageLaundry(arg));
    $(".better tbody tr:nth-child(3) td." + divId).text(calculator.calcBRWBLeakageLaundry(arg));

    $("#bw-4-" + divId).text(calculator.calcBRWBSkincare(arg));
    $(".better tbody tr:nth-child(4) td." + divId).text(calculator.calcBRWBSkincare(arg));
}

function BwaCalculations(divId, arg, calculator) {
    $("#bwa-1-" + divId).text(calculator.calcBWAIncontinenceCarePerDay(arg));
    $(".bwa tbody tr:first td." + divId).text(calculator.calcBWAIncontinenceCarePerDay(arg));

    $("#bwa-2-" + divId).text(calculator.calcBWAPadChangeAlt(arg));
    $(".bwa tbody tr:nth-child(2) td." + divId).text(calculator.calcBWAPadChangeAlt(arg));

    $("#bwa-3-" + divId).text(calculator.calcBWALeakageLaundry(arg));
    $(".bwa tbody tr:nth-child(3) td." + divId).text(calculator.calcBWALeakageLaundry(arg));

    $("#bwa-4-" + divId).text(calculator.calcBWASkincare(arg));
    $(".bwa tbody tr:nth-child(4) td." + divId).text(calculator.calcBWASkincare(arg));
}

(function ($) {
    "use strict";

    var Calculator = function (el) {
        this.$el = $(el);
        // this.$close = this.$el.find('.message-close');
        this.id = this.$el.prop("id");
        this.max = parseFloat(this.$el.attr("max"));
        this.min = parseFloat(this.$el.attr("min"));
        this.val = parseFloat(this.$el.attr("value"));
        this.step = parseFloat(this.$el.attr("step"));
        this.corr = parseFloat(this.$el.attr("corr"));
        window.pies = [];
        this.pieData = [{
            value: 0,
            color: "#84bb36"
        },
        {
            value: 100,
            color: "#f8f5f1"
        }
        ];

        this.pieOptions = {
            animation: false,
            showTooltips: false
        };

        if ($("#chart-area-" + this.id).length) {
            window.ctx = document.getElementById("chart-area-" + this.id).getContext("2d");
            window.pies[this.id] = new Chart(window.ctx).Pie(this.pieData, this.pieOptions);
        }

        this.init();
    };
    const calcVarFifty = 50;
    const calcVarThreeSix = 365;
    const calcVarFour = 4;
    const calcVarPointEight = 0.8;
    const calcVarHundred = 100;
    const calcVarSixty = 60;
    const calVarForteen = 14;
    const calcVarTen = 10;
    const calcVarPointSeven = 2.7;
    const calcVarPointFourFive = 0.45;
    const calcVarPointFive = 0.5;
    const calcVarPointEleven = 0.11;
    const calcVarOnePointTwo = 1.2;
    const calcVarThreePointFive = 3.5;
    const calcVarPointFiveTwo = 0.527;
    Calculator.prototype = {
        init: function () {
            var calc = this;
            this.bindEvents();

            calc.doCalcs("org");
            calc.doCalcs("new");

            $("#" + this.id + " .noUi-base .noUi-background .noUi-handle").text(
                $("#new-val-" + this.id).text()
            );
        },

        bindEvents: function () {
            var calc = this;

            var slider = $("#" + this.id).noUiSlider({
                start: calc.val,
                step: calc.step,
                connect: "lower",
                orientation: "horizontal",
                range: {
                    min: calc.min,
                    max: calc.max
                }
            });

            var timeout;
            const sliderCalc = 10;
            $("#" + this.id).on("slide", function () {
                if (timeout) {
                    clearTimeout(timeout);
                }

                timeout = setTimeout(function () {
                    timeout = undefined;
                    $("#new-val-" + calc.id).text(slider.val());
                    $("#" + calc.id + " .noUi-base .noUi-background .noUi-handle").text(
                        Math.round(slider.val() * sliderCalc) / sliderCalc
                    );
                    calc.doCalcs("new");
                    calc.copyPercentage();
                }, sliderCalc);
            });

            $(".tabledropper").click(function () {
                calc.dropTable($(this));
            });

            $(".close-table").click(function () {
                calc.closeTable(
                    $(this)
                        .parent()
                        .parent()
                        .parent()
                );
            });
        },

        dropTable: function (link) {
            var $dropped = $(".dropped");
            if ($dropped.get(0)) {
                if ($(link.context.rel).hasClass("dropped")) {
                    $dropped.slideUp("fast", function () {
                        $(this).removeClass("dropped");
                    });
                    return true;
                }

                $dropped.slideUp("fast", function () {
                    $(this).removeClass("dropped");

                    $(link.context.rel).slideDown("fast", function () {
                        $(this).addClass("dropped");
                    });
                });
            } else {
                $(link.context.rel).slideDown("fast", function () {
                    $(this).addClass("dropped");
                });
            }
        },

        closeTable: function (table) {
            table.slideUp("fast", function () {
                table.removeClass("dropped");
            });
        },

        doCalcs: function (arg) {
            var divId;

            if (arg === "new") {
                divId = 2;
            } else {
                divId = 1;
            }

            BwCalculations(divId, arg, this);
            const calcRows = 4;
            this.calcImprovements("better", calcRows);

            BwaCalculations(divId, arg, this);

            this.calcImprovements("bwa", calcRows);

            $("#bb-1-" + divId).text(this.calcBBPadCost(arg));
            $(".bb tbody tr:first td." + divId).text(this.calcBBPadCost(arg));

            $("#bb-2-" + divId).text(this.calcBBLeakageLaundry(arg));
            $(".bb tbody tr:nth-child(2) td." + divId).text(this.calcBBLeakageLaundry(arg));

            $("#bb-3-" + divId).text(this.calcBBSkincare(arg));
            $(".bb tbody tr:nth-child(3) td." + divId).text(this.calcBBSkincare(arg));

            $("#bb-4-" + divId).text(this.calcBBWaste(arg));
            $(".bb tbody tr:nth-child(4) td." + divId).text(this.calcBBWaste(arg));

            this.calcImprovements("bb", calcRows);

            $("#be-1-" + divId).text(
                this.calcBELaundry(arg) + this.calcBEMaterialsCounsumption(arg)
            );
            $(".be tbody tr:first td." + divId).text(
                this.calcBELaundry(arg) + this.calcBEMaterialsCounsumption(arg)
            );

            $("#be-2-" + divId).text(
                this.calcBEEnergyFootprint(arg) + this.calcBEMaterialsCounsumption(arg)
            );
            $(".be tbody tr:nth-child(2) td." + divId).text(
                this.calcBEEnergyFootprint(arg) + this.calcBEMaterialsCounsumption(arg)
            );

            $("#be-3-" + divId).text(this.calcBELaundry(arg));
            $(".be tbody tr:nth-child(3) td." + divId).text(this.calcBELaundry(arg));

            $("#be-4-" + divId).text(this.calcBEEnergyFootprint(arg));
            $(".be tbody tr:nth-child(4) td." + divId).text(this.calcBEEnergyFootprint(arg));

            $("#be-5-" + divId).text(this.calcBEMaterialsCounsumption(arg));
            $(".be tbody tr:nth-child(5) td." + divId).text(this.calcBEMaterialsCounsumption(arg));
            const calcImprovementRows = 5;
            this.calcImprovements("be", calcImprovementRows);
        },

        copyPercentage: function () {
            $(".improvement-1").text($(".better tbody tr:nth-child(1) td.3").text());
            $(".improvement-2").text($(".bwa tbody tr:nth-child(1) td.3").text());
            $(".improvement-3").text($(".bb tbody tr:nth-child(1) td.3").text());
            $(".improvement-4").text($(".be tbody tr:nth-child(1) td.3").text());
        },

        calcImprovements: function (prefix, rows) {
            var target = "." + prefix;

            for (var i = 1; i <= rows; i++) {
                // var before = $('#'+ prefix +'-'+ i +'-1').text();
                // var after = $('#'+ prefix +'-'+ i +'-2').text();
                var before = $(target + " tbody tr:nth-child(" + i + ") td.1").text();
                var after = $(target + " tbody tr:nth-child(" + i + ") td.2").text();

                var operator = null;
                var result = null;
                const calcResultVariable = 100;
                if (before === 0) {
                    if (after / before < 1) {
                        operator = "-";
                    }

                    result = operator + "Infinity%";
                    $(target + " tbody tr:nth-child(" + i + ") td.3").html(result);
                } else {
                    result = Math.round(-(after / before) * calcResultVariable + calcResultVariable);
                    $(target + " tbody tr:nth-child(" + i + ") td.3").html(result + "%");
                }
            }
        },

        /**
            Better Resident Well-Being functions
            args: org/new
        */

        calcBRWBInterventionsPerDay: function () {
            var calcOne = this.calcBRWBPadChange("new");
            var calcTwo = this.calcBRWBLeakageLaundry("new");
            var calcThree = this.calcBRWBSkincare("new");
            return Math.round(calcOne + calcTwo + calcThree);
        },
        calcBRWBPadChange: function (arg) {
            var result = $("#" + arg + "-val-1").text() * calcVarFifty * calcVarPointEight;
            return Math.round(result);
        },
        calcBRWBLeakageLaundry: function (arg) {
            var result =
                $("#" + arg + "-val-1").text() * ($("#" + arg + "-val-5").text() / calcVarHundred) * calcVarFifty * calcVarPointEight;
            return Math.round(result);
        },
        calcBRWBSkincare: function (arg) {
            var result = $("#" + arg + "-val-3").text() / calcVarHundred * calcVarFifty * calcVarPointEight;
            return Math.round(result);
        },

        /**
            Better Working Atmosphere functions
            args: org/new
        */
        calcBWAIncontinenceCarePerDay: function (arg) {
            var calcOne = this.calcBWAPadChangeAlt(arg);
            var calcTwo = this.calcBWALeakageLaundry(arg);
            var calcThree = this.calcBWASkincare(arg);

            return Math.round(calcOne + calcTwo + calcThree);
        },
        calcBWAPadChangeAlt: function (arg) {
            var result =
                calcVarFifty *
                calcVarPointEight *
                $("#" + arg + "-val-1").text() *
                ($("#" + arg + "-val-4").text() / calcVarSixty) *
                $("#" + arg + "-val-2").text();
            return Math.round(result);
        },
        calcBWALeakageLaundry: function (arg) {
            var result =
                calcVarFifty *
                calcVarPointEight *
                $("#" + arg + "-val-1").text() *
                ($("#" + arg + "-val-5").text() / calcVarHundred) *
                (calVarForteen / calcVarSixty);
            return Math.round(result);
        },
        calcBWASkincare: function (arg) {
            var result = calcVarFifty * calcVarPointEight * ($("#" + arg + "-val-3").text() / calcVarHundred) * (calcVarTen / calcVarSixty);
            return Math.round(result);
        },

        /**
            Better for the budget functions
            args: org/new
        */
        calcBBPadCost: function (arg) {
            var calcOne = this.calcBBLeakageLaundry(arg);
            var calcTwo = this.calcBBSkincare(arg);
            var calcThree = this.calcBBWaste(arg);

            return Math.round(calcOne + calcTwo + calcThree);
        },
        calcBBLeakageLaundry: function (arg) {
            var result =
                $("#" + arg + "-val-1").text() *
                $("#" + arg + "-val-5").text() /
                calcVarHundred *
                calcVarFifty *
                calcVarPointEight *
                calcVarPointSeven;
            return Math.round(result);
        },
        calcBBSkincare: function (arg) {
            var result = $("#" + arg + "-val-3").text() / calcVarHundred * calcVarFifty * calcVarPointEight * calcVarPointFourFive;
            return Math.round(result);
        },
        calcBBWaste: function (arg) {
            var result = $("#" + arg + "-val-1").text() * calcVarPointEleven * calcVarPointFive * calcVarFifty * calcVarPointEight;
            return Math.round(result);
        },

        /**
            Better for the Environment functions
            args: org/new
        */
        calcBELaundry: function (arg) {
            //rÃ¤Ã¤tt
            var result =
                calcVarFifty *
                calcVarPointEight *
                $("#" + arg + "-val-1").text() *
                ($("#" + arg + "-val-5").text() / calcVarHundred) *
                1 *
                calcVarThreeSix;
            return Math.round(result);
        },
        calcBEEnergyFootprint: function () {
            var result = this.calcBELaundry("new") * (calcVarOnePointTwo + calcVarThreePointFive) * calcVarPointFiveTwo / calcVarFour;
            return Math.round(result);
        },
        calcBEMaterialsCounsumption: function (arg) {
            var result = calcVarFifty * calcVarPointEight * $("#" + arg + "-val-1").text() * calcVarPointEleven * calcVarThreeSix;
            return Math.round(result);
        }
    };

    $.fn.calculator = function (options) {
        var args = Array.prototype.slice.call(arguments, 1),
            $this,
            data;

        return this.each(function () {
            $this = $(this);
            data = $this.data("calculator");

            if (!data) {
                data = new Calculator(this, options);
                $this.data("calculator", (data));
            }
            if (typeof options === "string") {
                data[options](args);
            }
        });
    };

    $.fn.calculator.Constructor = Calculator;
})(jQuery);

function CarousalHammerTime(that) {
    if (typeof Hammer != "undefined") {
        var hammertime = new Hammer(that.$el.get(0));
        const HammerConst = 600;
        hammertime.on("swipeleft", function () {
            if (that.animateSwipe) {
                that.$rightArrow.addClass("show");
                setTimeout(function () {
                    that.$rightArrow.removeClass("show");
                    that.goLeft.call(that);
                }, HammerConst);
            } else {
                that.goLeft.call(that);
            }
        });
        hammertime.on("swiperight", function () {
            if (that.animateSwipe) {
                that.$leftArrow.addClass("show");
                setTimeout(function () {
                    that.$leftArrow.removeClass("show");
                    that.goRight.call(that);
                }, HammerConst);
            } else {
                that.goRight.call(that);
            }
        });
    }
}
function AutoPlayMouseEnter(slideEvent, that) {
    if (slideEvent.autoPlay) {
        slideEvent.$el.on("mouseenter", function () {
            that.stopAutoPlay();
        });

        if (slideEvent.$pager) {
            slideEvent.$pager.on("mouseenter", function () {
                that.stopAutoPlay();
            });
        }
    }
}

function CarousalCurrentSlide(currentSlide, currentStartPosition, currentEndPosition, slide, that) {
    currentSlide
        .css({
            left: currentStartPosition
        })
        .velocity({
            left: currentEndPosition
        }, {
            duration: slide.slideSpeed,
            easing: slide.slideEasing,
            queue: false,
            complete: function () {
                currentSlide.removeClass("current").hide();
                that.changeSlideEnd.call(that);
            }
        });
}
(function ($) {
    "use strict";

    var Carousel = function (el, options) {
        this.settings = options;

        this.$el = $(el);
        this.$slidesContainer = this.$el.find(".carousel-item-container");
        this.$slides = this.$slidesContainer.children();
        this.$controls = null;
        this.$leftArrow = null;
        this.$rightArrow = null;

        this.changingSlide = false;
        this.transitionType = this.$el.is(".carousel-transition-type-slide") ? "slide" : "fade";
        this.slideSpeed = 1200;
        this.slideEasing = "swing";
        this.slideOffset = 60;
        this.fadeInSpeed = 700;
        this.fadeOutSpeed = 300;

        this.autoPlay = this.$el.is(".carousel-autoplay");
        this.autoPlayTimer = null;
        this.autoPlayDelay = 3000;

        this.animateSwipe = this.$el.is(".carousel-controls-swipe-animate");

        this.isHeightSet = false;

        this.init();
    };

    Carousel.prototype = {
        movingArrow: false,

        init: function () {
            this.setUp();
            this.bindEvents();
            this.startAutoPlay();
        },

        bindEvents: function () {
            var that = this;
            if (this.$slides.length > 1) {
                this.$rightArrow.on("click", function () {
                    that.goRight.call(that);
                });

                this.$leftArrow.on("click", function () {
                    that.goLeft.call(that);
                });
                $(document).on("keypress", function (event) {
                    if (event.keyCode === 13) { // 39 is the key code for the right arrow key
                        that.goRight.call(that);
                    } else if (event.keyCode === 13) { // 37 is the key code for the left arrow key
                        that.goLeft.call(that);
                    }
                });
                CarousalHammerTime(that);

                if (this.$pager) {
                    this.$pager.on("click", "li", function () {
                        that.changeTo($(this).index());
                    });
                }

                AutoPlayMouseEnter(this, that)
            }
            var resizeTimer = undefined;

            $(window).resize(function () {
                const timerConst = 200;
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(function () {
                    that.setCarouselHeight();
                    that.isHeightSet = false;
                }, timerConst);
            });

            $(window).on("breakpoint.changed", function () {
                if ($.breakpoint.size("<", "sm")) {
                    that.stopAutoPlay();
                } else {
                    that.startAutoPlay();
                }

                if ($.breakpoint.size("==", "xxs")) {
                    that.mobileArrows();
                } else if ($.breakpoint.size(">", "xxs")) {
                    that.$leftArrow.removeAttr("style");
                    that.$rightArrow.removeAttr("style");
                }
            });
        },

        mobileArrows: function () {
            var C = this,
                wait = 600,
                delay = 400;

            if (!C.animateSwipe) return;

            setTimeout(function () {
                C.$leftArrow.addClass("show");
                C.$rightArrow.addClass("show");

                setTimeout(function () {
                    C.$leftArrow.removeClass("show");
                    C.$rightArrow.removeClass("show");
                }, delay + wait);
            }, wait);
        },

        setUp: function () {
            this.initializeElements();
            if (this.$slides.length > 1) {
                this.$slides.css({ position: "absolute" });
                this.$controls.append(this.$leftArrow, this.$rightArrow, this.$pager);
                this.$controls.insertAfter(this.$slidesContainer);
            }
        },

        initializeElements: function () {

            this.setCarouselHeight();

            this.$controls = this.getEl("carousel-controls");
            this.$leftArrow = this.getEl("carousel-go-left");
            this.$leftArrow.append('<i tabindex="0" aria-label="Previous slide" class="icon-carousel-left" />');
            this.$rightArrow = this.getEl("carousel-go-right");
            this.$rightArrow.append('<i tabindex="0" aria-label="Next slide" class="icon-carousel-right" />');

            if (!this.$el.is(".no-pager")) {
                this.$pager = this.getEl("carousel-pager", "ul");
                this.$pager
                    .append(
                        new Array(this.$slides.length + 1).join(
                            '<li><i class="icon-carousel-control-indicator" /></li>'
                        )
                    )
                    .children()
                    .first()
                    .addClass("active");
            }
        },

        startAutoPlay: function () {
            if (this.autoPlay && this.$slides.length > 1) {
                this.runAutoPlay();
            }
        },

        runAutoPlay: function () {
            var that = this;
            clearTimeout(this.autoPlayTimer);
            this.autoPlayTimer = setTimeout(function () {
                that.goRight();
                that.runAutoPlay();
            }, this.autoPlayDelay);
        },

        stopAutoPlay: function () {
            clearTimeout(this.autoPlayTimer);
        },

        isAttached: function ($el) {
            return $el.closest(document.documentElement);
        },

        getEl: function (className, nodeType) {
            var $el = this.$el.find("." + className);

            nodeType = nodeType || "div";

            if (!$el.length) {
                $el = $("<" + nodeType + ' class="' + className + '" />');
            }

            return $el;
        },

        getCurrentSlide: function () {
            var $current = this.$slides.filter(".current"); if (!$current.length) {
                $current = this.$slides.eq(0).addClass("current");
            }

            return $current;
        },

        getNextSlide: function (direction) {
            var $current = this.getCurrentSlide(),
                $next;

            if (direction === "left") {
                $next = $current.prev();
                if (!$next.length) {
                    $next = this.$slides.eq(-1);
                }
            } else {
                $next = $current.next();
                if (!$next.length) {
                    $next = this.$slides.eq(0);
                }
            }

            return $next;
        },

        slide: function (direction) {
            if (this.changingSlide) return;

            var that = this,
                $currentSlide = this.getCurrentSlide(),
                $nextSlide = this.getNextSlide(direction),
                currentStartPosition,
                nextStartPosition,
                currentEndPosition,
                nextEndPosition;

            currentStartPosition = 0;

            if (direction === "right") {
                currentEndPosition = -$currentSlide.width() - this.slideOffset;
                nextStartPosition = $currentSlide.width() + this.slideOffset;
                nextEndPosition = 0;
            } else {
                currentEndPosition = $currentSlide.width() + this.slideOffset;
                nextStartPosition = -$currentSlide.width() - this.slideOffset;
                nextEndPosition = 0;
            }

            this.changeSlideStart();
            CarousalCurrentSlide($currentSlide, currentStartPosition, currentEndPosition, this, that);

            $nextSlide
                .show().css({
                    left: nextStartPosition,
                    opacity: 1
                })
                .velocity({
                    left: nextEndPosition
                }, {
                    duration: this.slideSpeed,
                    easing: this.slideEasing,
                    queue: false,
                    complete: function () {
                        $nextSlide.addClass("current");
                        that.slideEnd.call(that);
                    }
                });

            this.updatePager($nextSlide.index());

            return false;
        },

        changeTo: function (index) {
            var $currentSlide = this.getCurrentSlide(),
                $nextSlide = this.$slides.eq(index);

            if (window.matchMedia('(max-width: 767px)').matches) {
                this.$slidesContainer.height($nextSlide.height());
            }

            this.$slides.css({ opacity: 1, left: 0 }).fadeOut(this.fadeOutSpeed);
            $currentSlide.removeClass("current");

            $nextSlide.addClass("current");
            $nextSlide
                .css({ left: 0 })
                .stop(true, true)
                .fadeIn(this.fadeInSpeed);

            this.updatePager(index);
            this.isHeightSet = false;
            this.setCarouselHeight();
        },

        goRight: function () {
            var $nextSlide = this.getNextSlide("right");
            if (this.transitionType === "fade") {
                this.changeTo($nextSlide.index());
            } else if (this.transitionType === "slide") {
                this.slide("right");
            }
        },

        goLeft: function () {
            var $nextSlide = this.getNextSlide("left");
            if (this.transitionType === "fade") {
                this.changeTo($nextSlide.index());
            } else if (this.transitionType === "slide") {
                this.slide("left");
            }
        },

        changeSlideStart: function () {
            this.changingSlide = true;
            this.$el.addClass("changing-slide");
        },

        changeSlideEnd: function () {
            this.changingSlide = false;
            this.$el.removeClass("changing-slide");
        },

        updatePager: function (index) {
            if (this.$pager) {
                this.$pager
                    .find("li")
                    .removeClass("active")
                    .eq(index)
                    .addClass("active");
            }
        },

        setCarouselHeight: function () {
            if (this.isHeightSet) {
                return;
            }

            var imageHeight = 80;

            var $currentSlide = this.getCurrentSlide(),
                $images = $currentSlide.find("img"),
                $image,
                height = $currentSlide.height(),
                $introCarusel = $currentSlide.find('.intro-carousel-content'),
                contentTopMargin = $introCarusel.css("marginTop") ? parseInt($introCarusel.css("marginTop").replace("px", ""), 10) : 0,
                $carouselContentHeight = $introCarusel.height() + contentTopMargin;
            $images.each(function () {
                $image = $(this);
                if ($image.height() > height) {
                    height = $image.height();
                }
            });

            /* checking IE broswer and accordingly defining Number.isNaN */
            if (isIE()) {
                if (!Number.isNaN) {
                    Object.defineProperty(Number, 'isNaN', {
                        value: function (value) {
                            return value !== value;
                        }
                    });
                }
            }

            if (window.matchMedia('(min-width: 768px)').matches && !Number.isNaN($currentSlide.css("height")) &&
                this.$el.is(".intro-carousel") && !$currentSlide.is(".has-background")) {
                if ($currentSlide.find(".intro-carousel-keyword").length === 0) {
                    height = height - imageHeight;
                }
                if (height < $carouselContentHeight) {
                    height = $carouselContentHeight;
                }
            }


            this.$slidesContainer.height("auto");
            this.$slidesContainer.css("height", height);
            this.isHeightSet = true;
        }
    };

    $.fn.carousel = function (options) {
        var args = Array.prototype.slice.call(arguments, 1),
            $this,
            data;

        return this.each(function () {
            $this = $(this);
            data = $this.data("carousel");

            if (!data) {
                data = new Carousel(this, options);
                $this.data("carousel", (data));
            }
            if (typeof options === "string") {
                data[options](args);
            }
        });
    };

    $.fn.carousel.Constructor = Carousel;
})(jQuery);
(function ($) {
    "use strict";

    var ContentExpander = function (el) {
        this.$el = $(el);

        this.settings = $.extend({
            startExpanded: false,
            collapsedHeightElement: " ",
            target: "",
            container: ".ProductFamily--Row"
        },
            this.settings,
            this.$el.data()
        );

        this.$container = this.$el.closest(this.settings.container);

        if (!this.$container.length) {
            this.$container = $("body");
        }

        this.$target = this.$container.find(this.settings.target);
        this.$collapsedHeightElement = (this.$target.find(".productBoxWrapper").outerHeight(true) + 1);
        this.$icon = this.$el.find("i");

        this.init();
    };

    ContentExpander.prototype = {
        init: function () {
            var CE = this;

            CE.$target.css("overflow", "hidden");
            CE.bindEvents();
            CE.updateHeight();
        },

        bindEvents: function () {
            var CE = this;

            CE.$el.on("click", function () {
                if (!CE.$target.hasClass("expanded")) {
                    CE.$icon.removeClass("icon-arrow-down").addClass("icon-arrow-up");

                    CE.expand();
                } else {
                    CE.$icon.removeClass("icon-arrow-up").addClass("icon-arrow-down");

                    CE.collapse();
                }

                return false;
            });

            $(window).resize(function () {
                CE.updateHeight();
            });
            $(window).load(function () {
                const heightConst = 60;
                const heightInterval = 100;
                var counter = 0;
                var interval = setInterval(function () {
                    counter++;
                    CE.updateHeight();
                    if (counter === heightConst) {
                        clearInterval(interval);
                    }
                }, heightInterval);
            });
        },

        expand: function () {
            var CE = this,
                expandedHeight;

            CE.$target.addClass("expanded");

            CE.$target.css("height", "auto");
            expandedHeight = CE.$target.height();
            CE.$target.css("height", CE.getCollapsedHeight());
            CE.$target.animate({ height: expandedHeight }, "slow");
        },

        collapse: function () {
            var CE = this;
            const collapseConst = 140;
            const collapseInterval = 500;
            CE.$target.removeClass("expanded");
            CE.$target.animate({ height: CE.getCollapsedHeight() }, "slow");

            $("html, body").animate({
                scrollTop: CE.$target.offset().top - collapseConst
            },
                collapseInterval
            );
        },

        updateHeight: function () {
            var CE = this;
            this.$collapsedHeightElement = (this.$target.find(".productBoxWrapper").outerHeight(true) + 1);
            if (!CE.$target.hasClass("expanded")) {
                CE.$target.css("height", CE.getCollapsedHeight());
            }
            else {
                if (window.matchMedia('(min-width: 991px)')) {
                    CE.$target.css("height", "auto");
                    CE.$icon.removeClass("icon-arrow-up").addClass("icon-arrow-down");
                }
                CE.$icon.addClass("icon-arrow-up").removeClass("icon-arrow-down");
            }
        },

        getCollapsedHeight: function () {
            return (
                this.$collapsedHeightElement
            );
        }
    };

    $.fn.contentExpander = function (options) {
        var args = Array.prototype.slice.call(arguments, 1),
            $this,
            data;

        return this.each(function () {
            $this = $(this);
            data = $this.data("contentExpander");

            if (!data) {
                data = new ContentExpander(this, options);
                $this.data("contentExpander", (data));
            }
            if (typeof options === "string") {
                data[options](args);
            }
        });
    };

    $.fn.contentExpander.Constructor = ContentExpander;
})(jQuery);
(function ($) {
    "use strict";

    var Form = function (el, options) {
        this.settings = options;
        this.$el = $(el);
        this.$fields = this.$el.find(".form-field");
        this.originalValues = this.$el.serialize();
        this.originalValuesArray = this.$el.serializeArray();
        // If set to true when form is submitted, bypass validation
        this.bypassValidation = false;
        this.supportsPlaceholders = document.createElement("input").placeholder !== undefined;

        this.init();
    };

    Form.prototype = {
        iconValid: "icon-validation-tick",
        iconInvalid: "icon-validation-cross",

        init: function () {
            this.bindEvents();
            this.$fields
                .filter(".field-textarea")
                .css("overflow", "hidden")
                .autogrow();
            this.loadScrollPosition();
        },

        bindEvents: function () {
            var inputForm = this;

            inputForm.$el.on("submit", function () {
                if (inputForm.bypassValidation) {
                    return true;
                }

                inputForm.bypassValidation = false;
                return inputForm.validateForm();
            });

            // Validate field on change

            inputForm.$el.find("input, textarea, select").on("change input", function () {
                const timeoutInterval = 50;
                if ($(this).hasClass("costField")) {
                    var $formField = $(this).closest(".form-field");
                    var curTab = $(this).attr("tabindex");
                    inputForm.validateField($formField);
                    $("input[tabindex='" + curTab + "']").focus();
                    window.setTimeout(function () {
                        $("input[tabindex='" + curTab + "']").focus();
                    }, timeoutInterval);
                }
            });

            inputForm.$el.find("input, textarea, select").on("blur", function () {
                var $formField = $(this).closest(".form-field");
                if (!$(this).hasClass("costField")) {
                    inputForm.validateField($formField);
                }
            });

            inputForm.$el.find(".field-captcha").on("change", function () {
                inputForm.validateField($(this));
            });

            if (!Tena.browser.ie7) {
                inputForm.$el.find(".field-select").selectOrDie();

                // Reload select or die in forms inside tabs
                $(window).on("tabs.changed, accordion.changed", function (e, data) {
                    var $selects = data.show.find(".field-select");
                    $selects.selectOrDie("destroy").selectOrDie();
                });
            }

            var $passwordStrengthFields = inputForm.$el.find(".field-password-strength");
            $passwordStrengthFields.each(function () {
                var $passwordStrengthField = $(this),
                    $passwordField = $(
                        '[name="' + $passwordStrengthField.data("password-field") + '"]'
                    );
                inputForm.checkStrength($passwordField, $passwordStrengthField);
                $passwordField.keyup(function () {
                    inputForm.checkStrength($passwordField, $passwordStrengthField);
                });
            });

            inputForm.$el.on("change", function () {
                inputForm.changed();
            });

            inputForm.$el.on("change", "input:radio", function () {
                // Show or hide elements based on radio button state
                inputForm.toggleShowIfCheckedElements();
            });

            // Let select with .form-inline-action post the form without validation
            // Set selected value to a hidden field to be read by backend
            inputForm.$el.on("change", ".form-inline-action", function () {
                var $field = $(this),
                    $hiddenField = $field.find('input[type="hidden"]');

                $hiddenField.val($field.find("select").val());
                inputForm.bypassValidation = true;
                inputForm.saveScrollPosition();
                inputForm.$el.submit();
            });

            // Let button with .form-inline-action post the form without validation
            inputForm.$el.on("click", "button.form-inline-action", function () {
                inputForm.bypassValidation = true;
                inputForm.saveScrollPosition();
            });

            inputForm.$el.find("[data-form-activate-on-change]").on("change input", function () {
                var $field = $(this),
                    activate = $field.data("form-activate-on-change"),
                    $activate = $(activate);

                if ($activate.length > 0) {
                    if (inputForm.hasFieldBeenUpdated($field) && $field.val() !== "") {
                        inputForm.activateElement($activate);
                    } else {
                        inputForm.deactivateElement($activate);
                    }
                }
            });

            inputForm.$el.filter("[data-activate-on-valid]").on("change input", "input", function () {
                var activate = inputForm.$el.data("activate-on-valid"),
                    $activate = $(activate);

                if (inputForm.$el.find(".valid").length === inputForm.$fields.length) {
                    inputForm.activateElement($activate);
                } else {
                    inputForm.deactivateElement($activate);
                }
            });

            // Toggle password field visibility
            inputForm.$el.on("change", "[data-toggle-password]", function () {
                var $this = $(this),
                    $input = $this.find("input"),
                    type = $input.is(":checked") ? "text" : "password",
                    targets = $this.data("toggle-password").split(",");

                $.each(targets, function () {
                    var $target = $('[name="' + this + '"');
                    $target.prop("type", type);
                });
            });

            inputForm.$el.on("click", ".delete-row", function () {
                var $row = $(this).closest("tr");
                inputForm.toggleRowDeleted($row);

                return false;
            });

            // Placeholder fallback
            if (!this.supportsPlaceholders) {
                var $placeholderFields = this.$fields.find("[placeholder]");

                $placeholderFields.each(function () {
                    var $field = $(this),
                        placeholder = $(this).attr("placeholder");

                    if (this.value.length === 0) {
                        this.value = placeholder;
                    }

                    $field
                        .on("focus", function () {
                            if (this.value === placeholder) {
                                this.value = "";
                            }
                        })
                        .on("blur", function () {
                            if ($.trim(this.value).length === 0) {
                                this.value = placeholder;
                            }
                        });
                });
            }
        },

        changed: function () {
            var stateForm = this,
                $stateButtons = stateForm.$fields.find(".btn-form-change-active"),
                newValues = stateForm.$el.serialize();

            if (newValues !== stateForm.originalValues) {
                $stateButtons.removeClass("btn-inactive").prop("disabled", false);
            } else {
                $stateButtons.addClass("btn-inactive").prop("disabled", true);
            }
        },

        trimField: function ($field, $formField) {
            if ($field.context.value) {
                //Trim other stuff also if is validate-email
                if ($formField.is(".validate-email")) {
                    $field.context.value = $field.context.value.replace(/\s/g, "");
                    $field.context.value = $field.context.value.replace(/:/g, "");
                    $field.context.value = $field.context.value.replace(/;/g, "");
                    $field.context.value = $field.context.value.replace(/,/g, "");
                }

                $field.context.value = $field.context.value.replace(/</g, "");
                $field.context.value = $field.context.value.replace(/>/g, "");
            }
        },

        hasFieldBeenUpdated: function ($field) {
            var fieldForm = this,
                name = $field.attr("name"),
                newValue = $field.val(),
                updated = false,
                found = false;

            $.each(fieldForm.originalValuesArray, function () {
                if (this.name === name) {
                    found = true;
                    if (this.value !== newValue) updated = true;
                }
            });

            if (!found) {
                updated = true;
            }

            return updated;
        },

        activateElement: function ($el) {
            var classesToRemove = "btn-inactive";
            if ($el.prop("disabled")) {
                $el.data("original-classes", $el.attr("class"));
                $el.removeClass(classesToRemove).prop("disabled", false);
            }
        },

        deactivateElement: function ($el) {
            $el.addClass($el.data("original-classes")).prop("disabled", true);
        },

        validateForm: function () {
            var validityForm = this,
                $formField,
                validForm = true,
                validField;

            validityForm.$fields.each(function () {
                $formField = $(this);

                validField = validityForm.validateField($formField);

                if (!validField) {
                    validForm = false;
                }
            });

            return validForm;
        },

        validateField: function ($formField) {
            //var cur_tab = $(this).attr('tabindex');
            //cur_tab = cur_tab + 1;

            var currentForm = this,
                $field = $formField.find(".field"),
                fieldType = $field.prop("tagName"),
                validField = true,
                $iconValid = $("<i>").addClass(currentForm.iconValid),
                $iconInvalid = $("<i>").addClass(currentForm.iconInvalid),
                bypass = currentForm.bypass($formField);
            currentForm.trimField($field, $formField);

            $formField.find(".validate-message").hide();
            $formField.find("." + currentForm.iconValid).remove();
            $formField.find("." + currentForm.iconInvalid).remove();
            $formField.find(".invalid").removeClass("invalid");

            //$('input').attr('tabindex', cur_tab).focus();
            //console.log(cur_tab);

            if (bypass) {
                return true;
            }

            if (!fieldType || fieldType === "") {
                fieldType = "none";
            } else {
                fieldType = fieldType.toLowerCase();
            }

            if (
                $field.closest(".validate").length === 0 &&
                !$field.is(".field-radio") &&
                !$field.is(".field-checkbox")
            ) {
                if ($field.is("select") && $field.parent().is(".sod_select")) {
                    $field
                        .closest(".sod_select")
                        .wrap($("<div>").addClass("validate validate-" + fieldType));
                } else {
                    $field.wrap($("<div>").addClass("validate validate-" + fieldType));
                }
            }

            $field.closest(".validate").removeClass("invalid valid");

            if (!currentForm.supportsPlaceholders && $field.is("[placeholder]")) {
                if ($field.val() === $field.attr("placeholder")) {
                    $field.val("");
                }
            }

            if ($formField.is(".validate-required")) {
                if ($field.is(".field-radio")) {
                    // Radio buttons
                    var found = false;

                    $formField.find("input").each(function () {
                        if ($(this).is(":checked")) {
                            found = true;
                        }
                    });

                    if (!found) {
                        validField = false;
                    }
                    // } else if($field.is('.field-checkbox') && !$field.prop('checked')) { // Checkbox
                    //  validField = false;
                } else if ($field.is(".field-checkbox")) {
                    // Checkbox
                    validField = false;
                    $field.each(function () {
                        if ($(this).prop("checked")) {
                            validField = true;
                        }
                    });
                } else if ($.trim($field.val()).length === 0) {
                    // Default
                    validField = false;
                }

                if (!validField) {
                    $formField.find(".validate-message-required").show();
                }
            }

            if (validField && $formField.is(".validate-email")) {
                if (!/\S+@\S+\.\S+/.test($field.val())) {
                    validField = false;
                    $formField.find(".validate-message-email").show();
                }
            }

            if (validField && $formField.is(".validate-no-numbers")) {
                if (currentForm.checkIfContainsNumbers($field.val())) {
                    validField = false;
                    $formField.find(".validate-message-no-numbers").show();
                }
            }

            if (validField && $formField.is(".validate-match")) {
                var $matchesMessage = currentForm.$el.find(".field-password-matches"),
                    $match = $('[name="' + $formField.data("match") + '"]'),
                    matchValue = $match.val(),
                    fieldValue = $field.val();

                if ($formField.is(".validate-email")) {
                    matchValue = $match.val().toLowerCase();
                    fieldValue = $field.val().toLowerCase();
                }

                if (matchValue !== fieldValue) {
                    validField = false;
                    $formField.find(".validate-message-match").show();
                    $matchesMessage.addClass("hide");
                } else {
                    $matchesMessage.removeClass("hide");
                }
            }

            if (validField && $formField.is(".validate-length")) {
                var $lengthMessageMin = $formField.find(".validate-message-length-min"),
                    $lengthMessageMax = $formField.find(".validate-message-length-max"),
                    $min = $formField.data("min"),
                    $max = $formField.data("max");

                if ($field.val().length < $min) {
                    validField = false;
                    $lengthMessageMax.hide();
                    $lengthMessageMin.show();
                } else if ($field.val().length > $max) {
                    validField = false;
                    $lengthMessageMin.hide();
                    $lengthMessageMax.show();
                } else {
                    $lengthMessageMax.hide();
                    $lengthMessageMin.hide();
                }
            }

            if (validField && $formField.is(".validate-number")) {
                var $numericMessage = $formField.find(".validate-message-numeric"),
                    value = $field.val(),
                    min = $field.data("min"),
                    max = $field.data("max"),
                    regex = new RegExp(/^[\d.]+$/);

                if (value.length && (!regex.test(value) || value < min || value > max)) {
                    validField = false;
                    $numericMessage.show();
                } else {
                    $numericMessage.hide();
                }
            }

            if ($formField.is(".field-captcha")) {
                var captchaResponse = $('#g-recaptcha-response').val();
                if (!captchaResponse) {
                    validField = false;
                    $formField.find(".validate-message").show();
                }
            }

            if (!validField) {
                $field
                    .closest(".validate")
                    .addClass("invalid")
                    .append($iconInvalid);
            } else {
                $field
                    .closest(".validate")
                    .addClass("valid")
                    .append($iconValid);
            }

            return validField;
        },

        checkStrength: function ($field, $strength) {
            var $strengthSpan = $strength.find("span.strength"),
                numUpper = 0,
                numLower = 0,
                numNumber = 0,
                numSpecial = 0,
                value = $field.val();

            numUpper = value.match(/[A-Z]{1}/g);
            numLower = value.match(/[a-z]{1}/g);
            numNumber = value.match(/[0-9]{1}/g);
            numSpecial = value.match(/[\[\]\^\$\.\|\?\*\+\(\)\\~`\!@#%&\-_+={}'""<>:;, ]{1}/g);

            numUpper = numUpper ? numUpper.length : 0;
            numLower = numLower ? numLower.length : 0;
            numNumber = numNumber ? numNumber.length : 0;
            numSpecial = numSpecial ? numSpecial.length : 0;
            const numConstTwo = 2;
            const numConstFour = 4;
            if (numUpper >= numConstTwo && numLower >= numConstTwo && numNumber >= numConstTwo && numSpecial >= numConstTwo) {
                $strengthSpan
                    .removeClass("medium")
                    .removeClass("weak")
                    .addClass("strong")
                    .html("Very good");
            } else if (numUpper >= numConstTwo && numLower >= numConstTwo && (numNumber >= numConstTwo || numSpecial >= numConstTwo)) {
                $strengthSpan
                    .removeClass("strong").removeClass("weak").addClass("medium").html("Good");
            } else if (
                value.length >= numConstFour &&
                (numUpper >= numConstTwo || numLower >= numConstTwo) &&
                (numNumber >= 1 || numSpecial >= 1)
            ) {
                $strengthSpan
                    .removeClass("strong").removeClass("weak").addClass("medium").html("Medium");
            } else {
                $strengthSpan
                    .removeClass("medium").removeClass("strong").addClass("weak").html("Low");
            }
        },

        bypass: function ($formField) {
            var selectors = $formField.data("bypass"),
                $field = $formField.find(".field"), bypass = false, $bypass, $that, type, $match;

            // Do not bypass if a value is set
            if ($field.val()) {
                return false;
            }

            // Do not bypass if the matching field has a value
            if ($formField.is(".validate-match")) {
                $match = $('[name="' + $formField.data("match") + '"]');
                if ($match.val() !== $field.val()) {
                    return false;
                }
            }

            if (selectors) {
                $bypass = $(selectors);

                $bypass.each(function () {
                    $that = $(this);
                    type = $that.attr("type");

                    switch (type) {
                        case "checkbox":
                        case "radio":
                            if ($that.is(":checked")) {
                                bypass = false;
                                return false;
                            } else {
                                bypass = true;
                            }
                            break;
                        case "text":
                            if ($that.val()) {
                                bypass = false;
                                return false;
                            } else {
                                bypass = true;
                            }
                            break;
                    }
                });
            }

            return bypass;
        },

        toggleShowIfCheckedElements: function () {
            var toggleShowForm = this;

            toggleShowForm.$el.find("[data-checked-show]").each(function () {
                var target = $(this).data("checked-show"),
                    checked = $(this).is(":checked"),
                    $target;

                $target = $(target);

                if (checked) {
                    $target.removeClass("hide");
                } else {
                    $target.addClass("hide");
                }
            });
        },

        saveScrollPosition: function () {
            $.cookie("scrollposition", $(window).scrollTop());
        },

        loadScrollPosition: function () {
            var scrollPosition = $.cookie("scrollposition");

            if (scrollPosition) {
                $(window).scrollTop(scrollPosition);
                $.removeCookie("scrollposition");
            }
        },

        toggleRowDeleted: function ($row) {
            var deleteState = $row.hasClass("delete"),
                $field = $row.find(".field-delete-row");

            if (deleteState) {
                $row.removeClass("delete");
                $field.val(0);
            } else {
                $row.addClass("delete");
                $field.val(1);
            }

            $(window).trigger("form-row-deleted");
        },

        checkIfContainsNumbers: function (string) {
            return /\d/.test(string);
        }
    };

    $.fn.form = function (options) {
        var args = Array.prototype.slice.call(arguments, 1),
            $this,
            data;

        return this.each(function () {
            $this = $(this);
            data = $this.data("form");

            if (!data) {
                data = new Form(this, options);
                $this.data("form", (data));
            }
            if (typeof options === "string") {
                data[options](args);
            }
        });
    };

    $.fn.form.Constructor = Form;
})(jQuery);


function AddToTotalUnits(price, usePoints, FO, units, value, totalValue, points, quantity, unit, product, $points) {
    price = FO.getNumber(product.find(".product-price").text());
    quantity = FO.getNumber(product.find(".product-quantity").val());

    // Calculate total product value
    totalValue = price * quantity;
    value.text(FO.setNumber(totalValue));

    // Calculate points
    if ($points) {
        points = FO.getNumber($points.data("points")); // * quantity;
        // $points = $points.text(points);
    }

    if (!usePoints.is(":checked")) {
        units.total += totalValue;
    } else {
        units.points += points * quantity;
    }

    // Add to total units array
    unit = product.find(".product-unit").val();
    if (unit) {
        //totalPieces = pieces * quantity;
        if (units[unit] == null) {
            units[unit] = quantity;
        } else {
            units[unit] += quantity;
        }
    }
    return units;
}
function AddUnitToSum(units, unit, value, sum, FO, decimals) {
    if (units[unit] != null) {
        value = units[unit];
    }
    sum.text(FO.setNumber(value, decimals));
}
(function ($) {
    "use strict";

    var FormOrder = function (el, options) {
        this.settings = options;
        this.$el = $(el);
        this.$products = this.$el.find(".order-product");
        this.$pointsUsed = this.$el.find(".points-used");
        this.pointsAvailable = this.getNumber(this.$el.find(".points-available").text());
        this.sumtable = null;

        this.defaults = $.extend({
            decimals: 2,
            sumTableTarget: this.$el.data("target-sum-table")
        },
            this.settings
        );

        this.init();
    };

    FormOrder.prototype = {
        init: function () {
            this.settings = $.extend({}, this.defaults, this.settings);
            this.$sumtable = $('[data-sum-table="' + this.settings.sumTableTarget + '"]');

            this.bindEvents();

            $(".js-exceeded-points")
                .css("display", "none")
                .tooltip();
        },

        bindEvents: function () {
            var FO = this;

            FO.$products.on("change", ".product-unit", function () {
                var $selected = $("option:selected", this),
                    $product = $selected.closest(".order-product");

                FO.setProductUnit($product, $selected);
                FO.calculateTotal();
            });

            FO.$products.on("change keyup", ".product-quantity", function () {
                FO.calculateTotal();
            });

            FO.$products.on("change", ".use-points", function () {
                FO.calculateTotal();
            });

            $(window).on("form-row-deleted", function () {
                FO.calculateTotal();
            });
        },

        setProductUnit: function ($product, $unit) {
            var FO = this;

            $product.find(".product-pieces").text($unit.data("pieces"));
            $product.find(".product-price").text(FO.setNumber($unit.data("price")));
            $product.find(".points-cost").data("points", $unit.data("points"));
            $product.find(".product-quantity").prop("disabled", false);
        },

        calculateTotal: function () {
            var FO = this,
                units = {
                    points: 0,
                    total: 0
                };

            // Calculate and update each product
            FO.$products.not(".delete").each(function () {
                var $product = $(this),
                    $value = $product.find(".product-value"),
                    $usePoints = $product.find(".use-points"),
                    unit,
                    // pieces,
                    price,
                    quantity,
                    // value,
                    // totalPieces,
                    totalValue,
                    // canUsePoints,
                    points;

                AddToTotalUnits(price, $usePoints, FO, units, $value, totalValue, points, quantity, unit, $product, $points);

                if (FO.pointsAvailable < units.points) {
                    var $row;

                    $(".js-exceeded-points")
                        .filter(function () {
                            $row = $(this).closest("tr");
                            if ($row.find(".use-points").is(":checked")) {
                                return true;
                            }
                        })
                        .css("display", "inline-block");
                } else {
                    $(".js-exceeded-points").css("display", "none");
                }
            });

            // Update sumtable
            FO.$sumtable.find(".sum").each(function () {
                var $sum = $(this),
                    unit = $sum.data("sum"),
                    decimals = $sum.data("decimals"),
                    value = 0;
                AddUnitToSum(units, unit, value, $sum, FO, decimals)
            });

            // Update points used
            FO.$pointsUsed.text(units.points);
        },

        getNumber: function (value) {
            var number = Number(value);
            number = isNaN(number) ? 0 : number;
            number = Math.max(0, number);
            return number;
        },

        setNumber: function (number, decimals) {
            if (decimals == null) {
                decimals = this.settings.decimals;
            }

            return number.toFixed(decimals);
        }
    };

    $.fn.formOrder = function (options) {
        var args = Array.prototype.slice.call(arguments, 1),
            $this,
            data;

        return this.each(function () {
            $this = $(this);
            data = $this.data("formOrder");

            if (!data) {
                data = new FormOrder(this, options);
                $this.data("formOrder", (data));
            }
            if (typeof options === "string") {
                data[options](args);
            }
        });
    };

    $.fn.formOrder.Constructor = FormOrder;
})(jQuery);
(function ($) {
    "use strict";

    // Get query parameter from current url
    $.urlParam = function (name) {
        var results = new RegExp("[?&]" + name + "=([^&#]*)").exec(window.location.href);
        if (results == null) {
            return null;
        } else {
            return results[1] || 0;
        }
    };
})(jQuery);
function MenuBindEvent(menuBindEvent, e) {
    if (menuBindEvent.isOpen) {
        menuBindEvent.hide();
    } else {
        menuBindEvent.show();
    }
    e.preventDefault();
}
function AddRemoveActiveClass(submenu, list) {
    if (submenu.is(":hidden")) {
        list.addClass("active");
        submenu.show();
    } else {
        list.removeClass("active");
        submenu.hide();
    }
}
function MenuWindowResize(menuBindEvent) {
    menuBindEvent.$content.height($(window).height());
    if ($(document).width() !== menuBindEvent.documentWidth) {
        menuBindEvent.documentWidth = $(document).width();
        if (menuBindEvent.isOpen) {
            menuBindEvent.hide();
            $(".viewport").css("width", "auto");
            $(".wrapper").css({
                width: "auto",
                left: 0
            });
        }
        menuBindEvent.$el.width(menuBindEvent.width);
        menuBindEvent.documentWidth = $(document).width();
    }
    return menuBindEvent;
}

(function ($) {
    "use strict";

    var Menu = function (el, options) {
        this.settings = options;
        this.$el = $(el);
        this.$trigger = this.$el.find(".mobile-menu-trigger");
        this.$header = this.$el.find(".mobile-menu-top");
        this.$content = this.$el.find(".mobile-menu-content-wrapper");

        this.settings = $.extend({
            speed: 200,
            side: "left"
        },
            this.settings
        );

        this.init();
    };

    Menu.prototype = {
        width: null,
        height: null,
        isOpen: false,
        $cover: null,
        documentWidth: $(document).width(),

        init: function () {
            this.width = this.$el.width();
            this.height = $(window).height();
            const widthConst = 400;
            const heightConst = 60;
            if ($(window).width() <= widthConst) {
                this.width = $(window).width() - heightConst;
            } else if ($(window).width() > widthConst) {
                this.width = 320;
            }

            if (this.settings.side === "left") {
                this.$el.width(this.width).css("left", -this.width);
            } else {
                this.$el.width(this.width).css("right", -this.width);
            }

            this.$content.height(this.height - heightConst);

            this.addCover();
            this.bindEvents();

            if (!Tena.browser.ie7) {
                this.$el.find("select").selectOrDie();
            }
        },

        bindEvents: function () {
            var menuBindEvent = this;
            menuBindEvent.$trigger.on("click", function (e) {
                MenuBindEvent(menuBindEvent, e);
            });

            menuBindEvent.$el.find(".submenu-trigger").on("click", function () {
                var $trigger = $(this),
                    $list = $trigger.parent(),
                    $submenu = $list.find(".submenu");

                if ($list.parent().hasClass("expanded-submenu")) return;

                AddRemoveActiveClass($submenu, $list);

                return false;
            });

            menuBindEvent.$cover.on("click", function () {
                menuBindEvent.hide();
            });

            $(window).resize(function () {
                menuBindEvent = MenuWindowResize(menuBindEvent);
            });
        },

        addCover: function () {
            const menuHeightConst = 60;
            this.$cover = $("<div>").addClass("mobile-menu-cover");
            this.$cover.height($("body").height() + menuHeightConst);
            $(".viewport").append(this.$cover);
        },

        cover: function (action) {
            var menuCover = this,
                $cover = menuCover.$cover,
                settings = { duration: menuCover.settings.speed, queue: false };

            if (action === "show") {
                $cover.fadeIn(settings);
            } else {
                $cover.fadeOut(settings);
            }
        },

        closeOtherMenus: function () {
            $(".mobile-menu")
                .not(this.$el)
                .menu("hide");
        },

        show: function () {
            var showMenu = this,
                $wrapper = $(".wrapper"),
                $viewport = $(".viewport"),
                css = {};
            const menuHeightPosition = 60;
            showMenu.isOpen = true;
            showMenu.closeOtherMenus();
            showMenu.cover("show");
            showMenu.$el.addClass("active");

            $wrapper
                .css({
                    top: -$("body").scrollTop()
                })
                .data("scrollPosition", $("body").scrollTop());
            $viewport.css({
                position: "fixed",
                height: $(window).height() - menuHeightPosition
            });

            if (showMenu.settings.side === "left") {
                css.left = 0;
            } else {
                css.right = 0;
            }

            showMenu.$el.css(css);
        },

        hide: function () {
            var hideMenu = this,
                $wrapper = $(".wrapper"),
                $viewport = $(".viewport"),
                css = {};

            if (!hideMenu.isOpen) return;

            hideMenu.isOpen = false;
            hideMenu.cover("hide");
            hideMenu.$el.removeClass("active");

            if (hideMenu.settings.side === "left") {
                // css.left = '-260px';
                css.left = -this.width;
            } else {
                // css.right = '-260px';
                css.right = -this.width;
            }

            hideMenu.$el.css(css);

            $wrapper.css({
                top: 0
            });
            $viewport.css({
                position: "initial",
                height: "auto"
            });
            $("body").scrollTop($wrapper.data("scrollPosition"));
        }
    };

    $.fn.menu = function (options) {
        var args = Array.prototype.slice.call(arguments, 1),
            $this,
            data;

        return this.each(function () {
            $this = $(this);
            data = $this.data("menu");

            if (!data) {
                data = new Menu(this, options);
                $this.data("menu", (data));
            }
            if (typeof options === "string") {
                data[options](args);
            }
        });
    };

    $.fn.menu.Constructor = Menu;
})(jQuery);
(function ($) {
    "use strict";

    var Message = function (el) {
        this.$el = $(el);
        this.$close = this.$el.find(".message-close");
        this.id = this.$el.prop("id");
        this.init();
    };

    Message.prototype = {
        init: function () {
            var M = this;

            M.bindEvents();
            if (M.id.length === 0) {
                return;
            }

            if (!$.cookie(M.id)) {
                M.show();
            }
        },

        bindEvents: function () {
            var M = this;
            M.$close.on("click", function () {
                M.hide();
            });
        },

        show: function () {
            var M = this;

            M.$el.removeClass("initially-hidden");
        },

        hide: function () {
            var M = this;

            M.$el.fadeOut(function () {
                M.$el.remove();
                if (M.id) {
                    $.cookie(M.id, true);
                }
            });
        }
    };

    $.fn.message = function (options) {
        var args = Array.prototype.slice.call(arguments, 1),
            $this,
            data;

        return this.each(function () {
            $this = $(this);
            data = $this.data("message");

            if (!data) {
                data = new Message(this, options);
                $this.data("message", (data));
            }
            if (typeof options === "string") {
                data[options](args);
            }
        });
    };

    $.fn.message.Constructor = Message;
})(jQuery);
(function ($) {
    "use strict";

    var PieChart = function (el) {
        this.$el = $(el);
        this.id = this.$el.prop("id");
        this.numericID = this.id.slice(-1);

        this.pieData = [{
            value: 0,
            color: "#84bb36"
        },
        {
            value: 100,
            color: "#f8f5f1"
        }
        ];

        this.pieOptions = {
            segmentShowStroke: false,
            animation: false,
            showTooltips: false
        };

        this.ctx = document.getElementById(this.id).getContext("2d");

        this.init();
    };

    PieChart.prototype = {
        init: function () {
            var chart = this;
            chart.bindEvents();
        },

        bindEvents: function () {
            var chart = this;
            var pie = new Chart(this.ctx).Pie(this.pieData, this.pieOptions);
            var numericID = this.id.slice(-1);

            $(".improvement-" + numericID).bind(
                "DOMNodeInserted DOMSubtreeModified DOMNodeRemoved",
                function () {
                    chart.setPie(pie, numericID);
                }
            );
        },

        setPie: function (pie, id) {
            var improvement = $(".improvement-" + id).html();
            var percent = Number(improvement.slice(0, -1));
            const pieConst = 100;
            if (percent <= 0) {
                pie.segments[0].value = 0;
                pie.segments[1].value = 100;
            } else {
                var minus = pieConst - percent;
                pie.segments[0].value = percent;
                pie.segments[1].value = minus;
            }

            pie.update();
        }
    };

    $.fn.piechart = function (options) {
        var args = Array.prototype.slice.call(arguments, 1),
            $this,
            data;

        return this.each(function () {
            $this = $(this);
            data = $this.data("piechart");

            if (!data) {
                data = new PieChart(this, options);
                $this.data("piechart", (data));
            }
            if (typeof options === "string") {
                data[options](args);
            }
        });
    };

    $.fn.piechart.Constructor = PieChart;
})(jQuery);

(function ($) {
    "use strict";

    var SampleBox = function (el) {
        this.$el = $(el);
        this.$selector = this.$el.find(".item-select");
        this.$selected = this.$el.find(".item-selected-sizes");
        this.$sizes = this.$el.find(".sizes");
        this.$size = this.$sizes.find("input");

        this.init();
    };

    SampleBox.prototype = {
        type: null,
        selected: [],

        init: function () {
            var SB = this;

            if (SB.$sizes.hasClass("sizes-checkbox")) {
                SB.type = "checkbox";
                SB.$size = SB.$sizes.find("input");
            } else if (SB.$sizes.hasClass("sizes-select")) {
                SB.type = "select";
                SB.$size = SB.$sizes.find("select");
                SB.$sizes.find("select").selectOrDie();
            }

            if (SB.getSelected().length > 0) {
                SB.updateSelected();
            }

            SB.bindEvents();
        },

        bindEvents: function () {
            var SB = this;

            SB.$size.on("change", function () {
                SB.updateSelected();
            });
        },

        getSelected: function () {
            var SB = this,
                $size,
                selected = [];

            SB.$size.each(function () {
                $size = $(this);
                if ($size.is(":checked")) {
                    selected.push($size.val());
                }
            });

            return selected;
        },

        updateSelected: function () {
            var SB = this,
                selected = SB.getSelected();

            SB.$selected.empty();

            $.each(selected, function (index, value) {
                if (index > 0) value = ", " + value;
                SB.$selected.append(value);
            });
        }
    };

    $.fn.sampleBox = function (options) {
        var args = Array.prototype.slice.call(arguments, 1),
            $this,
            data;

        return this.each(function () {
            $this = $(this);
            data = $this.data("sampleBox");

            if (!data) {
                data = new SampleBox(this, options);
                $this.data("sampleBox", (data));
            }
            if (typeof options === "string") {
                data[options](args);
            }
        });
    };

    $.fn.sampleBox.Constructor = SampleBox;
})(
    jQuery
);
/**
 * Sorter
 * Example:
 * <div class="sorter" data-sorter-items=".my-class" data-sorter-default-order="asc">
        <div>
            <div class="sorter-header" data-sorter-item=".sorter-item-ordernr" data-sorter-item-type="number">Order no</div>
            <div class="sorter-header sorter-header-default" data-sorter-item=".sorter-item-date">Order date</div>
        </div>
        <ul>
            <li class="my-class">
                <div class="sorter-item-ordernr" data-sorter-value="1111">Order 1111</div>
                <div class="sorter-item-date" data-sorter-value="2013-01-01">2013-01-01</div>
            </li>
            <li class="my-class">
                <div class="sorter-item-ordernr" data-sorter-value="3333">Order 3333</div>
                <div class="sorter-item-date" data-sorter-value="2014-01-01">2014-01-01</div>
            </li>
            <li class="my-class">
                <div class="sorter-item-ordernr" data-sorter-value="2222">Order 2222</div>
                <div class="sorter-item-date" data-sorter-value="2011-01-01">2011-01-01</div>
            </li>
        </ul>
    </div>
 */

(function ($) {
    "use strict";

    var Sorter = function (el) {
        this.$el = $(el);
        this.$items = this.$el.find(this.$el.attr("data-sorter-items"));
        this.defaultOrder = this.$el.attr("data-sorter-default-order") || "asc";
        this.$defaultItem = this.$el.find(".sorter-header-default");

        this.init();
    };

    Sorter.prototype = {
        init: function () {
            var prototypeSorter = this;

            // Handle default item - Set css class and order
            if (typeof this.$defaultItem !== "undefined") {
                prototypeSorter.setHeader(this.$defaultItem);
                prototypeSorter.handleSort(this.$defaultItem);
            }

            prototypeSorter.bindEvents();
        },

        bindEvents: function () {
            var bindEventSorter = this;

            this.$el.on("click", ".sorter-header", function () {
                bindEventSorter.setHeader($(this));
                bindEventSorter.handleSort($(this));
            });
        },

        setHeader: function ($this) {
            var headerSorter = this;

            if ($this.hasClass("sorter-asc")) {
                headerSorter.$el.find(".sorter-header").removeClass("sorter-asc sorter-desc");
                $this.addClass("sorter-desc");
            } else {
                headerSorter.$el.find(".sorter-header").removeClass("sorter-asc sorter-desc");
                $this.addClass("sorter-asc");
            }
        },

        handleSort: function ($this) {
            var sorterHandler = this,
                sortEl = $this.attr("data-sorter-item"),
                orderAsc = $this.hasClass("sorter-asc") ? true : false,
                isNumber = $this.attr("data-sorter-item-type") === "number" ? true : false;

            sorterHandler.$items.sort(function (a, b) {
                var objects;
                if (orderAsc) {
                    if (isNumber) {
                        objects = parseInt($(a).find(sortEl).attr("data-sorter-value"), 10) <
                            parseInt($(b).find(sortEl).attr("data-sorter-value"), 10) ? 1 : -1;
                    } else {
                        objects =
                            $(a).find(sortEl).attr("data-sorter-value") <
                                $(b).find(sortEl).attr("data-sorter-value") ? 1 : -1;
                    }
                } else {
                    if (isNumber) {
                        objects =
                            parseInt($(a).find(sortEl).attr("data-sorter-value"), 10) >
                                parseInt($(b).find(sortEl).attr("data-sorter-value"), 10) ? 1 : -1;
                    } else {
                        objects =
                            $(a).find(sortEl).attr("data-sorter-value") >
                                $(b).find(sortEl).attr("data-sorter-value") ? 1 : -1;
                    }
                }
                return objects;
            });

            $(sorterHandler.$items[0])
                .parent()
                .html(sorterHandler.$items);
        }
    };

    $.fn.sorter = function (options) {
        var args = Array.prototype.slice.call(arguments, 1),
            $this,
            data;

        return this.each(function () {
            $this = $(this);
            data = $this.data("sorter");

            if (!data) {
                data = new Sorter(this, options);
                $this.data("sorter", (data));
            }
            if (typeof options === "string") {
                data[options](args);
            }
        });
    };

    $.fn.sorter.Constructor = Sorter;
})(jQuery);
(function ($) {
    "use strict";

    var Tabs = function (el) {
        this.$el = $(el);
        this.$tabs = this.$el
            .find(".tab:eq(0)")
            .siblings(".tab")
            .addBack();
        this.$content = this.$el
            .find(".tab-content:eq(0)")
            .siblings(".tab-content")
            .addBack();

        this.settings = $.extend({
            showFirst: true
        },
            this.settings,
            this.$el.data()
        );

        this.init();
    };

    Tabs.prototype = {
        $activeTab: null,

        init: function () {
            var prototypeTabs = this,
                hash = window.location.hash;

            if (prototypeTabs.$tabs.filter(".active").length === 0) {
                if (this.settings.showFirst) {
                    prototypeTabs.$tabs.first().addClass("active");
                    prototypeTabs.$activeTab = prototypeTabs.$content.eq(0).addClass("active");
                    var firstActiveId = prototypeTabs.$content.first().attr("id");
                    $('[id=' + firstActiveId + ']').addClass("active");
                    $.event.trigger("tabs.changed", {
                        show: prototypeTabs.$tabs.first()
                    });
                }
            } else {
                var openId = prototypeTabs.$tabs
                    .filter(".active")
                    .find("a")
                    .attr("href");
                prototypeTabs.$activeTab = $(openId);
                prototypeTabs.show(openId);
            }
            if (hash && prototypeTabs.$el.find('[href="' + hash + '"]').length > 0) {
                var newActiveLink = prototypeTabs.$tabs.find('[href="' + hash + '"]').parent();

                prototypeTabs.$tabs.removeClass("active");
                newActiveLink.addClass("active");

                prototypeTabs.show(hash);
            }
            prototypeTabs.bindEvents();
            prototypeTabs.sameSizeTabs();
        },

        bindEvents: function () {
            var eventTabs = this;

            eventTabs.$tabs.find("a").on("click", function (e) {
                var $tab = $(this),
                    tabID = $tab.attr("href");

                eventTabs.$tabs.removeClass("active");
                $tab.parent().addClass("active");

                eventTabs.show(tabID);
                e.preventDefault();
            });
            $(".btn-reset").click(function (e) {
                e.preventDefault();
                window.location.href = $(".btn-reset").attr("href");
                window.location.reload(true);
            });
            $(window).on("resize", function () {
                eventTabs.sameSizeTabs();
            });
        },

        sameSizeTabs: function () {
            var sizeTabs = this,
                height = 0,
                $tab;

            sizeTabs.$tabs.height("auto");

            sizeTabs.$tabs.each(function () {
                $tab = $(this);
                if ($tab.height() > height) {
                    height = $tab.height();
                }
            });

            sizeTabs.$tabs.height(height);
        },

        show: function (id) {
            var showTabs = this,
                $show = $(id + ", " + id + "-content"),
                $hide = showTabs.$activeTab;

            if ($hide) {
                $hide.removeClass("active");
                $hide.siblings().removeClass("active");
            }
            $show.addClass("active");
            showTabs.$activeTab = $show;

            $.event.trigger("tabs.changed", {
                show: $show,
                hide: $hide
            });
        },

        checkIfPreselectedTab: function () {
            const stringPosition = 5;
            const subStringPosition = 6;
            if (window.location.hash.substring(1, stringPosition) === "tabs") {
                //console.log("#" + window.location.hash.substring(6));
                var $tab = $("#" + window.location.hash.substring(subStringPosition)),
                    tabID = $tab.attr("href");

                $tab.parent().addClass("active");
                $tab
                    .parent()
                    .siblings()
                    .removeClass("active");
                $(tabID).siblings.removeClass("active");
                Tabs.show(tabID);
            }
        }
    };

    $.fn.tabs = function (options) {
        var args = Array.prototype.slice.call(arguments, 1),
            $this,
            data;

        return this.each(function () {
            $this = $(this);
            data = $this.data("tabs");

            if (!data) {
                data = new Tabs(this, options);
                $this.data("tabs", (data));
            }
            if (typeof options === "string") {
                data[options](args);
            }
        });
    };

    $.fn.tabs.Constructor = Tabs;
})(jQuery);
(function ($) {
    "use strict";

    var ToggleTable = function (el) {
        this.$el = $(el);
        this.init();
    };

    ToggleTable.prototype = {
        init: function () {
            this.foldToggles();
            this.bindEvents();
        },

        bindEvents: function () {
            var eventToggleTable = this;

            eventToggleTable.$el.find(".toggle-row .toggle").on("click", function () {
                var $toggleRow = $(this).closest(".toggle-row");

                if ($toggleRow.next().hasClass("toggle-row-description")) {
                    var $toggleRowDescription = $toggleRow.next();

                    eventToggleTable.toggleTableRow($toggleRow, $toggleRowDescription);
                }
            });
        },

        foldToggles: function () {
            //Toggle-Row folding
            this.$el.find(".toggle-row-description").toggleClass("untoggled");
            this.$el
                .find(".toggle-row .first-column > .icon")
                .toggleClass("icon-arrow-up icon-arrow-down");
        },

        toggleTableRow: function ($toggleRow, $toggleRowDescription) {
            $toggleRow.find(".first-column > .icon").toggleClass("icon-arrow-up icon-arrow-down");
            $toggleRowDescription.toggleClass("untoggled");
        }
    };

    $.fn.toggleTable = function (options) {
        var args = Array.prototype.slice.call(arguments, 1),
            $this,
            data;

        return this.each(function () {
            $this = $(this);
            data = $this.data("toggle-table");

            if (!data) {
                data = new ToggleTable(this, options);
                $this.data("toggle-table", (data));
            }
            if (typeof options === "string") {
                data[options](args);
            }
        });
    };

    $.fn.toggleTable.Constructor = ToggleTable;
})(jQuery);
(function ($) {
    "use strict";

    var Tooltip = function (el, options) {
        this.settings = options;
        this.$el = $(el);

        this.settings = $.extend({
            stick: false,
            mouseover: true,
            title: null,
            description: null
        },
            this.settings,
            this.$el.data()
        );

        this.init();
    };

    Tooltip.prototype = {
        $tooltip: null,

        init: function () {
            var T = this;

            T.createTooltip();

            T.bindEvents();
        },

        bindEvents: function () {
            var T = this;

            if (T.settings.mouseover) {
                T.$el.on("mouseover", function () {
                    T.display();
                });
            }

            T.$el.on("click", function (e) {
                if (T.$tooltip.is(":visible")) {
                    T.hide();
                } else {
                    T.display();
                }
                if (!T.$el.hasClass('sitemap-tooltip')) {
                    e.preventDefault();
                }
            });

            if (!T.settings.stick) {
                T.$el.on("mouseout", function () {
                    T.hide();
                });
            }

            T.$tooltip.find(".tooltip-close").on("click", function () {
                T.hide();
            });

            $("body, .accordion-title").on("mouseout", function (e) {
                var $target = $(e.target);

                if (
                    $target.is(T.$el) ||
                    $target.is(T.$tooltip) ||
                    T.$tooltip.has($target).length > 0
                ) {
                    return;
                }

                T.hide();
            });

            $(window).resize(function () {
                T.position();
            });
        },

        createTooltip: function () {
            var T = this,
                $tooltip = $("<div>").addClass("tooltip"),
                $wrapper = $("<div>").addClass("tooltip-content-wrapper"),
                $content = $("<div>").addClass("tooltip-content"),
                $arrow = $("<div>").addClass("tooltip-arrow"),
                $close = $("<div>").addClass("icon-validation-cross tooltip-close hide show-xs");

            if (T.settings.title) {
                $content.append(
                    $("<h4>")
                        .addClass("tooltip-title")
                        .text(T.settings.title)
                );
            }
            if (T.settings.description) {
                $content.append($("<p>").text(T.settings.description));
            }

            $tooltip.append($wrapper);
            $wrapper.append($content);
            $wrapper.append($arrow);
            $wrapper.append($close);
            $("body").append($tooltip);

            T.$tooltip = $tooltip;
        },

        display: function () {
            var T = this;

            T.position();
            T.$tooltip.show();
        },

        hide: function () {
            var T = this;

            T.$tooltip.hide();
        },

        position: function () {
            var T = this,
                position = T.getPosition();

            T.$tooltip.css({
                left: position.left,
                top: position.top
            });
        },

        getPosition: function () {
            const toolTipConst = 2;
            const tooltipPosition = 10;
            var T = this,
                offset = T.$el.offset(),
                height = T.$el.height(),
                width = T.$el.width(),
                tooltipWidth = T.$tooltip.outerWidth(),
                windowWidth = $(window).width(),
                arrowHeight = 11,
                position = {};

            position.left = offset.left - tooltipWidth / toolTipConst + width / toolTipConst;
            position.top = offset.top + height + arrowHeight;

            if (position.left < tooltipPosition) {
                position.left = 10;
            } else if (tooltipWidth + position.left > windowWidth) {
                position.left = position.left + (windowWidth - (tooltipWidth + position.left));
            }

            return position;
        }
    };

    $.fn.tooltip = function (options) {
        var args = Array.prototype.slice.call(arguments, 1),
            $this,
            data;

        return this.each(function () {
            $this = $(this);
            data = $this.data("tooltip");

            if (!data) {
                data = new Tooltip(this, options);
                $this.data("tooltip", (data));
            }
            if (typeof options === "string") {
                data[options](args);
            }
        });
    };

    $.fn.tooltip.Constructor = Tooltip;
})(jQuery);
var Tena = {};

function CalculateCarousalOffset(imagePager, currentImage, arrow, $nextImage, imageArrow, offset) {
    if (imagePager.length > 1) {
        currentImage.fadeOut();
    }
    if (arrow.is(".icon-nav-arrow-left") || arrow.is(".icon-nav-arrow-top")) {
        $nextImage = currentImage.prev();
        imageArrow.addClass("active");
        if ($nextImage.size() === 0) {
            $nextImage = currentImage.siblings().eq(-1);
        }
        offset = currentImage.width()
        offset = -(offset);
    } else if (arrow.is(".icon-nav-arrow-right") ||
        arrow.is(".icon-nav-arrow-bottom")) {
        $nextImage = currentImage.next();
        imageArrow.addClass("active");
        if ($nextImage.size() === 0) {
            $nextImage = currentImage.siblings().eq(0);
        }

        offset = currentImage.width();
    }
    return [offset, $nextImage];
}
function GetOptionGroups(optionGroups, $select) {
    $select = $("select[name=product_name]").eq(0);
    $select.find("optgroup").each(function () {
        optionGroups.push($(this));
    });
    return optionGroups;
}
(function ($) {
    $.extend(Tena, {
        browser: {
            ie7: false,
            ie8: false,
            ie9: false
        },

        init: function () {
            Tena.detectBrowser();
            Tena.cookiebar();
            Tena.initProductFamilySelector();
            Tena.Header.init();
            Tena.Footer.init();
            Tena.Helpers.addHelpers();
            Tena.bindEvents();

            if (Tena.browser.ie7) {
                Tena.ie7fix();
            }

            $(".terms-popup").magnificPopup({
                type: "inline",
                midClick: true
            });

            $(".popup-inline").magnificPopup({
                type: "inline",
                callbacks: {
                    open: function () {
                        var $contentContainer = $(this)[0].contentContainer;
                        $contentContainer.addClass("popup-custom-iframe");
                    }
                }
            });

            $(".video-popup, .iframe-popup").magnificPopup({
                type: "iframe",
                iframe: {
                    patterns: {
                        azure: {
                            index: "azure.com",
                            id: function () {
                                var id = 0;
                                return id;
                            },
                            src: "https://www.azure.com/embed/%id%"
                        }
                    }
                }
            });
            if (window.matchMedia('(max-width: 767px)').matches) {
                $(".gallery-popup a").magnificPopup({
                    type: "image",
                    mainClass: "image-carousel-popup",
                    zoom: {
                        enabled: true,
                        duration: 300
                    },
                    gallery: {
                        enabled: true,
                        tCounter: "%curr% / %total%"
                    },
                    callbacks: {
                        open: function () {                        // When you open the
                            $('html').css('overflow', 'hidden');  // window, the element
                        },                                        // "body" is used "overflow: hidden".

                        close: function () {                       // When the window
                            $('html').css('overflow', '');        // is closed, the 
                        },
                        beforeClose: function () {
                            $(".image-pager li", ".component-image-carousel")
                                .eq(this.index)
                                .trigger("click");
                        },
                        elementParse: function (item) {
                            if (item.el.context.className === 'video-gallery-popup') {
                                item.type = 'jwplayer';
                            } else {
                                item.type = 'image';
                            }
                        }
                    }
                });
            }
            $(".date-picker").each(function () {
                var picker = new Pikaday({
                    field: this,
                    format: "YYYY-MM-DD"
                });

                $(this)
                    .siblings(".icon-calendar")
                    .on("click", function () {
                        picker.show();
                    });
            });

            $(".inline-popup-link").magnificPopup({
                type: "inline",
                showCloseBtn: false
            });

            $(".jw-video-popup").magnificPopup({
                type: "jwplayer"
            });

            $(".mobile-menu-left").menu({ speed: 400 });
            $(".mobile-menu-right").menu({ speed: 400, side: "right" });

            Tena.updateDebugSize();

            // Init components
            $("form").form();
            $(".calc").calculator();
            $(".accordion").accordion();
            $(".toggle-table").toggleTable();
            $(".carousel").carousel();
            $(".tabs").tabs();
            $(".component-sample-box").sampleBox();
            $(".form-order").formOrder();
            $(".js-toggle-tooltip").tooltip();
            $(".sorter").sorter();
            $(".pie").piechart();
            $(".message").message();
            $(".content-expander").contentExpander();

            $(".js-numeric").keyup(Tena.numeric);

            $.breakpoint.init({
                sizes: ["xxs", "xs", "sm", "md"]
            });

            Tena.trackFonts();

            // Init alignment last to let other
            // components finish their layout first
            Tena.Alignment.init();

            // Trigger resize and again when images are loaded
            var $window = $(window);
            imagesLoaded($window, function () {
                $window.resize();
            });
            $window.resize();

            // Ugly workaround to hide navigation based on query parameter
            if ($.urlParam("hidenav")) {
                $(".header, .mobile-header, .footer, .cookiebar").hide();
            }
        },

        bindEvents: function () {
            $(window).on("resize", function () {
                Tena.updateDebugSize();
                Tena.toggleTableCellVisibilty();
            });

            // Add unique player IDs so we can correctly display more than one video on the same page
            var players = document.querySelectorAll(".jw-player");
            for (var index = 0; index < players.length; index++) {
                players[index].id = "jw" + index;
            }

            $(".wrapperVideo-btn").on("click", function (evt) {
                evt.stopPropagation();
                var $video = $(this).closest(".video"),
                    $jwplayer = $video.find(".jwplayer "),
                    $videoId = $jwplayer.attr('id');
                $video.find(".jw-player-wrapper").show();
                $video.find(".video-thumbnail").hide();
                jwplayer($videoId).play(true);
            });

            $.each(document.querySelectorAll('.video-player'), function (playerIndex, player) {
                new VideoPlayer(player);
            });


            $(".btn-play, .video-thumbnail").on("click", function () {
                if ($(this).is('.wrapperVideo-btn')) {
                    return;
                }
                var $video = $(this).closest(".video"),
                    $iframe = $video.find("iframe"),
                    $jwplayer = $video.find(".jw-player");

                if ($jwplayer.length > 0) {
                    var sources = $jwplayer.data("url").split(",");

                    var $sourcesArray = [];
                    for (var i = 0; i < sources.length; i++) {
                        $sourcesArray.push({ file: sources[i] });
                    }

                    $(".jw-player-wrapper").show();
                    // Setup JWPlayer
                    var settings = getJwplayerSettings($sourcesArray);
                    jwplayer($jwplayer.attr("id")).setup(settings);
                } else {
                    $video.find(".jw-player-wrapper").show();
                    var src = $iframe.attr("src");
                    if (src && !$(this).is('.video')) {
                        src = src.replace(/&autoplay=0/i, '&autoplay=1');
                        $iframe.attr("src", src).show();
                    } else {
                        $jwplayer = $video.find(".jwplayer ");
                        jwplayer($jwplayer.attr('id')).play(true);
                    }
                }

                $video.find(".video-thumbnail").hide();
            });

            // Hide thumbnail for product description
            if ($(".ProductDescription-container .has-thumbnail").length !== $(".ProductDescription-container .ProductDescription").length) {
                $(".ProductDescription-container").addClass('hide-thumbnail');
            }

            //var $imageCarouselArrows = $(".icon", ".component-image-carousel"),
            var $imageCarouselArrows = $(".image-carousel .icon, .ThumbnailSlider .icon"),
                imagePagerLength = $(".ThumbnailSlider ul").children().length,
                arrowBottom = $(".icon.icon-nav-arrow-bottom"),
                arrowTop = $(".icon.icon-nav-arrow-top");
            arrowBottom.hide();
            arrowTop.hide();
            const imageLengthConst = 7;
            if (imagePagerLength > imageLengthConst) {
                arrowTop.show();
                arrowBottom.show();
            }
            if (imagePagerLength == 1) {
                $(".icon-nav-arrow-left").css('display', 'none');
                $(".icon-nav-arrow-right").css('display', 'none');
            }
            $("ul.image-pager").css({ height: "560px", overflow: "hidden" });
            $("ul.image-pager li:first-child").addClass("active");
            var scrollTopPosition = $("ul.image-pager").outerHeight(true);
            $imageCarouselArrows.on("click keypress", function (event) {
                // Handle click and keyboard "Enter" keypress events
                if (event.type === "click" || (event.type === "keypress" && event.key === "Enter")) {
                    //alert('click')
                    var $arrow = $(this),
                        $productContainer = $arrow.closest(".component-product-intro"),
                        $carousel = $productContainer.find(".component-image-carousel"),
                        $imagePager = $productContainer.find(".image-pager li"),
                        //$currentThumnail,
                        $currentImage,
                        $nextImage,
                        offset,
                        currentImage;

                    currentImage = $carousel.data().currentImage || 0;
                    $currentImage = $carousel.find(".image").eq(currentImage);
                    if ($imagePager.length > 1) {
                        $currentImage.fadeOut();
                    }
                    if ($arrow.is(".icon-nav-arrow-left") || $arrow.is(".icon-nav-arrow-top")) {
                        $nextImage = $currentImage.prev();
                        $(this).addClass("active");
                        if ($nextImage.size() === 0) {
                            $nextImage = $currentImage.siblings().eq(-1);
                        }
                    }
                    offsetAndImage = CalculateCarousalOffset($imagePager, $currentImage, $arrow, $nextImage, $(this), offset);
                    offset = offsetAndImage[0];
                    $nextImage = offsetAndImage[1];

                    if ($nextImage) {
                        $carousel.data("current-image", $nextImage.index());

                        $nextImage
                            .css({
                                left: offset,
                                opacity: 1
                            }).show().stop(true, false).animate({
                                left: 0
                            }, "slow");

                        $imagePager
                            .removeClass("active")
                            .eq($nextImage.index())
                            .addClass("active");
                        const nextImageConst = 6;
                        if ($nextImage.index() > nextImageConst) {
                            $("ul.image-pager").animate({
                                scrollTop: scrollTopPosition
                            },
                                "slow"
                            );
                        } else {
                            $("ul.image-pager").animate({ scrollTop: 0 }, "slow");
                        }
                    }
                }
            });

            if ($(".image-carousel").length > 0 && typeof Hammer != "undefined") {
                var imageCarouselSwipe = new Hammer($(".image-carousel").get(0));
                imageCarouselSwipe.on("swipeleft", function () {
                    $imageCarouselArrows.filter(".icon-nav-arrow-right").trigger("click");
                });
                imageCarouselSwipe.on("swiperight", function () {
                    $imageCarouselArrows.filter(".icon-nav-arrow-left").trigger("click");
                });
            }

            //$(".image-pager li", ".component-image-carousel").on("click", function () {
            $(".image-pager li").on("click", function () {
                $('.image-pager li').removeClass('active');
                var $imagePagerItem = $(this),
                    $productContainer = $imagePagerItem.closest(".component-product-intro"),
                    $carousel = $productContainer.find(".component-image-carousel"),
                    $imagePagers = $(this),
                    $currentImage,
                    //$nextImage,
                    //offset,
                    currentImage;
                const fadeOutInterval = 400;
                const fadeInInterval = 400;

                currentImage = $carousel.data().currentImage || 0;
                $currentImage = $carousel.find(".image").eq(currentImage);
                $carousel.find(".image").fadeOut(fadeOutInterval);

                //Show right image
                $currentImage = $carousel.find(".image").eq($imagePagerItem.index());
                $carousel.data().currentImage = $imagePagerItem.index();
                $currentImage.fadeIn(fadeInInterval);

                $imagePagers.removeClass("active");
                $imagePagerItem.addClass("active");
            });

            // Check all checkboxes in a form - in use in *-history.php pages
            $("body").on("click", ".check-all-input-checkbox", function (e) {
                e.preventDefault();
                var form = $(this).parents("form");
                form.find(":checkbox").prop("checked", true);
            });

            // Print image
            $(".js-print-img").on("click", function (e) {
                var popup = window.open(),
                    imgSource = $(this).attr("href"),
                    $content = $("<div>"),
                    $img = $("<img>").attr("src", imgSource),
                    html = $content.append($img).html();

                popup.document.write(html);
                popup.document.close();
                popup.focus();
                popup.print();
                popup.close();

                e.preventDefault();
            });
        },

        detectBrowser: function () {
            var $body = $("body");
            if ($body.is(".ie9")) Tena.browser.ie9 = true;
            if ($body.is(".ie8")) Tena.browser.ie8 = true;
            if ($body.is(".ie7")) Tena.browser.ie7 = true;
        },

        cookiebar: function () {
            var $cookiebar = $(".cookiebar"),
                $close = $(".cookiebar-close");

            if (!$.cookie("cookiebar")) {
                $cookiebar.fadeIn();
            }

            $close.on("click", function () {
                var path = $("#context-path-value").attr("data-context-path");
                $cookiebar.fadeOut();
                $.cookie("cookiebar", true, { expires: 7, path: path });
            });
        },

        numeric: function () {
            var numeric = this.value.replace(/[^0-9\.]/g, "");
            if (this.value !== numeric) {
                this.value = numeric;
            }
        },

        updateDebugSize: function () {
            var $size = $("#debug-size");
            if (!$size.length) {
                $size = $('<div id="debug-size" />');
                $size.css({
                    position: "fixed",
                    top: 5,
                    left: 5,
                    zIndex: 999999,
                    backgroundColor: "rgba(255, 255, 255, 0.3)",
                    fontSize: 12,
                    padding: 2,
                    border: "1px solid rgba(255, 255, 255, 0.5)",
                    color: "000",
                    display: "none"
                });
                $("body").prepend($size);
            }

            $size.html($(window).width() + "x" + $(window).outerHeight());
        },

        initProductFamilySelector: function () {
            var $accordion = $(".accordion-product-family-selector"),
                $select,
                optionGroups = [];

            if (!$accordion.length) {
                return;
            }

            optionGroups = GetOptionGroups(optionGroups, $select);

            $accordion.on("click", ".product-family-item", function () {
                var id = $(this).data("family-id"),
                    $selectedOptionGroup = false;

                if ($(this).hasClass("selected")) {
                    $accordion.find(".product-family-item").removeClass("selected");
                    $select.find("optgroup").remove();
                    $.each(optionGroups, function (index, $element) {
                        $select.append($element);
                    });

                    if (!Tena.browser.ie7) {
                        $select.selectOrDie("update");
                    }
                    return;
                }

                $accordion.find(".product-family-item").removeClass("selected");
                $(this).addClass("selected");

                $.each(optionGroups, function (index, $element) {
                    if ($element.data("id") === id) {
                        $selectedOptionGroup = $element;
                        return false;
                    }
                });

                if ($selectedOptionGroup.length === 1) {
                    $select.find("optgroup").remove();
                    $select.append($selectedOptionGroup);
                    $select.prop("selectedIndex", 0);

                    if (!Tena.browser.ie7) {
                        $select.selectOrDie("update");
                    }
                }
            });
        },

        ie7fix: function () {
            Tena.fixColWidth($("body"));
            $(window).on("tabs.changed", function (e, data) {
                Tena.fixColWidth(data.show);
            });
            $(window).on("accordion.changed", function (e, data) {
                Tena.fixColWidth(data.show);
            });
            $(window).on("breakpoint.changed", function () {
                Tena.fixColWidth($("body"));
            });
            $(window).on("submenu.changed", function (e, data) {
                Tena.fixColWidth(data.show);
            });
        },

        fixColWidth: function ($el, timeout) {
            const timeoutIntervalConst = 100;
            var $cols = typeof $el !== "undefined" ? $el.find("[class*=col-]") : $("[class*=col-]"),
                $col,
                width,
                fullWidth,
                diff,
                newWidth;

            timeout = timeout || timeoutIntervalConst;

            $cols.each(function () {
                this.style.removeAttribute("width");
            });

            window.fixColWidthTimeout = setTimeout(function () {
                $cols.each(function () {
                    $col = $(this);
                    width = $col.width();
                    fullWidth = $col.outerWidth();
                    diff = fullWidth - width;
                    newWidth = width - diff;

                    $col.css("width", newWidth);
                });
            }, timeout);
        },

        // Comply with fonts.com EULA
        // http://blog.toxa.de/archives/460
        // http://www.fonts.com/info/legal/eula/web-fonts
        trackFonts: function () {
            //var projectId = "93406a9f-d474-40e2-b739-cf4c89214b01",
            var mtiTracking = document.createElement("script");

            mtiTracking.type = "text/javascript";
            mtiTracking.async = "true";
            mtiTracking.src =
                ("https:" === document.location.protocol ? "https:" : "http:") +
                "//fast.fonts.net/t/trackingCode.js";

            $("head").append(mtiTracking);
        },

        toggleTableCellVisibilty: function () {
            const columnWindowWidthConst = 768;
            var windowWidth = $(window).width();
            if (windowWidth < columnWindowWidthConst) {
                $(".component-table")
                    .find("td")
                    .each(function () {
                        var $column = $(this);
                        if (!$column.data().label || !$column.html()) {
                            $column.addClass("hidden-empty");
                        }
                    });
            } else {
                $(".hidden-empty").each(function () {
                    var $column = $(this);
                    $column.removeClass("hidden-empty");
                });
            }
        }
    });

    $(function () {
        Tena.init();
    });
})(jQuery);
(function ($) {
    Tena.Alignment = {
        groups: [],

        init: function () {
            var $rows = $(".body .container > .row"),
                $row,
                $item,
                $items;

            $rows.each(function () {
                $row = $(this);
                $items = $row.find(".align-height").filter(function () {
                    $item = $(this);
                    return $item.is(".fixed-height") ? false : true;
                });

                if ($items.length > 1) {
                    Tena.Alignment.groups.push($items);
                }
            });

            Tena.Alignment.resize();
            Tena.Alignment.bindEvents();
        },

        bindEvents: function () {
            var winWidth = $(window).width(),
                winHeight = $(window).height();

            $(".tab").click(Tena.Alignment.resize);
            $(window).load(Tena.Alignment.resize);
            $(window).resize(function () {
                var newWidth = $(window).height(),
                    newHeight = $(window).width();

                if (winWidth !== newWidth || winHeight !== newHeight) {
                    Tena.Alignment.resize();
                    winWidth = newWidth;
                    winHeight = newHeight;
                }
            });
        },

        resetPushboxContentAlignment: function ($container) {
            $container = $container || $("body");

            $container.find(".pushbox .js-align-bottom").css({
                marginTop: 0
            });
        },

        alignPushboxContentToBottom: function ($container) {
            $container = $container || $("body");

            var $pushboxes = $container
                .find(".pushbox .js-align-bottom")
                .closest(".pushbox-content");

            $pushboxes.each(function () {
                var $pushbox = $(this),
                    totalHeight = $pushbox.outerHeight(),
                    contentHeight = 0,
                    difference = 0;

                // Calculate content height
                $pushbox
                    .children()
                    .filter(function () {
                        return $(this).css("position") !== "absolute";
                    })
                    .each(function () {
                        contentHeight += $(this).outerHeight(true);
                    });

                // Calculate difference
                difference = totalHeight - contentHeight;
                difference = Math.max(0, difference);

                // Fix difference using margin-top
                $pushbox.find(".js-align-bottom").css({
                    marginTop: difference
                });
            });
        },

        resize: function () {
            Tena.Alignment.resetPushboxContentAlignment();

            $.each(Tena.Alignment.groups, function (g, $group) {
                $group.height("auto");
                Tena.Alignment.alignAdjacentItems($group, false);
            });

            Tena.Alignment.alignPushboxContentToBottom();
            Tena.Alignment.alignTitleOffsets();
        },

        equalizeHeight: function ($items) {
            var maxHeight = 0;

            $items.each(function () {
                var $item = $(this),
                    $dynamic = $item.find(".dynamic-height"),
                    height;

                if ($dynamic) {
                    var dynamicHeight = $dynamic.attr("data-dynamic-height");
                    $dynamic.outerHeight(dynamicHeight);
                    height = $item.outerHeight(true);
                    $dynamic.css({ height: "" });
                } else {
                    height = $item.outerHeight(true);
                }

                if (height > maxHeight) {
                    maxHeight = height;
                }
            });

            // If the boxes are of uneven height, the background image in
            // 'type-image-full' might have problems filling the whole pushbox
            const pushboxConst = 2;
            if (maxHeight % pushboxConst !== 0) {
                maxHeight--;
            }

            $items.outerHeight(maxHeight);
        },

        alignAdjacentItems: function ($items, debug) {
            var $item,
                $adjacent = $().add(),
                offset = 0,
                debug = false;

            $items.each(function () {
                $item = $(this);

                if (offset !== 0 && $item.offset().top !== offset) {
                    if ($adjacent.length > 1) {
                        Tena.Alignment.equalizeHeight($adjacent);
                    }

                    $adjacent = $().add();
                }

                if ($item.offset().top === offset || $adjacent.length === 0) {
                    $adjacent = $adjacent.add($item);
                }

                offset = $item.offset().top;
            });

            if ($adjacent.length > 1) {
                Tena.Alignment.equalizeHeight($adjacent);
            }
        },

        alignTitleOffsets: function () {
            var $sectionTitleOffsets = $(".align-title-offset");

            $sectionTitleOffsets.each(function () {
                var elemToAlign = $(this),
                    $sectionTitle = $(this)
                        .closest(".row")
                        .find(".component-title, .section-title")
                        .first();

                elemToAlign.css("padding-top", $sectionTitle.innerHeight());
            });
        }
    };
})(jQuery);

function MenuMoveMouse(e, tenaHeader) {
    var $target = $(e.target),
        $openSubmenu = $('.submenu-opened:not(.sticky)');
    const clearTimeoutDuration = 100;
    if ($target.is('.menu-item') || $target.is('.menu-item a') || $target.is('.submenu') ||
        $target.is('.has-submenu') || $target.closest('.submenu').length || $target.closest('.has-submenu').length) {
        clearTimeout(window.closeSubmenuTimeout);
    } else if ($openSubmenu.length) {
        // Hide
        clearTimeout(window.closeSubmenuTimeout);
        window.closeSubmenuTimeout = setTimeout(function () {
            tenaHeader.closeSubmenu();
        }, clearTimeoutDuration);
    }
}
function is_touch_enabled() {
    return ('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0);
}

function MenuMouseEnter(menu, header, tenaHeader) {
    if (menu.is(".opened") || header.find(".has-submenu.opened")) {
        clearTimeout(tenaHeader.hideMenuTimer);
    }
}

function MenuMouseLeave(tenaHeader, menu) {
    var $menuItem = menu,
        $submenu = $menuItem.find(".submenu");
    const menuTimerDuration = 450;
    if ($menuItem.is(".opened")) {
        tenaHeader.hideMenuTimer = setTimeout(function () {
            $menuItem.removeClass("opened");
            $submenu.hide();
        }, menuTimerDuration * 1);
    }
}

(function ($) {
    Tena.Header = {
        hideMenuTimer: null,

        init: function () {
            Tena.Header.bindEvents();
        },

        bindEvents: function () {
            var $window = $(window),
                $header = $(".header");

            $window.on("resize", function () {
                Tena.Header.toggleStickyMenu();
                Tena.Header.closeSubmenu();
            });

            const tenaHeadeConst = 991;
            if ($(window).width() < tenaHeadeConst) {
                $window.on("scroll", function () {
                    Tena.Header.closeSubmenu();
                });
            } else {
                $window.on("scroll", function () {
                    Tena.Header.toggleStickyMenu();
                });
            }

            $(document).on('mousemove', function (e) {
                MenuMoveMouse(e, Tena.Header);
            });


            const headerWidthConst = 1023;
            if ($(window).width() <= headerWidthConst) {
                $header.on("click", ".has-submenu > a", Tena.Header.toggleSubmenu);
            } else {
                if (is_touch_enabled()) {
                    $(".header .has-submenu a").on("click", function () {
                        if ($(this).is(".opened")) {
                            var $menuItem = $(this);
                            $menuItem.removeClass("opened");
                            $(".submenu").remove();
                            location.reload();
                        } else {
                            $(".has-submenu").on("click", "a", Tena.Header.openRedirectSubmenu);
                        }
                    });
                } else {
                    $header.on("mouseenter", ".has-submenu > a", Tena.Header.toggleSubmenu);
                }
            }

            $header.on("mouseenter click", ".submenu", function (e) {
                e.stopPropagation();
            });

            $("body").on("click", function () {
                Tena.Header.closeSubmenu();
            });

            $header.on("mouseenter", ".has-submenu", function () {
                MenuMouseEnter($(this), $header, Tena.Header);
            });

            $header.on("mouseleave", ".has-submenu", function () {
                MenuMouseLeave(Tena.Header, $(this));
            });

            $window.scroll();
        },

        toggleSubmenu: function () {
            var $link = $(this),
                $submenu = $link.siblings(".submenu");

            if (!$link.hasClass("opened")) {
                Tena.Header.closeSubmenu();
                Tena.Header.openSubmenu($submenu);
            } else {
                Tena.Header.closeSubmenu();
            }

            return false;
        },

        openRedirectSubmenu: function () {
            var $link = $(this),
                $submenu = $link.siblings(".submenu");
            $(".has-submenu > a.opened").removeClass("opened");
            $(".submenu:not(:last-child)").remove();
            if (!$link.hasClass("opened")) {
                Tena.Header.openSubmenu($submenu);
            }
            return false;
        },

        openSubmenu: function ($targetSubmenu) {
            var $link = $(event.target),
                $container = $link.closest(".container"),
                $menuWrapper = $container.closest(".menu-wrapper"),
                $submenu = $(".submenu-opened"),
                $columns,
                submenuCSS = {},
                $cookiebarOuterHeight = $(".component-cookiebar").outerHeight(),
                $menuWrapperOuterHeight = $(".menu-wrapper").outerHeight();

            $link.parents(".menu.align-left").addClass("Nav--Hovered");
            $link.addClass("opened");

            $submenu = $targetSubmenu.clone(true);
            if (!is_touch_enabled()) {
                if ($submenu.length) {
                    $submenu.addClass("submenu-opened").show();
                }
                else {
                    return false;
                }
            }
            else {
                $submenu.addClass("submenu-opened").show();
            }

            if ($link.parents(".header-menu").length && !$submenu.hasClass("submenu-header-menu")) {
                $submenu.addClass("submenu-header-menu");
            } else if ($submenu.hasClass("submenu-header-menu")) {
                $submenu.removeClass("submenu-header-menu");
            }
            const menuWrapperConst = 13;
            const linkOffsetConst = 15;
            const submenuOffsetConst = 991;
            if ($menuWrapper.is(".sticky")) {
                submenuCSS.top = $(window).scrollTop() + $menuWrapperOuterHeight - $cookiebarOuterHeight + menuWrapperConst;
                submenuCSS.position = "absolute";
            } else {
                submenuCSS.top =
                    $link.offset().top + $link.height() + linkOffsetConst - $(".header").offset().top;
            }

            if ($submenu.is(".submenu-login")) {
                $submenu.css(
                    $.extend({
                        width: "100%",
                        left: 0
                    },
                        submenuCSS
                    )
                );
            } else if ($submenu.is(".other-sites")) {
                if ($(window).width() <= submenuOffsetConst) {
                    var left = $link.offset().right + $link.width() - $targetSubmenu.width();
                } else {
                    var left = $link.offset().left + $link.width() - $targetSubmenu.width();
                }
                $submenu.css(
                    $.extend({
                        width: $targetSubmenu.width(),
                        left: left
                    },
                        submenuCSS
                    )
                );
            } else {
                $submenu.css(
                    $.extend({
                        width: $container.width(),
                        left: $container.offset().left + parseInt($container.css("padding-left"), 10)
                    },
                        submenuCSS
                    )
                );

                $columns = $submenu.find(".submenu-content .column");
                var numEmptyColumns = 0;
                const emptyColumnConst = 2;
                // Make sure to only do the below loop for the Products menu; we do this by checking for presence of .column-content
                if ($columns.length > 0 && $columns[0].querySelector(".column-content")) {
                    if ($columns[$columns.length - emptyColumnConst]) {
                        if ($columns[$columns.length - emptyColumnConst].querySelector(".column-content").childNodes.length <= 1) {
                            numEmptyColumns++;
                            $columns[$columns.length - emptyColumnConst].style = "display: none";
                        }
                    }

                    if ($columns[$columns.length - 1]) {
                        if ($columns[$columns.length - 1].querySelector(".column-content").childNodes.length <= 1) {
                            numEmptyColumns++;
                            $columns[$columns.length - 1].style = "display: none";
                        }
                    }
                }

                var colClassNumber = 12;
                if ($columns.length > 1) {
                    if (numEmptyColumns === 1) {
                        colClassNumber = colClassNumber / $columns.length + numEmptyColumns;
                    } else if (numEmptyColumns > 1) {
                        colClassNumber = colClassNumber / $columns.length + numEmptyColumns + 1;
                    } else {
                        colClassNumber = colClassNumber / $columns.length;
                    }
                }

                $.each($columns, function () {
                    var newClassName = $(this)
                        .attr("class")
                        .replace("4", colClassNumber);
                    $(this).attr("class", newClassName);
                });

                if ($columns.length === 0) {
                    $columns = $submenu.find(".submenu-content .submenu-header");
                }
                const columnConst = 3;
                const columnLengthConst = 18;
                const menuLinkConst = 2;
                const containerOffsetConst = 20;
                if ($columns.length < columnConst) {
                    $submenu.width($submenu.width() / columnConst * $columns.length + columnLengthConst);

                    var submenuLeftMargin =
                        $link.offset().left + $link.width() / menuLinkConst - $submenu.width() / menuLinkConst;
                    $submenu.css("left", submenuLeftMargin + "px");

                    if (submenuLeftMargin < $container.offset().left) {
                        $submenu.css("left", $container.offset().left + containerOffsetConst + "px");
                    }

                    // If the menu link is on the right side of the menu and the
                    // menu has less than three columsn, move the menu over to
                    // the side of the menu item avoid having the submenu arrow
                    // end up outside of the submenu
                }
            }

            // Hide mega menu on vertical upward window scroll
            $(window).scroll(function () {
                var windowScroll = $(window).scrollTop();
                const outerHeightConst = 20;
                var megaMenuTopPosition = $('.submenu-opened').offset().top;
                if ($('.menu-wrapper').hasClass("sticky")) {
                    if (windowScroll + $(".menu-wrapper").outerHeight() + outerHeightConst < megaMenuTopPosition) {
                        Tena.Header.closeSubmenu();
                    };
                };
            });

            $(".header").prepend($submenu);

            $.event.trigger("submenu.changed", { show: $submenu });

            Tena.Header.showArrow($link, $submenu);

            imagesLoaded($submenu.get(0), function () {
                Tena.Header.fixColumns($submenu);
                Tena.Alignment.alignPushboxContentToBottom($submenu);
            });
        },

        fixColumns: function ($submenu) {
            var $columns = $submenu.find(".submenu-content .column"),
                $header = $submenu.find(".submenu-header"),
                headerHeight = $header.length ? $header.outerHeight(true) : 0,
                maxHeight = 0;

            $columns.css({ height: "auto" });
            $columns.each(function () {
                var height = $(this).outerHeight();
                if (height > maxHeight) {
                    maxHeight = height;
                }
            });

            $columns.css({
                height: maxHeight
            });

            $submenu.find(".submenu-content").height(maxHeight + headerHeight);
        },

        showArrow: function ($link, $submenu) {
            var $arrow = $submenu.find(".arrow");

            if (!$arrow.length) {
                $arrow = $('<div class="arrow" />');
                $submenu.prepend($arrow);
            }
            const submenuPrositionConst = 2;

            $arrow.css({
                left: $link.offset().left +
                    $link.width() / submenuPrositionConst -
                    $submenu.position().left -
                    $arrow.width() / submenuPrositionConst
            });
        },

        closeSubmenu: function () {
            $(".header")
                .find(".submenu-opened")
                .hide();
            $(".header").find(".menu.align-left").removeClass("Nav--Hovered");
            $(".has-submenu > a.opened").removeClass("opened");
            $(".submenu.submenu-opened:nth-child(n+2)").remove();
        },

        toggleStickyMenu: function () {
            var $window = $(window),
                $headerMenu = $(".header-menu"),
                $menuWrapper = $headerMenu.find(".menu-wrapper"),
                $submenu = $(".submenu-header-menu.submenu-opened");

            if (!$menuWrapper.data().defaultOffset) {
                $menuWrapper.data("default-offset", $menuWrapper.offset().top);
            }

            if ($menuWrapper.is(".sticky")) {
                if ($window.scrollTop() < $menuWrapper.data().defaultOffset - 0) {
                    $(".sticky-show").fadeOut("100");
                    $menuWrapper.removeClass("sticky");
                    $headerMenu.height("auto");
                    $submenu.css({
                        position: "absolute",
                        top: $menuWrapper.position().top + $menuWrapper.height()
                    });
                }
            } else {
                if ($window.scrollTop() >= $menuWrapper.data().defaultOffset - 0) {
                    $(".sticky-show").fadeIn("100");
                    $menuWrapper.addClass("sticky");
                    $headerMenu.height($menuWrapper.height());
                    if ($submenu.length) {
                        $submenu.css({
                            position: "absolute",
                            top: $menuWrapper.height()
                        });
                    }
                }
            }
        }
    };
})(jQuery);

(function ($) {
    Tena.Footer = {
        canvas: null,
        canvasColor: null,
        //canvasHeight: 190,

        init: function () {
            Tena.Footer.shareLinksWindow();
        },

        shareLinksWindow: function () {
            $("#share-link-popup-link").magnificPopup({
                type: "inline"
            });
        }
    };
})(jQuery);

(function ($) {
    Tena.Helpers = {
        addHelpers: function () {
            window.isEnterKey = Tena.Helpers.isEnterKey;
        },

        isEnterKey: function (e) {
            const keyCodeConst = 13;
            // keyCode 13 is 'enter'
            return e.keyCode === keyCodeConst;
        }
    };
})(jQuery);

/* SVN Revision no 1689- to be implemented later (#SR 1431702 - Product Range Filter - Filter button do not remain highlighted )*/
//AlProducts.ascx
/*$(document).ready(function () {
    $(".component-filters .filters ul.filters-list li").removeClass('active');
    var id = $("input[data-page-id='hdnSelectedFilter']").val();
    var id_1 = "a[data-page-a-id=" + id + "]";

    if ($("input[data-page-id='hdnSelectedFilter']").val() != undefined && $("input[data-page-id='hdnSelectedFilter']").val() != "") {
        $(id_1).parent().addClass('active');
        if (id == "CleanseNoRinse" || id == "CleanseRinseOff" || id == "Mosturise" || id == "Protect") {
            $("#Skincare").parent('li').addClass('active');
        }
    }
});*/
$(document).ready(function () {
    $(".mfp-wrap").attr("id", "btnClose");
});

(function checkIfUserIsProfessional() {
    var cookiename = document.getElementById("cookiename");
    if (cookiename != null) {
        // We have already placed a cookie
        if (document.cookie.indexOf(cookiename.value + "=") !== -1) {
            // User is a professional
            if (document.cookie.indexOf(cookiename.value + "=false") === -1) {
                switchScrollLock("release");
            }
        } else {
            // If the user has not been asked before, disallow scrolling and show question modal
            switchScrollLock("set");
        }
    }
})();

$(".ModalClose").click(function () {
    tenaModal(document.getElementById("cookiename").value, true, '7');
});

function tenaModal(type, value, days) {
    {
        var date, expires;
        if (days === 0) {
            date = new Date();
            date.setFullYear(date.getFullYear() + 1);
            expires = "; expires=" + date.toGMTString();
        } else {
            const dayOne = 24;
            const dayTwo = 60;
            const dayThree = 1000;
            date = new Date();
            date.setTime(date.getTime() + days * dayOne * dayTwo * dayTwo * dayThree);
            expires = "; expires=" + date.toGMTString();
        }
        var path = $("#context-path-value").attr("data-context-path");
        document.cookie = type + "=" + value + expires + "; path=" + path;
        switchScrollLock("release");
    }
}

function switchScrollLock(state) {
    var body = document.querySelector("body");
    var overlay = body.querySelector(".overlay");

    if (state === "set") {
        body.classList.add("u-noscroll");
        overlay.classList.add("isDisplayed");
        document.querySelector(".modal").classList.add("isDisplayed");
    }

    if (state === "release") {
        body.classList.remove("u-noscroll");
        body.removeChild(overlay);
    }
}

(function checkTopLevelDomain() {
    var domainPieces = window.location.hostname.split(".");
    var domain = domainPieces[domainPieces.length - 1].toLowerCase();

    function addCssToHead(url) {
        var head = document.querySelector("head");
        var link = document.createElement("link");
        link.type = "text/css";
        link.rel = "stylesheet";
        link.href = url;
        head.appendChild(link);
    }

    if (domain === "ru" || domain === "ua") {
        // Add CSS to head, in order to load cyrillic fonts
        addCssToHead(window.environment.StaticDirectory + "css/cyrillic-fonts.css");
    }
})();

$(document).ready(function () {
    $(".iconShow-Hide").click(function (e) {
        e.preventDefault();
        $(this).toggleClass('showContent');
        $(this).parent().find('.accordionContent').slideToggle();
    });

    $('.accordionContent.siteMap--Level2 .iconShow-Hide').addClass('showContent');
    const screenWidthConst = 400;
    // Google Captcha size on the mobile
    if (screen.width > screenWidthConst) {
        $('.g-recaptcha').attr('data-size', 'normal');
    } else {
        $('.g-recaptcha').attr('data-size', 'compact');
    }

    ContentSlide();
    function ContentSlide() {
        var slideHeight = 175;
        const contentHeightOne = 991;
        const contentHeightTwo = 97;
        const contentHeightThree = 90;
        const mobcontentSlideThree = 175;
        const contentHeightFour = 44;
        if (window.matchMedia('(max-width: 991px)').matches) {
            if ($(".MobContentSlide").hasClass("ContentSlide--ProductDetails")) {
                if ($(window).width() > contentHeightOne) {
                    var contentHeightDynamic = contentHeightTwo;
                } else {
                    var contentHeightDynamic = mobcontentSlideThree;
                }
            }
            else {
                var contentHeightDynamic = contentHeightFour;
            }
        }
        else {
            if ($(".ContentSlide").hasClass("ContentSlide--ProductDetails")) {
                if ($(window).width() > contentHeightOne) {
                    var contentHeightDynamic = contentHeightTwo;
                } else {
                    var contentHeightDynamic = contentHeightThree;
                }
            }
            else {
                var contentHeightDynamic = contentHeightFour;
            }
        }
        var contentHeight = contentHeightDynamic;
        var minHeight = $(".ContentSlide").height();
        var minMobHeight = $(".MobContentSlide").height();
        if (window.matchMedia('(max-width: 991px)').matches && $('.MobContentSlide').length) {
            if (minMobHeight <= slideHeight) {
                $(".MobContentSlide").removeClass();
                $(".Button--AccordionWrap").hide();
            } else {
                $('.Button--AccordionWrap').css('display', 'block');
                $('.ContentSlide').css('height', contentHeight + 'px');
                var currentOrientation = window.orientation;
                $(".Button--Accordion").click(function () {
                    $(this).toggleClass("show-hide");
                    var curHeight = $(".ContentSlide").height();
                    var curMobHeight = $(".MobContentSlide").height();
                    if (currentOrientation === window.orientation) {
                        if (curMobHeight <= slideHeight) {
                            $(".MobContentSlide").animate({
                                height: minMobHeight
                            }, "fast");
                            $(this).html($(".Button--Accordion").attr("show-less"));
                        } else {
                            $(".MobContentSlide").animate({
                                height: contentHeight
                            }, "fast");
                            $(this).html($(".Button--Accordion").attr("show-more"));
                        }

                    } else {
                        $(".MobContentSlide").toggleClass("heightAuto");
                        if ($(".MobContentSlide").hasClass("heightAuto")) {
                            var NewHeight = 0;
                            $(".MobContentSlide").children().each(function () {
                                NewHeight = NewHeight + $(this).outerHeight(true);
                            });
                            $(".MobContentSlide").animate({
                                height: NewHeight
                            }, "fast");
                            $(this).html($(".Button--Accordion").attr("show-less"));
                        } else {
                            $(".MobContentSlide").animate({
                                height: contentHeightDynamic
                            }, "fast");
                            $(this).html($(".Button--Accordion").attr("show-more"));
                        }
                    }
                });
            }
        } else {

            if (minHeight <= slideHeight) {
                $(".ContentSlide").removeClass();
                $(".Button--AccordionWrap").hide();
            } else {
                $('.Button--AccordionWrap').css('display', 'block');
                $('.ContentSlide').css('height', contentHeight + 'px');
                var currentOrientation = window.orientation;
                $(".Button--Accordion").click(function () {
                    $(this).toggleClass("show-hide");
                    var curHeight = $(".ContentSlide").height();
                    var curMobHeight = $(".MobContentSlide").height();
                    if (currentOrientation === window.orientation) {
                        if (curHeight <= slideHeight) {
                            $(".ContentSlide").animate({
                                height: minHeight
                            }, "fast");
                            $(this).html($(".Button--Accordion").attr("show-less"));
                        } else {
                            $(".ContentSlide").animate({
                                height: contentHeight
                            }, "fast");
                            $(this).html($(".Button--Accordion").attr("show-more"));
                        }
                    } else {
                        $(".ContentSlide").toggleClass("heightAuto");
                        if ($(".ContentSlide").hasClass("heightAuto")) {
                            var NewHeight = 0;
                            $(".ContentSlide").children().each(function () {
                                NewHeight = NewHeight + $(this).outerHeight(true);
                            });
                            $(".ContentSlide").animate({
                                height: NewHeight
                            }, "fast");
                            $(this).html($(".Button--Accordion").attr("show-less"));
                        } else {
                            $(".ContentSlide").animate({
                                height: contentHeightDynamic
                            }, "fast");
                            $(this).html($(".Button--Accordion").attr("show-more"));
                        }
                    }
                });
            }
        }

    }
    $(window).resize(function () {
        $(".ContentSlide").removeClass("heightAuto");
        $(".MobContentSlide").removeClass("heightAuto");
        $(".Button--Accordion").removeClass("show-hide");
        $(".Button--Accordion").html($(".Button--Accordion").attr("show-more"));
        const contentSlideOne = 991;
        const contentSlideTwo = 97;
        const contentSlideThree = 90;
        const mobcontentSlideThree = 175;
        const contentSlideFour = 44;
        if ($(".ContentSlide").hasClass("ContentSlide--ProductDetails")) {
            if ($(window).width() > contentSlideOne) {
                contentHeightDynamic = contentSlideTwo;
            } else {
                contentHeightDynamic = mobcontentSlideThree;
            }
        } if ($(".MobContentSlide").hasClass("ContentSlide--ProductDetails")) {
            if ($(window).width() > contentSlideOne) {
                contentHeightDynamic = contentSlideTwo;
            } else {
                contentHeightDynamic = mobcontentSlideThree;
            }
        } else {
            contentHeightDynamic = contentSlideFour;
        }
        $(".ContentSlide").animate({ height: contentHeightDynamic }, "fast");
        $(".MobContentSlide").animate({ height: contentHeightDynamic }, "fast");
    });

    // mobile view content show more function


    // Product Finder
    $(".ChooseSection li a").click(function (event) {
        event.preventDefault();
        $(".ChooseSection li a").closest(".focus").removeClass("focus");
        $(this).addClass('focus');
    });

    $(".IconWrapper a").click(function (event) {
        event.preventDefault();
        $(this).closest(".ProductFinder-item").find(".focus").removeClass("focus");
        $(this).addClass("focus");
        $(this).closest('.MandatorySection').addClass('validated');
        ValidateSection();
    });

    $(".BooleanWrapper a").click(function (event) {
        event.preventDefault();
        $(this).closest('.MandatorySection').addClass('validated');
        ValidateSection();
    });

    function ValidateSection() {
        if ($('.MandatorySection').length !== $('.validated').length) {
            $('.SectionFilter').addClass('disable');

        } else {
            $('.SectionFilter').removeClass('disable');
            $('.FilterMessage').hide();
        }
    }

    $('.ProductFinder-chooseYes').click(function () {
        event.preventDefault();
        $(this).closest('.ProductFinder-item').find('.ButtonSelection').removeClass("ProductFinder-switchtoNo ProductFinder-notSelected");
        $(this).closest('.ProductFinder-item').find('.ProductFinder-switchtoNo').removeClass("focus");
        $(this).closest('.ProductFinder-item').find('.ButtonSelection').addClass("ProductFinder-switchtoYes");
        $(this).addClass('focus');
    });

    $('.ProductFinder-chooseNo').click(function () {
        event.preventDefault();
        $(this).closest('.ProductFinder-item').find('.ButtonSelection').removeClass("ProductFinder-switchtoYes ProductFinder-notSelected");
        $(this).closest('.ProductFinder-item').find('.ProductFinder-switchtoYes').removeClass("focus");
        $(this).closest('.ProductFinder-item').find('.ButtonSelection').addClass("ProductFinder-switchtoNo");
        $(this).addClass('focus');
    });

    // this function is used for absorbency value in product selector result
    function isInteger(num) {
        return (num ^ 0) === num;
    }

    function GetComplementaryDescription(descriptionText, item) {
        const complementaryWindowWidthOne = 1025;
        const complementarySizeSplitConstOne = 56;
        const complementarySizeSplitConstTwo = 40;
        if ($(window).width() > complementaryWindowWidthOne) {
            if (item.description.length > complementarySizeSplitConstOne) {
                descriptionText = '<p><a href="#" class="js-toggle-tooltip"' +
                    ' tabindex="-1" data-stick="true" data-description="' +
                    item.description + '">' +
                    item.description.substring(0, complementarySizeSplitConstOne)
                    + '<span class="toggle-tooltip">...</span></a></p>';
            } else {
                descriptionText = '<p>' + item.description + '</p>';
            }
        } else {
            if (item.description.length > complementarySizeSplitConstTwo) {
                descriptionText = '<p><span class="noTooltip-mobile" tabindex="-1"' +
                    ' data-stick="true" data-description="' + item.description + '">' +
                    item.description.substring(0, complementarySizeSplitConstTwo)
                    + '<span class="toggle-tooltip">...</span></span></p>';
            } else {
                descriptionText = '<p>' + item.description + '</p>';
            }
        }
        return descriptionText;
    }

    function GetComplementaryHtml(complementaryProducts, item) {
        complementaryProducts += '<div class="ConsiderProduct-container ListingBlock">';
        complementaryProducts += '<div class="ConsiderProduct-img col-3"><img src="'
            + item.productImageUrl + '" alt="' +
            item.altText + '" title="' + item.altText + '" class="ListingProduct-img"></div>';
        complementaryProducts += '<input type="hidden" class="articleNumber" value="' + item.articleNumber + '">';
        complementaryProducts += '<div class="ConsiderProduct col-9">';
        complementaryProducts += '<h4>' + item.productName + '</h4>';
        return complementaryProducts;
    }
    function cleanText(text) {
        return text.replace(/[^a-zA-Z0-9\s]/g, '');  // Removes everything except letters, numbers, and spaces
    }
    $(".SectionFilter").click(function (event) {
        event.preventDefault();
        $('.loaderWrapper').show();

        var filterData = {};
        var productData = {};

        $(".ProductFinder-item .focus").each(function (i, e) {
            productData = {};
            $.each(e.attributes, function (j, v) {
                if (/^data/.test(v.nodeName)) {
                    productData[v.nodeName] = v.nodeValue;
                }
            });
            var id = $(e).closest(".ProductFinder-item").attr("id");
            if (id === "Gender") {
                productData.isUnisexProductFirst = $(".ProductPriority-hidden").val();
                productData.enableSampleForSelector = $(".ProductSampleButton-hidden").val();
            }
            filterData[id] = productData;
        });

        var contextPathValue = $('#context-path-value').attr('data-context-path');
        var URL = window.location.origin + contextPathValue + "/api/Services/ProductFinder/GetProductSelectorResult";

        $.ajax({
            type: 'POST',
            url: URL,
            data: { inputData: JSON.stringify(filterData), complimentaryProducts: $('.ComplimentaryProducts-hidden').val() },
            dataType: 'json',
            success: function (data) {
                $('.loaderWrapper').hide();
                $('.ProductSearch-results').show();
                $('#RecommendedProductBlock').html('');
                $('#ConsiderProductsBlock').html('');
                $('#ComplementaryProductBlock').html('');
                $('#RecommendedProductBlock').show();
                $('#ConsiderProductsBlock').show();
                $('#ComplementaryProductBlock').show();
                $('#ProductHeader').html('');
                $('#ProductHeader').show();
                $("#NoResultFound").hide();
                var jsondata = data;
                var descriptionText = '';
                $('.BigButton').html(jsondata.resultButtonToggleText);
                if (jsondata.noProductsFoundLabel === "") {
                    $('#ProductHeader').html(jsondata.productResultSectionHeader);
                    var recommendedProducts = "";
                    recommendedProducts += '<h4 class="ProductCards--Title">' + jsondata.recommendedProduct.recommendedSectionLabel +
                        '</h4>';
                    recommendedProducts += '<div class="RecommendedProduct SectionContainer--details" >';
                    recommendedProducts += '<p><img src="' + jsondata.recommendedProduct.recommendedProduct.productImageUrl + '"  alt="'
                        + jsondata.recommendedProduct.recommendedProduct.altText + '" title="' +
                        jsondata.recommendedProduct.recommendedProduct.altText + '"></p>';
                    recommendedProducts += '<input type="hidden" class="articleNumber" value="' +
                        jsondata.recommendedProduct.recommendedProduct.articleNumber + '">';
                    const recommnedWindowWidthConst = 1025;
                    const productNameLengthOne = 120;
                    const productNameLengthTwo = 50;
                    if ($(window).width() > recommnedWindowWidthConst) {
                        if (jsondata.recommendedProduct.recommendedProduct.productName.length >= productNameLengthOne) {
                            recommendedProducts += '<h4><a href="#" class="js-toggle-tooltip" tabindex="-1" data-stick="true"' +
                                ' data-description="' + jsondata.recommendedProduct.recommendedProduct.productName + '">' +
                                jsondata.recommendedProduct.recommendedProduct.productName.substring(0, productNameLengthOne) +
                                '<span class="toggle-tooltip">...</span></a></h4>';
                        } else {
                            recommendedProducts += '<h4>' + jsondata.recommendedProduct.recommendedProduct.productName + '</h4>';
                        }
                    } else {
                        if (jsondata.recommendedProduct.recommendedProduct.productName.length >= productNameLengthTwo) {
                            recommendedProducts += '<h4><span class="noTooltip-mobile" tabindex="-1" data-stick="true" data-description="'
                                + jsondata.recommendedProduct.recommendedProduct.productName + '">' +
                                jsondata.recommendedProduct.recommendedProduct.productName.substring(0, productNameLengthTwo) +
                                '<span class="toggle-tooltip">...</span></span></h4>';
                        } else {
                            recommendedProducts += '<h4>' + jsondata.recommendedProduct.recommendedProduct.productName + '</h4>';
                        }
                    }
                    if ($(window).width() > recommnedWindowWidthConst) {
                        if (jsondata.recommendedProduct.recommendedText.length >= productNameLengthOne) {
                            descriptionText = '<p><a href="#" class="js-toggle-tooltip" tabindex="-1" data-stick="true" data-description="'
                                + jsondata.recommendedProduct.recommendedText + '">'
                                + jsondata.recommendedProduct.recommendedText.substring(0, productNameLengthOne)
                                + '<span class="toggle-tooltip">...</span></a></p>';
                        } else {
                            descriptionText = '<p>' + jsondata.recommendedProduct.recommendedText + '</p>';
                        }
                    } else {
                        if (jsondata.recommendedProduct.recommendedText.length >= productNameLengthTwo) {
                            descriptionText = '<p><span class="noTooltip-mobile" tabindex="-1" data-stick="true" data-description="' +
                                jsondata.recommendedProduct.recommendedText + '">' +
                                jsondata.recommendedProduct.recommendedText.substring(0, productNameLengthTwo) +
                                '<span class="toggle-tooltip">...</span></span></p>';
                        } else {
                            descriptionText = '<p>' + jsondata.recommendedProduct.recommendedText + '</p>';
                        }
                    }
                    recommendedProducts += descriptionText;
                    //dot added as per absorbancy unit
                    var recommendedAbsorbencyUnit = jsondata.recommendedProduct.recommendedProduct.absorbencyUnit;
                    if (recommendedAbsorbencyUnit === 'dot') {
                        recommendedProducts += '<div class="ProductResults--attributes"><div class="Attribute--detail"><h4>' +
                            jsondata.absorbencyLabel + ' </h4><ul class="Icon--Dots" id="recommended-rating">';
                    }
                    else {
                        recommendedProducts += '<div class="ProductResults--attributes"><div class="Attribute--detail"><h4>' +
                            jsondata.absorbencyLabel + ' </h4><ul class="rating" id="recommended-rating">';
                    }

                    var recommendedAbsorbencyValue = jsondata.recommendedProduct.recommendedProduct.absorbencyValue;
                    var recommendedMaximumAbsorbency = jsondata.recommendedProduct.recommendedProduct.maximumAbsorbency;
                    if (isInteger(recommendedAbsorbencyValue)) {
                        var totalParts1 = recommendedAbsorbencyValue.toString();
                        var totalHalf1 = 0;
                    } else {
                        var totalParts1 = recommendedAbsorbencyValue.toString().split(".");
                        var totalHalf1 = totalParts1.length > 0 ? totalParts1[1].length : 0;
                    }
                    var totalInteger1 = parseInt(totalParts1[0], 10);
                    var temp1 = "";
                    if (recommendedAbsorbencyUnit === 'dot') {
                        const dotTotalCount = 4;
                        for (var j = 0; j < totalInteger1; j++) {
                            temp1 += '<li><i class="icon-dots-full"></i></li>';
                        }
                        for (var j = 0; j < totalHalf1; j++) {
                            temp1 += '<li><i class="icon-dots-half"></i></li>';
                        }
                        if (recommendedMaximumAbsorbency !== null && recommendedMaximumAbsorbency !== 0) {
                            for (var j = 0; j < recommendedMaximumAbsorbency - (totalHalf1 + totalInteger1); j++) {
                                temp1 += '<li><i class="icon-dots-empty"></i></li>';
                            }
                        }
                        else if (recommendedMaximumAbsorbency === 0) {
                            for (var j = 0; j < dotTotalCount - (totalHalf1 + totalInteger1); j++) {
                                temp1 += '<li><i class="icon-dots-empty"></i></li>';
                            }
                        }
                        else {
                            for (var j = 0; j < dotTotalCount - (totalHalf1 + totalInteger1); j++) {
                                temp1 += '<li><i class="icon-dots-empty"></i></li>';
                            }
                        }
                    }
                    else {
                        const dropTotalCount = 8;
                        for (var j = 0; j < totalInteger1; j++) {
                            temp1 += '<li><i class="icon-drop-full"></i></li>';
                        }
                        for (var j = 0; j < totalHalf1; j++) {
                            temp1 += '<li><i class="icon-drop-half-full"></i></li>';
                        }
                        if (recommendedMaximumAbsorbency !== null && recommendedMaximumAbsorbency !== 0) {
                            for (var j = 0; j < recommendedMaximumAbsorbency - (totalHalf1 + totalInteger1); j++) {
                                temp1 += '<li><i class="icon-drop-empty"></i></li>';
                            }
                        }
                        else if (recommendedMaximumAbsorbency === 0) {
                            for (var j = 0; j < dropTotalCount - (totalHalf1 + totalInteger1); j++) {
                                temp1 += '<li><i class="icon-drop-empty"></i></li>';
                            }
                        }
                        else {
                            for (var j = 0; j < dropTotalCount - (totalHalf1 + totalInteger1); j++) {
                                temp1 += '<li><i class="icon-drop-empty"></i></li>';
                            }
                        }
                    }

                    $("#recommended-rating").html(temp1);
                    recommendedProducts += temp1;
                    recommendedProducts += '</ul></div>';
                    var recommendedSizesAvailable = jsondata.recommendedProduct.recommendedProduct.sizesAvailable;
                    var recommendedSizeSplit = recommendedSizesAvailable.split(" ").length;
                    const sizeConstOne = 2;
                    const sizeConstTwo = 5;
                    const sizeConstThree = 6;
                    const recommendWidthConst = 1025;
                    if ($(window).width() > recommendWidthConst) {
                        if (recommendedSizeSplit >= sizeConstOne && recommendedSizeSplit <= sizeConstTwo) {
                            recommendedProducts += '<div class="Attribute--detail Sizes--available"><h4>' + jsondata.sizesAvailableLabel
                                + '</h4>';
                            recommendedProducts += '<span>' + recommendedSizesAvailable + '</span></div>';
                        } else if (recommendedSizeSplit >= sizeConstThree) {
                            recommendedProducts += '<div class="Attribute--detail Sizes--available"><h4>' + jsondata.sizesAvailableLabel +
                                '</h4>';
                            recommendedProducts += '<span><a href="#" class="js-toggle-tooltip" tabindex="-2" data-stick="true"' +
                                ' data-description="' + recommendedSizesAvailable + '">' +
                                recommendedSizesAvailable.split(/\s+/).slice(0, sizeConstTwo).join(" ") +
                                '<span class="toggle-tooltip">...</span></a></span></div>';
                        }
                    } else {
                        if (recommendedSizeSplit >= sizeConstOne && recommendedSizeSplit <= sizeConstTwo) {
                            recommendedProducts += '<div class="Attribute--detail Sizes--available"><h4>' + jsondata.sizesAvailableLabel +
                                '</h4>';
                            recommendedProducts += '<span>' + recommendedSizesAvailable + '</span></div>';
                        } else if (recommendedSizeSplit >= sizeConstThree) {
                            recommendedProducts += '<div class="Attribute--detail Sizes--available"><h4>' + jsondata.sizesAvailableLabel +
                                '</h4>';
                            recommendedProducts += '<span class="noTooltip-mobile" tabindex="-2" data-stick="true" data-description="' +
                                recommendedSizesAvailable + '">' +
                                recommendedSizesAvailable.split(/\s+/).slice(0, sizeConstTwo).join(" ")
                                + '<span class="toggle-tooltip">...</span></span>' +
                                '</div>';
                        }
                    }

                    recommendedProducts += '</div>';

                    if (jsondata.recommendedProduct.isSampleEnableInCms === true) {
                        if (jsondata.recommendedProduct.sampleOrderButtonText !== "") {
                            recommendedProducts += '<a href="' + jsondata.recommendedProduct.sampleOrderRedirectUrl +
                                '" class="CTA-right-arrow Button--default" target="new"><span>' +
                                jsondata.recommendedProduct.sampleOrderButtonText + '</span></a>';
                        }
                        recommendedProducts += '<a href = "' + jsondata.recommendedProduct.recommendedProduct.productPageUrl +
                            '" class="CTA-right-arrow Button--default secondaryCTA" target="new" class="Button--default"><span>' +
                            jsondata.findOutMoreLabel + '</span></a>';
                    } else {
                        recommendedProducts += '<a href = "' + jsondata.recommendedProduct.recommendedProduct.productPageUrl +
                            '" class="CTA-right-arrow Button--default" target="new" class="Button--default"><span>' +
                            jsondata.findOutMoreLabel + '</span></a>';
                    }

                    recommendedProducts += '</div>';

                    $('#RecommendedProductBlock').append(recommendedProducts);
                    $(".js-toggle-tooltip").tooltip();
                    if (typeof jsondata.considerationProducts !== "undefined") {
                        if (typeof jsondata.considerationProducts.products !== "undefined" &&
                            jsondata.considerationProducts.products.length !== 0) {
                            var considerationProducts = '<h4 class="ProductCards--Title">' +
                                jsondata.considerationProducts.considerSectionLabel + '</h4>';
                            var consierProductsArray = jsondata.considerationProducts.products;
                            $.each(consierProductsArray, function (id, item) {
                                considerationProducts += '<div class="ConsiderProduct-container ListingBlock">';
                                considerationProducts += '<div class="ConsiderProduct-img col-3"><img src="' + item.productImageUrl +
                                    '" alt="' + item.altText + '" title="' + item.altText + '" class="ListingProduct-img"></div>';
                                considerationProducts += '<input type="hidden" class="articleNumber" value="' + item.articleNumber + '">';
                                considerationProducts += '<div class="ConsiderProduct col-9">';
                                const windowWidthConstant = 1228;
                                const considerationProductLengthOne = 55;
                                const considerationProductLengthTwo = 32;
                                const considerationProductLengthThree = 56;
                                const considerationProductLengthFour = 40;
                                if ($(window).width() > windowWidthConstant) {
                                    if (item.productName.length >= considerationProductLengthOne) {
                                        var productNameText = '<h4><a href="#" class="js-toggle-tooltip"' +
                                            ' tabindex="-2" data-stick="true" data-description="' + item.productName + '">' +
                                            item.productName.substring(0, considerationProductLengthOne)
                                            + '<span class="toggle-tooltip">...</span></a></h4>';
                                    } else {
                                        var productNameText = '<h4>' + item.productName + '</h4>';
                                    }
                                } else {
                                    if (item.productName.length >= considerationProductLengthTwo) {
                                        var productNameText = '<h4><span class="noTooltip-mobile"' +
                                            ' tabindex="-2" data-stick="true" data-description="' + item.productName + '">' +
                                            item.productName.substring(0, considerationProductLengthTwo) +
                                            '<span class="toggle-tooltip">...</span></span></h4>';
                                    } else {
                                        var productNameText = '<h4>' + item.productName + '</h4>';
                                    }
                                }
                                considerationProducts += productNameText;
                                if ($(window).width() > windowWidthConstant) {
                                    if (item.description.length >= considerationProductLengthThree) {
                                        descriptionText = '<p><a href="#" class="js-toggle-tooltip" ' +
                                            ' tabindex="-1" data-stick="true" data-description="' +
                                            item.description + '">' + item.description.substring(0, considerationProductLengthThree) +
                                            '<span class="toggle-tooltip">...</span></a></p>';
                                    } else {
                                        descriptionText = '<p>' + item.description + '</p>';
                                    }
                                } else {
                                    if (item.description.length >= considerationProductLengthFour) {
                                        descriptionText = '<p><span class="noTooltip-mobile"' +
                                            ' tabindex="-1" data-stick="true" data-description="' +
                                            item.description + '">' +
                                            item.description.substring(0, considerationProductLengthFour) +
                                            '<span class="toggle-tooltip">...</span></span></p>';
                                    } else {
                                        descriptionText = '<p>' + item.description + '</p>';
                                    }
                                }
                                var considerationAbsorbencyUnit = item.absorbencyUnit;
                                considerationProducts += descriptionText;
                                if (considerationAbsorbencyUnit === 'dot') {
                                    considerationProducts += '<div class="ProductResults--attributes"><div class="Attribute--detail"><h4>'
                                        + jsondata.absorbencyLabel + '</h4><ul class="Icon--Dots" id="consider-rating">';
                                }
                                else {
                                    considerationProducts += '<div class="ProductResults--attributes"><div class="Attribute--detail"><h4>'
                                        + jsondata.absorbencyLabel + '</h4><ul class="rating" id="consider-rating">';
                                }

                                var considerationAbsorbencyValue = item.absorbencyValue;
                                var considerationMaximumAbsorbency = item.maximumAbsorbency;
                                if (isInteger(considerationAbsorbencyValue)) {
                                    var totalParts = considerationAbsorbencyValue.toString();
                                    var totalHalf = 0;
                                } else {
                                    var totalParts = considerationAbsorbencyValue.toString().split(".");
                                    var totalHalf = totalParts.length > 0 ? totalParts[1].length : 0;
                                }
                                var totalInteger = parseInt(totalParts[0], 10);
                                var temp = "";
                                const considerDotCount = 4;
                                const considerDropCount = 8;
                                if (considerationAbsorbencyUnit === 'dot') {
                                    for (var k = 0; k < totalInteger; k++) {
                                        temp += '<li><i class="icon-dots-full"></i></li>';
                                    }
                                    for (var k = 0; k < totalHalf; k++) {
                                        temp += '<li><i class="icon-dots-half"></i></li>';
                                    }
                                    if (considerationMaximumAbsorbency !== null && considerationMaximumAbsorbency !== 0) {
                                        for (var k = 0; k < considerationMaximumAbsorbency - (totalHalf + totalInteger); k++) {
                                            temp += '<li><i class="icon-dots-empty"></i></li>';
                                        }
                                    }
                                    else if (considerationMaximumAbsorbency === 0) {
                                        for (var k = 0; k < considerDotCount - (totalHalf + totalInteger); k++) {
                                            temp += '<li><i class="icon-dots-empty"></i></li>';
                                        }
                                    }
                                    else {
                                        for (var k = 0; k < considerDropCount - (totalHalf + totalInteger); k++) {
                                            temp += '<li><i class="icon-dots-empty"></i></li>';
                                        }
                                    }
                                }
                                else {
                                    for (var k = 0; k < totalInteger; k++) {
                                        temp += '<li><i class="icon-drop-full"></i></li>';
                                    }
                                    for (var k = 0; k < totalHalf; k++) {
                                        temp += '<li><i class="icon-drop-half-full"></i></li>';
                                    }
                                    if (considerationMaximumAbsorbency !== null && considerationMaximumAbsorbency !== 0) {
                                        for (var k = 0; k < considerationMaximumAbsorbency - (totalHalf + totalInteger); k++) {
                                            temp += '<li><i class="icon-drop-empty"></i></li>';
                                        }
                                    }
                                    else if (considerationMaximumAbsorbency === 0) {
                                        for (var k = 0; k < considerDropCount - (totalHalf + totalInteger); k++) {
                                            temp += '<li><i class="icon-drop-empty"></i></li>';
                                        }
                                    }
                                    else {
                                        for (var k = 0; k < considerDropCount - (totalHalf + totalInteger); k++) {
                                            temp += '<li><i class="icon-drop-empty"></i></li>';
                                        }
                                    }
                                }

                                $("#consider-rating").html(temp);

                                considerationProducts += temp;
                                considerationProducts += '</ul></div>';
                                var considerSizesAvailable = item.sizesAvailable;
                                var considerSizeSplit = considerSizesAvailable.split(" ").length;
                                const considerWindowWidthOne = 1025;
                                const considerSizeSplitConstOne = 2;
                                const considerSizeSplitConstTwo = 5;
                                const considerSizeSplitConstThree = 6;
                                if ($(window).width() > considerWindowWidthOne) {
                                    if (considerSizeSplit >= considerSizeSplitConstOne && considerSizeSplit <= considerSizeSplitConstTwo) {
                                        considerationProducts += '<div class="Attribute--detail Sizes--available"><h4>' +
                                            jsondata.sizesAvailableLabel + '</h4>';
                                        considerationProducts += '<span>' + considerSizesAvailable + '</span></div>';
                                    } else if (considerSizeSplit >= considerSizeSplitConstThree) {
                                        considerationProducts += '<div class="Attribute--detail Sizes--available"><h4>' +
                                            jsondata.sizesAvailableLabel + '</h4>';
                                        considerationProducts += '<span><a href="#" class="js-toggle-tooltip" tabindex="-2"' +
                                            ' data-stick="true" data-description="' + considerSizesAvailable + '">' +
                                            considerSizesAvailable.split(/\s+/).slice(0, considerSizeSplitConstTwo).join(" ") +
                                            '<span class="toggle-tooltip">...</span></a></span></div>';
                                    }
                                } else {
                                    if (considerSizeSplit >= considerSizeSplitConstOne && considerSizeSplit <= considerSizeSplitConstTwo) {
                                        considerationProducts += '<div class="Attribute--detail Sizes--available"><h4>' +
                                            jsondata.sizesAvailableLabel + '</h4>';
                                        considerationProducts += '<span>' + considerSizesAvailable + '</span></div>';
                                    } else if (considerSizeSplit >= considerSizeSplitConstThree) {
                                        considerationProducts += '<div class="Attribute--detail Sizes--available"><h4>' +
                                            jsondata.sizesAvailableLabel + '</h4>';
                                        considerationProducts += '<span class="noTooltip-mobile" tabindex="-2" data-stick="true"' +
                                            ' data-description="' + considerSizesAvailable + '">' +
                                            considerSizesAvailable.split(/\s+/).slice(0, considerSizeSplitConstTwo).join(" ") +
                                            '<span class="toggle-tooltip">...</span></span></div>';
                                    }
                                }
                                const considerWindowWidthTwo = 991;
                                if ($(window).width() > considerWindowWidthTwo) {
                                    considerationProducts += '</div></div><div class="ProductResult--ProductCardCTA">';
                                    if (item.isSampleEnableInCms === true) {
                                        if (item.sampleOrderButtonText !== "") {
                                            considerationProducts += '<a href="' + item.sampleOrderRedirectUrl +
                                                '" class="CTA-right-arrow Button--default" target="new"><span>'
                                                + item.sampleOrderButtonText + '</span></a>';
                                        }
                                        considerationProducts += '<a href="' + item.productPageUrl +
                                            '" class="CTA-right-arrow Button--default secondaryCTA" target="new"><span>'
                                            + jsondata.findOutMoreLabel + '</span></a>';
                                    } else {
                                        considerationProducts += '<a href="' + item.productPageUrl +
                                            '" class="CTA-right-arrow Button--default" target="new"><span>'
                                            + jsondata.findOutMoreLabel + '</span></a>';
                                    }
                                    considerationProducts += '</div></div>';
                                } else {
                                    considerationProducts += '</div><div class="ProductResult--ProductCardCTA">';
                                    if (item.isSampleEnableInCms === true) {
                                        if (item.sampleOrderButtonText !== "") {
                                            considerationProducts += '<a href="' + item.sampleOrderRedirectUrl +
                                                '" class="CTA-right-arrow Button--default" target="new"><span>'
                                                + item.sampleOrderButtonText + '</span></a>';
                                        }
                                        considerationProducts += '<a href="' + item.productPageUrl +
                                            '" class="CTA-right-arrow Button--default secondaryCTA" target="new"><span>'
                                            + jsondata.findOutMoreLabel + '</span></a>';
                                    } else {
                                        considerationProducts += '<a href="' + item.productPageUrl +
                                            '" class="CTA-right-arrow Button--default" target="new"><span>'
                                            + jsondata.findOutMoreLabel + '</span></a>';
                                    }
                                    considerationProducts += '</div></div></div>';
                                }
                            });
                            $('#ConsiderProductsBlock').append(considerationProducts);
                            $(".js-toggle-tooltip").tooltip();
                        } else
                            $('#ConsiderProductsBlock').hide();
                    } else
                        $('#ConsiderProductsBlock').hide();
                    if (typeof jsondata.complimentaryProducts !== "undefined") {
                        if (typeof jsondata.complimentaryProducts.products !== "undefined" &&
                            jsondata.complimentaryProducts.products.length !== 0) {
                            var complementaryProducts = '<h4 class="ProductCards--Title">' +
                                jsondata.complimentaryProducts.complimentaryProductSectionLabel + '</h4>';
                            var complementaryProductsArray = jsondata.complimentaryProducts.products;
                            $.each(complementaryProductsArray, function (id, item) {
                                complementaryProducts = GetComplementaryHtml(complementaryProducts, item);
                                descriptionText = GetComplementaryDescription(descriptionText, item);
                                complementaryProducts += descriptionText;
                                const complementaryWindowWidthTwo = 991;
                                if ($(window).width() > complementaryWindowWidthTwo) {
                                    complementaryProducts += '</div><div class="ProductResult--ProductCardCTA">';
                                    if (item.isSampleEnableInCms === true) {
                                        if (item.sampleOrderButtonText !== "") {
                                            complementaryProducts += '<a href="' + item.sampleOrderRedirectUrl +
                                                '" class="CTA-right-arrow Button--default" target="new"><span>' +
                                                item.sampleOrderButtonText + '</span></a>';
                                        }
                                        complementaryProducts += '<a href="' + item.productPageUrl +
                                            '" class="CTA-right-arrow Button--default secondaryCTA" target="new"><span>' +
                                            jsondata.findOutMoreLabel + '</span></a>';
                                    } else {
                                        complementaryProducts += '<a href="' + item.productPageUrl +
                                            '" class="CTA-right-arrow Button--default" target="new"><span>' +
                                            jsondata.findOutMoreLabel + '</span></a>';
                                    }
                                    complementaryProducts += '</div></div>';
                                } else {
                                    complementaryProducts += '<div class="ProductResult--ProductCardCTA">';
                                    if (item.isSampleEnableInCms === true) {
                                        if (item.sampleOrderButtonText !== "") {
                                            complementaryProducts += '<a href="' + item.sampleOrderRedirectUrl +
                                                '" class="CTA-right-arrow Button--default" target="new"><span>' + item.sampleOrderButtonText +
                                                '</span></a>';
                                        }
                                        complementaryProducts += '<a href="' + item.productPageUrl +
                                            '" class="CTA-right-arrow Button--default secondaryCTA" target="new"><span>' +
                                            jsondata.findOutMoreLabel + '</span></a>';
                                    } else {
                                        complementaryProducts += '<a href="' + item.productPageUrl +
                                            '" class="CTA-right-arrow Button--default" target="new"><span>' +
                                            jsondata.findOutMoreLabel + '</span></a>';
                                    }

                                    complementaryProducts += '</div></div></div>';
                                }
                            });
                            $('#ComplementaryProductBlock').append(complementaryProducts);
                        }
                        else $('#ComplementaryProductBlock').hide();
                    }
                    else $('#ComplementaryProductBlock').hide();

                    const bigButtonDelayDuration = 1000;
                    $('html, body').delay(bigButtonDelayDuration).animate({
                        scrollTop: ($('.BigButton-Container').offset().top)
                    });

                    $(".js-toggle-tooltip").tooltip();
                } else {
                    $('#ProductHeader').hide();
                    $('#RecommendedProductBlock').hide();
                    $('#ConsiderProductsBlock').hide();
                    $('#ComplementaryProductBlock').hide();
                    $("#NoResultFound").html(jsondata.noProductsFoundLabel);
                    $("#NoResultFound").show();
                }
            },
            error: function () {
                //var err = eval("(" + xhr.responseText + ")");
            }
        });
    });

    $(".Button--resetSelection").click(function () {
        event.preventDefault();
        $(".ChooseSection li a").removeClass("focus");
        $(".ProductFinder-item").find(".focus").removeClass("focus");
        $('.BooleanWrapper a').removeClass("focus");
        $('.ChooseTitle a').removeClass("focus");
        $('.ButtonWrapper .ButtonSelection').removeClass("ProductFinder-switchtoYes ProductFinder-switchtoNo");
        $('.ButtonWrapper .ButtonSelection').addClass("ProductFinder-notSelected");
        $('.SectionFilter').addClass('disable');
        $('.MandatorySection').removeClass('validated');
        $('.ProductSearch-results').hide();
        $('html, body').delay(1000).animate({
            scrollTop: ($('.PageTitle').offset().top)
        });
        $('.FilterMessage').show();
    });

    // Product finder search
    var baseurl = $("#SearchUrl").val() + "?q=";
    //var suffix = "&hits=10&offset=0&facet_section=" + $("#SearchTab").val();

    $('.ProductFinder-search').click(function () {
        var searchproduct = $(".SearchInput").val();
        $(location).attr('href', baseurl + searchproduct);
    });

    const articleTextLength = 21;
    $('.ArticleProgress-backgroundColor').each(function () {
        if ($(this).text().length > articleTextLength) {
            $(this).text($(this).text().substring(0, articleTextLength));
        }
    });

    const messageDelayDuartion = 1000;
    const messageOffsetTop = 130;
    $('#btnOrderSample').click(function () {
        if ($('.validate-message').is(':visible')) {
            $('html, body').delay(messageDelayDuartion).animate({
                scrollTop: ($('.Form--MainWrapper').find('.validate-message:visible:first').offset().top - messageOffsetTop)
            });
        }
    });

    // Promobox alignment for screen 768px to 1227px
    var screenWidth = $(window).width();
    const promoScreenWdithOne = 767;
    const promoScreenWdithTwo = 1228;
    const promoScreenWdithThree = 250;
    if (screenWidth > promoScreenWdithOne && screenWidth < promoScreenWdithTwo) {
        $(".Promobox--SingleCard").first().closest(".section.vspace-sm")
            .after('<div class="section vspace-sm"><div class="container"><div class="Promobox--Wrapper"></div></div></div>');
        $(".Promobox--SingleCard").each(function () {
            $(this).closest(".section.vspace-sm").remove();
            $(this).clone().remove().appendTo(".Promobox--Wrapper");
        });
    }

    // Scroll to top
    $('.Button--backToTop').hide();
    if (screenWidth > promoScreenWdithOne) {
        $(window).bind('scroll', function () {
            if ($(this).scrollTop() > promoScreenWdithThree) {
                $('.Button--backToTop').fadeIn('slow');
            } else {
                $('.Button--backToTop').fadeOut('slow');
            }
        });
    };

    const backToTopInterval = 400;
    $('.Button--backToTop').click(function () {
        event.preventDefault();
        $('html, body').animate({ scrollTop: 0 }, backToTopInterval);
    });
});

//hide review dropdown if all stars are empty 
$(window).on("load", function () {
    if ($("#reviewDropdown, .accordationReview").length) {
        setTimeout(function () {
            if ($(".accordationReview .pr-rating-stars").length) {
                $("#reviewDropdown .accordationReview .pr-star-v4-0-filled").each(function () {
                    var totoalZeroReview = $("#reviewDropdown").find(".accordationReview .pr-star-v4-0-filled");
                    if (totoalZeroReview.length === 5) {
                        $("#reviewDropdown").addClass("hidden");
                    };
                })
            };
        }, 500);
    }
});
// onclick review link scroll review comment section
var reviewDropdownScroll = function (event) {
    var reviewDropdown = $('#reviewDropdown');
    $('.pr-snippet-read-and-write a, .pr-snippet-review-count').click(function () {
        $(reviewDropdown).removeClass('accordion-collapsed');
        $(reviewDropdown).addClass('accordion-expanded');
        $(reviewDropdown).find('.accordion-title i').addClass('icon-arrow-up');
        $(reviewDropdown).find('.accordion-title i').removeClass('icon-arrow-down');
        $(reviewDropdown).find('.accordion-content').css('opacity', '1');
    });
}

$(document).ready(function () {
    // H1 headline append to desk or mob
    if ($('.deskHeadline').length) {
        $('.mainHeadlineSection').appendTo('.deskHeadline').css('display', 'block');
    } else {
        $('.mainHeadlineSection').remove('.deskHeadline');
    }
    if (window.matchMedia('(max-width: 991px)').matches) {
        if ($('.mobHeadline').length) {
            $('.mainHeadlineSection').appendTo('.mobHeadline').css('display', 'block');
        } else {
            $('.mainHeadlineSection').appendTo('.deskHeadline').css('display', 'block');
        }
    }
    // ProductVdo append 
    if ($('.deskProductVdo').length) {
        $('.ProductVdo').appendTo('.deskProductVdo').css('display', 'block');
    } else {
        $('.ProductVdo').remove('.deskProductVdo');
    }
    if (window.matchMedia('(max-width: 991px)').matches) {
        if ($('.mobProductVdo').length) {
            $('.ProductVdo').appendTo('.mobProductVdo').css('display', 'block');
        } else {
            $('.ProductVdo').appendTo('.deskProductVdo').css('display', 'block');
        }
    }
    // Review main section append 
    if ($('.deskReviewMainSection').length) {
        $('.reviewMainSection').appendTo('.deskReviewMainSection').css('display', 'block');
    } else {
        $('.reviewMainSection').remove('.deskReviewMainSection');
    }
    if (window.matchMedia('(max-width: 991px)').matches) {
        if ($('.mobReviewMainSection').length) {
            $('.reviewMainSection').appendTo('.mobReviewMainSection').css('display', 'block');
        } else {
            $('.reviewMainSection').appendTo('.deskReviewMainSection').css('display', 'block');
        }
    }
    // redesign product detail mobile view header scroll
    if (window.matchMedia('(max-width: 767px)').matches) {
        if ($(".component-product-intro").length) {
            if (window.matchMedia('(max-width: 767px)').matches) {
                $('.mobile-header').css('position', 'absolute');
                $('.TenaHeader .header-top').css('position', 'absolute');
                $('body').css('margin-top', '0');
                $('.component-product-intro').css('margin-top', '80px');
            } else {
                $('.mobile-header').css('position', 'fixed');
                $('.TenaHeader .header-top').css('position', 'fixed');
                $('.component-product-intro').css('margin-top', '0px');
            }
        }
    }
})
// ProductVdo and reviewsection append resize
$(window).resize(function () {
    //less then 1023 cover mob and ipad
    if ($(window).width() <= 991) {
        $('.mainHeadlineSection').appendTo('.mobHeadline').css('display', 'block');
        $('.ProductVdo').appendTo('.mobProductVdo').css('display', 'block');
        $('.reviewMainSection').appendTo('.mobReviewMainSection').css('display', 'block');
    } else {
        $('.mainHeadlineSection').appendTo('.deskHeadline').css('display', 'block');
        $('.ProductVdo').appendTo('.deskProductVdo').css('display', 'block');
        $('.reviewMainSection').appendTo('.deskReviewMainSection').css('display', 'block');
    }
})
// ProductBenefit append and product headline append
var ProductBenefitApnd = function (event) {
    if ($('.deskProductBenefit').length) {
        $('.ProductBenefit').appendTo('.deskProductBenefit').css('display', 'block');
        if ($('.ProductBenefit').is(':visible') || $('.ProductBenefit').length) {
            $('.pr-snippet-read-and-write').css('opacity', '1');
            reviewDropdownScroll(event);
        }
        else { }
    }
    else {
        $('.ProductBenefit').remove('.deskProductBenefit');
    }
    if (window.matchMedia('(max-width: 991px)').matches) {
        if ($('.mobProductBenefit').length) {
            $('.ProductBenefit').appendTo('.mobProductBenefit').css('display', 'block');
            if ($('.ProductBenefit').is(':visible')) {
                $('.pr-snippet-read-and-write').css('opacity', '1');
                reviewDropdownScroll(event);
            }
            else {
                $('.pr-snippet-read-and-write').css('opacity', '0');
            }
        } else {
            $('.ProductBenefit').remove('.deskProductBenefit');
        }
    }
}

// ProductBenefit append resize
$(window).resize(function () {
    //less then 1023 cover mob and ipad
    if ($(window).width() <= 991) {
        $('.ProductBenefit').appendTo('.mobProductBenefit').css('display', 'block');
        if ($('.ProductBenefit').is(':visible')) {
            //onclick review show review dropdown  
            $('.pr-snippet-read-and-write').css('opacity', '1');
            reviewDropdownScroll(event);
        }
        else {
            $('.pr-snippet-read-and-write').css('opacity', '0');
        }
    } else {
        $('.ProductBenefit').appendTo('.deskProductBenefit').css('display', 'block');
    }
})
$(window).load(function () {
    ProductBenefitApnd(event);
});
$(window).resize(function () {
    ProductBenefitApnd(event);
});
$(document).ready(function () {
    ProductBenefitApnd(event);
    //Product detail Redesign Pack Size menu
    $('.viewport').after('<div class="packSize" style="overflow: hidden;"></div><div style="top:0;bottom:0;right: 0;left:0;pointer-events:none;z-index: 1043;top: 0;height: 100%;" class="mainT"><div style="position: absolute;width: 100%;left: 0;" class="backdropWrapper"></div></div>');

    //Product detail Redesign Functionality Pack size appended HTML 
    var packSizeMenu = '<div class="packSizeWrapper" id="packSizeWrapper"><div class="packSizeInfo"><div class="packSizeHeader"><div class="closeThis" id="closeThis"><svg data-v-6a943414="" width="24" height="24" style="fill:white" xmlns="http://www.w3.org/2000/svg" class="close-icon"><path d="M6.414 5A1 1 0 1 0 5 6.414L10.586 12 5 17.586A1 1 0 1 0 6.414 19L12 13.414 17.586 19A1 1 0 1 0 19 17.586L13.414 12 19 6.414A1 1 0 1 0 17.586 5L12 10.586 6.414 5Z"></path></svg></div></div><div class="row" id="checkInfo"><div class="row redesignMarginB paraMarBot" id="innerHtmlPackSize"></div>';
    if ($('.backdropWrapper').length) {
        $('.backdropWrapper').append(packSizeMenu);
        $('#packSizeWrapper').css('display', 'none');
    }
    //Pack size slide right window onclick close
    $(".clickshowinfo").click(function () {
        $('.mainT').css('position', 'fixed');
        $('.menu-wrapper.sticky').css('z-index', '999');
        $('#packSizeWrapper').css('display', 'block');
        $('#packSizeHtml').css('display', 'block');
        $(".backdropWrapper").css({
            height: "100%"
        });
        if ($('.packSizeWrapper').length) {
            var replaceHtml = document.getElementById("packSizeHtml").innerHTML;
            document.getElementById("innerHtmlPackSize").innerHTML = replaceHtml;
        } else {
            return false;
        }
        if (window.matchMedia('(max-width: 767px)').matches) {
            $(".packSizeWrapper").animate({
                top: "auto",
                bottom: "0"
            }, 200);
            $(".packSizeInfo").animate({
                top: "auto",
                bottom: "0"
            }, 200);
        } else if (window.matchMedia('(max-width: 1024px)').matches) {
            $(".packSizeWrapper").animate({
                width: "45%"
            }, 200);
            $(".packSizeInfo").animate({
                width: "100%"
            }, 200);
        } else {
            $(".packSizeWrapper").animate({
                width: "29%"
            }, 200);
            $(".packSizeInfo").animate({
                width: "100%"
            }, 200);
        }
        $(".backdropWrapper").css('pointer-events', 'all');
        $('.packSize').addClass('packSizeBackdrop');

        $('html').css('overflow', 'hidden');
    });


    //Pack size slide right window outside close
    var backdropWrapper = document.getElementsByClassName("backdropWrapper");
    var closeFunction = function (e) {
        var containerT = document.getElementById('packSizeWrapper');
        var closeThiscloseThis = document.getElementById('closeThis');
        var clickshowinfo = document.getElementById(clickshowinfo);
        if ($('.packSizeWrapper').length) {
            if (!containerT.contains(e.target) || closeThis.contains(e.target)) {
                $('#packSizeHtml').css('display:none');
                $('html').css('overflow', 'auto');
                $('#packSizeHtml').css('display', 'none');
                $('.menu-wrapper.sticky').css('z-index', '10000');
                if (window.matchMedia('(max-width: 767px)').matches) {
                    $(".packSizeWrapper").animate({
                        top: "auto",
                        bottom: "-100%"
                    }, 200);
                    $(".packSizeInfo").animate({
                        top: "auto",
                        bottom: "-100%"
                    }, 200);
                    $('#packSizeHtml').css('display:none');
                } else {
                    $(".packSizeWrapper").animate({
                        width: "0%"
                    }, 200);
                    $(".packSizeInfo").animate({
                        width: "0%"
                    }, 200);
                }

                $(".backdropWrapper").css('pointer-events', 'none');
                $('.packSize').removeClass('packSizeBackdrop');
            }
        }
    };
    for (var i = 0; i < backdropWrapper.length; i++) {
        backdropWrapper[i].addEventListener('click', closeFunction, false);
    }
    $(".closeThis").click(function (e) {
        closeFunction(e);
    });
});

(function ($, window, document, undefined) {
    var LightboxFunc = 'Lightboxed';
    var namespace = 'lightboxed--';

    function Lightboxed(element, options) {
        jQuery.extend(
            this, {
            Obj: element,
            _name: LightboxFunc,
            _defaults: $.fn.lightboxed.defaults,
            options: jQuery.extend({}, $.fn.lightboxed.defaults, options),
            lightbox: {},
            cache: {},
            index: false,
            touch: {},
        }
        );
        this.init();
    }
    $.extend(Lightboxed.prototype, {
        init: function () {
            var that = this;
            this.CacheObj = (jQuery(document).find('#' + namespace + 'cache').length > 0) ? jQuery(document).find('#' + namespace + 'cache') : jQuery('<div id="' + namespace + 'cache" />').appendTo('body');
            this.cache.container = jQuery('<div id="' + this.uniq + '" />').appendTo(this.CacheObj);
            this.cache.content = jQuery('<div class="' + namespace + 'cached_content" />').appendTo(this.cache.container);
            if (this.Obj.length > 1) {
                this.cache.thumbs = jQuery('<div class="' + namespace + 'cached_thumbs" />').appendTo(this.cache.container);
            }
            this.Obj.each(function () {
                that.addToCache(this);
            });
            this.lightbox.container = (jQuery(document).find('#' + namespace + 'container').length > 0) ? jQuery(document).find('#' + namespace + 'container') : jQuery('<div id="' + namespace + 'container" />').addClass(this.options.lightboxCloseClass).appendTo('body');
            this.lightbox.stage = (this.lightbox.container.find('#' + namespace + 'stage').length > 0) ? this.lightbox.container.find('#' + namespace + 'stage') : jQuery('<div id="' + namespace + 'stage" />').appendTo(this.lightbox.container);
            this.lightbox.content = (this.lightbox.stage.find('#' + namespace + 'content').length > 0) ? this.lightbox.stage.find('#' + namespace + 'content') : jQuery('<div id="' + namespace + 'content" />').appendTo(this.lightbox.stage);
            this.lightbox.thumbs = (this.lightbox.container.find('#' + namespace + 'thumbs').length > 0) ? this.lightbox.container.find('#' + namespace + 'thumbs') : jQuery('<div id="' + namespace + 'thumbs" />').appendTo(this.lightbox.stage);
            this.lightbox.close = (this.lightbox.container.find('#' + namespace + 'bttn_close').length > 0) ? this.lightbox.container.find('#' + namespace + 'bttn_close') : jQuery('<div id="' + namespace + 'bttn_close" class="icon-validation" />');
            this.lightbox.prev = (this.lightbox.container.find('#' + namespace + 'bttn_prev').length > 0) ? this.lightbox.container.find('#' + namespace + 'bttn_prev') : jQuery('<div id="' + namespace + 'bttn_prev" class="icon icon-nav-arrow-left"/>');
            this.lightbox.next = (this.lightbox.container.find('#' + namespace + 'bttn_next').length > 0) ? this.lightbox.container.find('#' + namespace + 'bttn_next') : jQuery('<div id="' + namespace + 'bttn_next" class="icon icon-nav-arrow-right"/>');
            this.registerEvents('init');
        },

        addToCache: function (element) {
            if ((jQuery(element).attr('data-link') || jQuery(element).attr('href') || jQuery(element).attr('data-src') || jQuery(element).attr('src')).match('jpg|gif|png|JPG|GIF|PNG|JPEG|jpeg|svg|SVG|tif|TIF|bmp|BMP')) {
                var content = jQuery('<img />')
                    .attr('src', (jQuery(element).attr('data-link') || jQuery(element).attr('href') || jQuery(element).attr('src')))
                    .attr('alt', jQuery(element).attr('alt'))
                    .attr('data-caption', jQuery(element).attr('data-caption'));
            } else if ((jQuery(element).attr('data-link') || jQuery(element).attr('href')).match('^#') && jQuery((jQuery(element).attr('data-link') || jQuery(element).attr('href'))).length > 0) {
                var content = jQuery(jQuery(element).attr('data-link') || jQuery(element).attr('href')).html();

            } else {
                var content = jQuery('<video class="vdo" controls muted />')
                    //.attr('src', (jQuery(element).attr('data-link') || jQuery(element).attr('href')) + "?autoplay=1")
                    .attr('src', (jQuery(element).attr('data-link') || jQuery(element).attr('href')))
                    .attr('width', jQuery(element).attr('data-width'))
                    .attr('height', jQuery(element).attr('data-height'))
                    .attr('allowfullscreen', 'true');
            }
            jQuery('<div class="' + namespace + 'frame" />').append(
                jQuery('<div class="' + namespace + 'innerFrame" />')
                    .append(jQuery('<span id="' + namespace + 'bttn_close" class="icon-validation" />').append(jQuery('<svg data-v-6a943414="" width="24" height="24" style="fill: white;" xmlns="http://www.w3.org/2000/svg" class="close-icon"><path d="M6.414 5A1 1 0 1 0 5 6.414L10.586 12 5 17.586A1 1 0 1 0 6.414 19L12 13.414 17.586 19A1 1 0 1 0 19 17.586L13.414 12 19 6.414A1 1 0 1 0 17.586 5L12 10.586 6.414 5Z"></path></svg>')))
                    .append(content)
                    .append((jQuery(element).attr('data-caption') !== undefined) ? jQuery('<div class="' + namespace + 'caption" />').html(jQuery(element).attr('data-caption')) : '')
                    .append(jQuery('<div id="lightboxed--bttn_prev" class="icon icon-nav-arrow-left bttn_prev"/>'))
                    .append(jQuery('<div id="lightboxed--bttn_next" class="icon icon-nav-arrow-right bttn_next"/>'))
            )
                .appendTo(this.cache.content);

            if (this.Obj.length > 1) {
                jQuery('<div class="' + namespace + 'thumb" />')
                    .append(
                        jQuery('<div class="' + namespace + 'thumb_border" />')
                            .append(jQuery('<img />').attr('src', (jQuery(element).attr('data-src') || jQuery(element).attr('src') || jQuery(element).attr('data-link') || jQuery(element).attr('href'))))
                    )
                    .appendTo(this.cache.thumbs);
            }
        },

        resizeContent: function () {
            var that = this;
            this.lightbox.content
                .width(jQuery(window).width() * this.lightbox.content.children().length)
                .height(this.cache.thumbs ? jQuery(window).height() - this.lightbox.thumbs.height() : jQuery(window).height())
                .children()
                .width(jQuery(window).width() - 2 * this.options.content.spacing)
                .height(this.lightbox.content.height());
            this.lightbox.content.children().each(function (index) {
                jQuery(this).css('left', jQuery(window).width() * index + that.options.content.spacing)
            });
        },

        open: function (event, element) {
            var that = this;
            if (this.cache.thumbs && this.options.thumbs !== false) {
                this.lightbox.thumbs
                    .width((this.options.thumbs.size + this.options.thumbs.spacing) * (this.cache.thumbs.children().length))
                    .height(this.options.thumbs.size + 2 * this.options.thumbs.spacing)
                    .html(this.cache.thumbs.clone(true).html())
                    .show()
                    .children()
                    .width(this.options.thumbs.size)
                    .height(this.options.thumbs.size)
                    .css('margin', this.options.thumbs.spacing + 'px ' + this.options.thumbs.spacing / 2 + 'px');
                this.registerEvents('thumbs');
                jQuery('.bttn_next').show();
                jQuery('.bttn_prev').show();
            }
            this.lightbox.content.html(this.cache.content.clone(true).html());
            if (this.lightbox.content.children().length > 1 && this.options.loop == true) {
                this.phantoms = {
                    left: jQuery(this.lightbox.content.children().get(this.lightbox.content.children().length - 1)).clone().addClass(namespace + 'phantom-left').prependTo(this.lightbox.content),
                    right: jQuery(this.lightbox.content.children().get(1)).clone().addClass(namespace + 'phantom-right').appendTo(this.lightbox.content)
                };
            }
            this.resizeContent();
            this.change(event, this.Obj.index(element));
            this.registerEvents('lightbox');
            this.lightbox.container.toggleClass(this.options.lightboxCloseClass + ' ' + this.options.lightboxOpenClass).fadeIn(this.options.delay);
            jQuery('html').css('overflow', 'hidden');
        },

        close: function (event) {
            var that = this;
            this.deregisterEvents();
            this.lightbox.container.toggleClass(this.options.lightboxCloseClass + ' ' + this.options.lightboxOpenClass).fadeOut(this.options.delay, function () {
                that.lightbox.container.attr('style', '');
                that.lightbox.content.html('').attr('style', '');
                that.lightbox.thumbs.html('').attr('style', '').hide();
                jQuery('.bttn_next').hide();
                jQuery('.bttn_prev').hide();
                that.index = false;
                jQuery('html').css('overflow', 'auto');
            });
        },

        change: function (event, element) {
            var that = this;

            var index = (typeof (element) == 'number') ? element : jQuery(element).index();
            if (this.lightbox.content.children().length == 1) {
                this.lightbox.content.children().addClass(namespace + 'active');
            }
            if (this.lightbox.content.children().length > 1) {
                var offset = (this.options.loop === true) ? jQuery(window).width() * -(index + 1) : jQuery(window).width() * -index;

                if (index == -1) {
                    index = this.lightbox.content.children().length - 3;
                    setTimeout(function () {
                        var offset = jQuery(window).width() * -(index + 1);

                    }, this.options.delay);
                } else if (index == this.lightbox.content.children().length - 2) {
                    index = 0;
                    setTimeout(function () {
                        var offset = jQuery(window).width() * -(index + 1);
                    }, this.options.delay);
                } else {
                    setTimeout(function () {
                        that.lightbox.content.css('transition', 'none');
                    }, this.options.delay);
                }
                this.lightbox.content.children().removeClass(namespace + 'active');
                jQuery(this.lightbox.content.children().get(index + 1)).addClass(namespace + 'active');
                this.lightbox.thumbs.children().removeClass(namespace + 'active');
                jQuery(this.lightbox.thumbs.children().get(index)).addClass(namespace + 'active');
                if (this.lightbox.thumbs.width() > jQuery(window).width()) {
                    var offset = -(index * (this.options.thumbs.size + this.options.thumbs.spacing)) - ((this.options.thumbs.size + this.options.thumbs.spacing) / 2);
                }
                if (jQuery('.vdo').length) {
                    jQuery(this.lightbox.content).each(function (t, r) {
                        if (jQuery(this).find('.vdo').parents().hasClass("lightboxed--active")) {
                            jQuery(this).find(".vdo")[1].load();
                            jQuery(this).find(".vdo")[1].play();
                        }
                        else {
                            jQuery(this).find(".vdo")[1].pause();
                        }
                    })
                }
            }
            this.index = index;
            jQuery('html').css('overflow', 'hidden');
        },

        next: function (event) {
            var that = this;
            this.change(event, this.index + 1);
        },

        prev: function (event) {
            var that = this;
            this.change(event, that.index - 1);
        },


        registerEvents: function (scope) {
            var that = this;
            if (scope == 'init') {
                this.Obj.each(function () {
                    jQuery(this).on('click', function (event) {
                        event.preventDefault();
                        that.open.call(that, event, this);
                    });
                });
            }
            if (scope == 'lightbox') {
                this.lightbox.close.on('click', function (event) {
                    that.close.call(that, event);
                });
                jQuery('#lightboxed--container').on('click', function (event) {
                    that.close.call(that, event);
                });
                jQuery('.icon-validation').on('click', function (event) {
                    that.close.call(that, event);
                });
                jQuery(".lightboxed--frame").click(function (e) {
                    e.stopPropagation();
                });
                jQuery(".lightboxed--frame, #lightboxed--bttn_next, #lightboxed--bttn_prev, #lightboxed--thumbs").click(function (e) {
                    e.stopPropagation();
                });
                jQuery(".lightboxed--innerFrame").click(function (e) {
                    if (jQuery('html').css('overflow', 'hidden')) {
                        jQuery('html').css('overflow', 'hidden');
                    };
                })
                jQuery('.bttn_next').on('click', function (event) {
                    that.next.call(that, event);
                });
                jQuery('.bttn_prev').on('click', function (event) {
                    that.prev.call(that, event);
                });
                jQuery(window).on('resize', function (event) {
                    that.resizeContent.call(that, event);
                });
            }
            if (scope == 'thumbs') {
                this.lightbox.thumbs.children().on('click', function (event) {
                    that.change.call(that, event, this);
                });
                jQuery('html').css('overflow', 'hidden');
            }
        },

        deregisterEvents: function () {
            var that = this;
            this.lightbox.close.off();
            jQuery('.bttn_next').off();
            jQuery('.bttn_prev').off();
            jQuery(window).off('resize');
            this.lightbox.content.off();
            this.lightbox.thumbs.children().off();
            this.lightbox.thumbs.off();
        }
    });

    $.fn.indexof = function (a) {
        var f;
        for (var i = 0; i < jQuery(this).length; i++) {
            if (jQuery(this).get(i) == a) {
                f = i;
            }
        }
        return f;
    };

    $.fn.lightboxed = function (options) {
        var createInstance = function () {
            if (!$.data(this, "Lightbox_" + LightboxFunc)) {
                $.data(this, "Lightbox_" + LightboxFunc, new Lightboxed(jQuery(this), options));
            }
        };
        var grouped = {};
        jQuery(this).filter('[rel]').each(function () {
            var group = jQuery(this).attr('rel');
            if (grouped.hasOwnProperty(group)) {
                grouped[group].push(this);
            } else {
                grouped[group] = [this];
            }
        });
        jQuery.each(grouped, createInstance);
        jQuery.each(jQuery(this).filter(':not([rel])'), createInstance);
        return this;
    };

    $.fn.indexOf = function (e) {
        var ret = false;
        var string = jQuery(e)[0].outerHTML;
        jQuery(this).each(function (i, e) {
            if (jQuery(this)[0].outerHTML == string) {
                ret = i;
                return false;
            }
        });
        return ret;
    };

    $.fn.lightboxed.defaults = {
        lightboxOpenClass: namespace + 'lightbox_open',
        lightboxCloseClass: namespace + 'lightbox_close',
        delay: 500,
        loop: true,
        thumbs: {
            size: 60,
            spacing: 10
        },
        content: {
            spacing: 10
        },
        swipe: {
            horizontalOffset: 0.2,
            verticalOffset: 0.33,
        }
    };

})(jQuery, window, document);
jQuery('.lightboxed').lightboxed();

//carousel resize click event 
$(document).on('click keypress', '#iconResize', function (event) {
    if (event.type === "click" || (event.type === "keypress" && event.key === "Enter")) {
        $('.image').each(function (index, value) {
            if ($(this).css('display') == 'block') {
                $(this).find('.lightboxed').click();
            }
        })
    }
})
//product benefit review scroll
ProductBenefitApnd(event);

//whole product card clickable 
$(".productBoxWrapper").click(function (event) {
    event.preventDefault();
    var productNameElement = $(this).find(".productBoxText .title a");
    var productGpimIDElement = $(this).find(".productBoxText .product-gpimid");
    var productUrlElement = $(this).find(".productBoxText .product-url");
    var goToProductTextElement = $(this).find('.productCTA a span');
    var productName = productNameElement.length > 0 ? productNameElement.text() : '';
    var productGpimID = productGpimIDElement.length > 0 ? productGpimIDElement.val() : '';
    var productUrl = productUrlElement.length > 0 ? productUrlElement.val() : '';
    var goToProductText = goToProductTextElement.length > 0 ? goToProductTextElement.text() : '';
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        "event": "ga4_product_click",
        "ga4_data": {
            "single_item_id": productGpimID,
            "single_item_name": productName,
            "link_text": goToProductText,
            "link_url": productUrl
        }
    });
    setTimeout(function () {
        window.location = $(event.currentTarget).find(".ctaBtn").attr("href");
    }, 50);
    return false;
});
//searc result page redirect issue fixed onclick
$(".search-result-content").click(function () {
    window.location = $(this).find(".search-article-link").attr("href");
    return false;
});
//card innerheight when review there
var alignmentText = function (event) {
    $('.productBoxWrapper').each(function () {
        var $lengthDetailColor = $(this).siblings('.productBoxWrapper').find('.detailColor');
        var lengthDetailAbsorbency = $(this).find('.detailAbsorbency');
        var $heightDetailColor = $(this).siblings('.productBoxWrapper').find('.productBoxMain').outerHeight();
        var $heightDetailAbsorbency = $(this).find('.detailAbsorbency').outerHeight();
        if (window.matchMedia('(min-width: 320px)').matches) {
            if ($(this).find('.reviewSnnipt, .js-review').length) {
                if (!lengthDetailAbsorbency.length) {
                    $(this).find('.productBoxFooter').css('flex', 'unset');
                    if ($('.searchResultSection').length) {
                        $(this).find('.productBoxFooter').css('justify-content', 'end');
                    }

                } else {
                    $(this).find('.productBoxFooter').css('flex', '1 1 100%');
                }
                if (lengthDetailAbsorbency.length > 0 && $lengthDetailColor.length > 0) {
                    $(this).find('.productBoxFooter').css('flex', '1 1 100%');
                } else {
                    $(this).find('.productBoxFooter').css('flex', 'unset');
                    if ($('.searchResultSection').length) {
                        $(this).find('.productBoxFooter').css('justify-content', 'end');
                    }
                }
            } else {
                if (!lengthDetailAbsorbency.length) {
                    $(this).find('.productBoxFooter').css('flex', 'unset');
                    if ($('.searchResultSection').length) {
                        $(this).find('.productBoxFooter').css('justify-content', 'end');
                    }
                } else {
                    $(this).find('.productBoxFooter').css('flex', '1 1 100%');
                }
                if (lengthDetailAbsorbency.length > 0 && $lengthDetailColor.length > 0) {
                    $(this).find('.productBoxFooter').css('flex', '1 1 100%');
                } else {
                    $(this).find('.productBoxFooter').css('flex', 'unset');
                    if ($('.searchResultSection').length) {
                        $(this).find('.productBoxFooter').css('justify-content', 'end');
                    }
                }
            }
        }
        if (window.matchMedia('(min-width:991px)').matches) {
            if ($(this).find('.reviewSnnipt, .js-review').length) {
                if (lengthDetailAbsorbency.length > 0 && $lengthDetailColor.length > 0) {
                    $(this).find('.productBoxFooter').css('flex', '1 1 100%');
                } else {
                    $(this).find('.productBoxFooter').css('flex', 'unset');
                    if ($('.searchResultSection').length) {
                        $(this).find('.productBoxFooter').css('justify-content', 'end');
                    }
                }
            } else {
            }
        }
    });
}

$(window).resize(function (event) {
    alignmentText(event);
});

$.fn.jQueryEqualHeight = function (innerDiv) {
    if (innerDiv == undefined) {
        innerDiv = '.productBoxWrapper';
    }
    var currentTallest = 0,
        currentRowStart = 0,
        rowDivs = new Array(),
        topPosition = 0;
    $(this).each(function () {
        $(this).find(innerDiv).height('auto')
        topPosition = $(this).position().top;
        if (currentRowStart != topPosition) {
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].find(innerDiv).height(currentTallest);
            }
            rowDivs.length = 0;
            currentRowStart = topPosition;
            currentTallest = $(this).find(innerDiv).height();
            rowDivs.push($(this));
        } else {
            rowDivs.push($(this));
            currentTallest = (currentTallest < $(this).find(innerDiv).height()) ? ($(this).find(innerDiv).height()) : (currentTallest);
        }
        for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].find(innerDiv).height(currentTallest);
        }
    });
};
$(window).on('load', function () {
    setTimeout(function () {
        equal_height();
    }, 200);
});
$(window).resize(function () {
    setTimeout(function () {
        equal_height();
    }, 200);
});
function equal_height() {
    // Equal Card Height, Text Height and Title Height
    $('.productBoxWrapper').jQueryEqualHeight('.productBoxMain .productBoxText .title');
    $('.productBoxWrapper').jQueryEqualHeight('.productBoxMain .productBoxText .paraDiv');

    if ($('.searchResultSection').length) {
        $('.productBoxWrapper').jQueryEqualHeight('.detailAbsorbency');
        $('.productBoxWrapper').jQueryEqualHeight('.detailColor');
        $('.productBoxWrapper').jQueryEqualHeight('.productBoxFooter');
    }

    if ($('.js-review').length) {
        setTimeout(function () {
            $('.productBoxWrapper').jQueryEqualHeight('.productBoxMain .productBoxText .js-review');
            if ($('.productBoxMain .productBoxText .js-review').height() > 0) {
                $('.productBoxMain .productBoxText .js-review').css('opacity', '1');
            }
        }, 200);
    }
    if ($('.js-review').length && $('.pr-no-reviews').css('display') == 'none') {
        setTimeout(function () {
            $('.productBoxWrapper').jQueryEqualHeight('.productBoxMain .productBoxText .js-review');
            if ($('.productBoxMain .productBoxText .js-review').height() > 0) {
                $('.productBoxMain .productBoxText .js-review').css('opacity', '1');
            }
        }, 200);
    }
}


$(document).on('click', function (e) {
    setTimeout(function () {
        equal_height();
    }, 500)
});
/* $('button.toggleGridbtn, .js-product-filter-apply, .close-icon, .product-filter-btn, .js-product-filter-reset-all').on('click', function() {
    setTimeout(function() {
        equal_height();
    }, 500);
})
 */
$('button.toggleListbtn, .js-product-filter-apply, .close-icon').on('click', function (event) {
    $('.productBoxMain .productBoxText .title, .productBoxMain .productBoxText .paraDiv, .productBoxMain .productBoxText .js-review').css('height', 'auto');
})

// search functionality
if ($('.search-result-content').length) {
    $('.search-result-content').each(function () {
        $(this).parent().removeClass('productBoxWrapper');
        $(this).parents('.cardwrapper').removeClass('equal-card');
        $(this).parents('.ProductDetailCard').removeClass('ProductDetailCard');
    });
}
//mobile search

// when navigation out of search page 
if (window.location.href.indexOf("?") > -1) {
} else {
    localStorage.removeItem('product');
}
//Iphone selection option issue
if ($('.select2-results__options').length) {
    var selectedProduct = localStorage.getItem("product");
    var selectedOptionText;
    $(".select2-results__options").first().find(":selected").removeAttr("selected");
    $(".select2-results__options").first().prop(':selected', false);
    $(".select2-results__options").first().prop('selected', false);
    $(".select2-results__options").find("option").each(function () {
        if ($(this).text().trim() == selectedProduct) {
            selectedOptionText = $(this).text();
            $('.selectedOption').text(selectedOptionText);
            $(this).prop('selected', true);
        }
    });
    $('.select2-results__options').on('change', function () {
        var url = $(this).children('option:selected').attr('href');
        localStorage.setItem("product", $(this).children('option:selected').text());
        if (url) {
            window.location = url;
        }
    });
}

var searchTerm = '';
var searchQueryValue = '';
if ($('.js-input-search-main').length > 0) {
    $('.site-search-page .js-btn-site-search').attr('disabled', $('.js-input-search-main').val().length === 0);
    var searchQueryValue = $('.js-input-search-main').val();
}

function onSearchInputFocus(event) {
    if ($(event.currentTarget).parents('.js-search--desktop').length > 0) {
        $('.logo').addClass('u-disableLink');
        $('.site-search-box').siblings().addClass('u-display-none');
        $('.site-name, .header-details').addClass('u-display-none');
        $('.js-search--desktop').addClass('site-search-box--is-expanded');
        $('.js-collapse-search-header').removeClass('u-display-none');

        if ($('.js-overlay-search-bg').length === 0) {
            $('body')
                .addClass('u-overflow-hidden')
                .prepend('<div class="overlay-search-bg js-overlay-search-bg"></div>');
        }
    }
}

function onSearchDeactivateFromHeader(event) {
    searchTerm = '';

    if ($(event.target).parents('.js-collapse-search-header').length > 0) {
        collapseSearchHeader();
    }

    if ($(event.target).parents('.js-collapse-search-main').length > 0) {
        $('.js-collapse-search-main').addClass('u-display-none');
        $('.js-input-search-main').val('');
    }

    $('.logo').removeClass('u-disableLink');
    $('.js-btn-site-search').attr('disabled');
}

function onSearchDeactivate() {
    window.location.href = urlSearch;
}

function onSearchTermChange(event) {
    searchTerm = event.target.value;

    $(event.currentTarget.classList.contains('js-input-search-header') ?
        '.js-reset-search-header' :
        '.js-reset-search-main'
    ).toggleClass('u-display-none', searchTerm.length === 0);

    // Redirect action on pressed enter key on keyboard
    if (event.which === 13) {
        var encodedsearchtext = encodeURIComponent(searchTerm);
        window.location.href = urlSearch + '?q=' + encodedsearchtext
    }

    // Close search input field on pressed escape key on keyboard
    if (event.which === 27) {
        searchTerm = '';

        // Close overlay-background on pressed escape key on keyboard
        if ($(event.target).siblings('.js-collapse-search-header').length > 0) {
            $('.js-input-search-header').val('');
            $('.js-collapse-search-header').addClass('u-display-none');
            // $('.js-search-overlay').removeClass('u-display-none');
            $('.logo').removeClass('u-disableLink');
            $('.site-search-box').siblings().removeClass('u-display-none');
            $('.site-name, .header-details').removeClass('u-display-none');
            $('.js-search--desktop').removeClass('site-search-box--is-expanded');
            $('.js-overlay-search-bg').remove();
            $('.js-input-site-search').blur();
        }
    }

    $('.js-btn-site-search').attr('disabled', searchTerm.length === 0);
}

$('.js-input-site-search, #searchInput').on('click', function (event) {
    onSearchInputFocus(event);
});


var urlSearch = $('.site-search-box').attr('href');

function onSearch() {
    window.location.href = urlSearch + '?q=' + searchTerm
}

function onMobileTabChange(event) {
    window.location.href = $('.input-site-search').val();
}

function resetInputField(isHeader) {
    var searchTerm = '';

    $(isHeader ? '.js-input-search-header' : '.js-input-search-main').val('');
    $(isHeader ? '.js-reset-search-header' : '.js-reset-search-main').addClass('u-display-none');
    $(isHeader ? '.js-search--desktop .js-btn-site-search' : '.js-site-search-page .js-btn-site-search').attr('disabled', searchTerm.length === 0);
}

function collapseSearchHeader() {
    var searchTerm = '';
    $('.js-input-search-header').val('');
    $('.js-collapse-search-header').addClass('u-display-none');
    $('.js-reset-search-header').addClass('u-display-none');
    $('.site-search-box').siblings().removeClass('u-display-none');
    $('.site-name, .header-details').removeClass('u-display-none');
    $('.js-search--desktop').removeClass('site-search-box--is-expanded');
    $('.js-overlay-search-bg').remove();
    $('body').removeClass('u-overflow-hidden');
    $('.js-btn-site-search').attr('disabled', searchTerm.length === 0);
}

function clickOutsideSearchElement(event) {
    if (!$(event.target).closest('.header').length) {
        collapseSearchHeader();
    }
}

function onFocusOutCollapseBtn() {
    collapseSearchHeader();
    const $nextHeaderItemToFocus = $('.header-items a');

    if ($nextHeaderItemToFocus.length > 0) {
        $($nextHeaderItemToFocus[0]).focus();
    }
}

/* function onSearchDeactivateKeyup(event) {
   if (event.which === eventKeyCodes.enter) {
      onSearchDeactivateFromHeader(event);
   }
} */

$('.js-btn-site-search').on('click', function (event) {
    onSearch();
})

$('.js-input-site-search, #searchInput').on('keyup', function (event) {
    onSearchTermChange(event)
})
$('.js-input-site-search, #searchInput').on('focus', function (event) {
    onSearchInputFocus(event)
})
$('.js-input-site-search').on('blur', function (event) {

})
$('.js-reset-search-header').on('click', function (event) {
    resetInputField(true);
});

$('.js-collapse-search-header').on('click', function (event) {
    onSearchDeactivateFromHeader(event)
})
$('#searchInput').on('change keyup', function () {
    if ($('#searchInput').val().length > 0) {
        $('.ProductFinder-search').removeClass('disabled')
    }
    else {
        $('.ProductFinder-search').addClass('disabled')
    }
})
$(window).on('load', function () {
    $('#searchInput').val('');
})
$('.js-collapse-search-header').on('keyup', function (event) {
    onSearchDeactivateKeyup(event)
})
$('.js-collapse-search-header').on('focusout', function (event) {
    onFocusOutCollapseBtn(event)
})
$('.js-reset-search-main').on('click', function (event) {
    onSearchDeactivate();
})

$('.js-reset-search-header').on('click', function (event) {
    resetInputField(true);
});

$(document).on('click', function (event) {
    clickOutsideSearchElement(event);
})
//search input box top border condition
if ($('.site-search-box--is-mobile').prev().length) {
    $('.site-search-box--is-mobile').css('border-top', '1px solid #dcdada');
}
else {
    $('.site-search-box--is-mobile').css('border-top', 'none');
}

//H1 Tag change in review section
window.onload = function () {
    setTimeout(function () {
        $(".pr-headline").replaceWith($('<h3 class="pr-headline">' + $(".pr-headline").html() + '</h3>'));
        $(".pr-rd-review-total").replaceWith($('<h3 class="pr-rd-review-total">' + $(".pr-rd-review-total").html() + '</h3>'));
        $(".pr-review-snapshot-block-headline").replaceWith($('<h3 class="pr-review-snapshot-block-headline">' + $(".pr-review-snapshot-block-headline").html() + '</h3>'));
        $(".pr-review-snapshot-snippets-headline").replaceWith($('<h3 class="pr-review-snapshot-snippets-headline">' + $(".pr-review-snapshot-snippets-headline").html() + '</h3>'));
    }, 800);
};

//Anounce form's dropdown 
var formAnnounce = function () {
    setTimeout(function () {
        var labelName = $('.sod_select').parents('.form-field').find('.field-label').text();
        $('.sod_select').attr("aria-label", "select dropdown value from" + labelName);
        $('.sod_label').attr("aria-live", "polite");
    }, 500);

}
$(document).ready(function () {
    if ($("#SampleContinueButton").length) {
        $("#SampleContinueButton").click(function () {
            formAnnounce();
        });
    }
    formAnnounce();
});

$(document).ready(function () {
    $('.mobile-menu-trigger').on('click', function () {
        setTimeout(function () {
            var labelName = $('#location').parents('.sod_select');
            var sodlabelName = $(labelName).find('.sod_label').text();
            $(labelName).attr({ "aria-label": sodlabelName + " dropdown", });
        }, 500);
    });
});
//GA4 for email click event
$(document).ready(function () {
    $('a').click(function () {
        var href = $(this).attr('href');
        if (href && href.startsWith('mailto:')) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "ga4_email_click"
            });
        }
    });
});
//GA4 for pdf file event
$('body').on('click', 'a, button', function (event) {
    var $target = $(this);
    var url = $target.attr('href');
    if (url && url.toLowerCase().endsWith('.pdf')) {
        var file_name = url.split('/').pop();

        // Initialize linkText
        var linkText = '';

        // Check for 'component-btn' class and fetch text from <div class="btn">
        if ($target.find('.component-btn .btn').length) {
            linkText = $target.find('.component-btn .btn').text().trim();
        } else {
            // Fallbacks for other cases
            var rawText = $target.text() ||
                $target.attr('aria-label') ||
                $target.attr('title') || '';
            linkText = rawText.replace(/\s+/g, ' ').trim();
        }

        // Use the domain of the current page instead of the clicked link
        var domain = window.location.hostname;

        // Determine file type
        var file_type = $('.ProductDetails-backgroundColor').length ? 'Product download' : 'General download';

        // Push GA4 Event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'ga4_file_download',
            ga4_data: {
                file_extension: 'PDF',
                file_name: file_name,
                link_text: linkText,
                link_url: url,
                link_domain: domain, // Current domain
                file_type: file_type
            }
        });
    }
});




// search autocomplete
$(document).ready(function () {
    const autocompleteInput = $(".input-site-search");
    const autocompleteList = $(".autocompleteList");
    var contextPathValue = $('#context-path-value').attr('data-context-path');
    var URL = window.location.origin + contextPathValue + "/api/Services/Search/GetSuggestions";
    autocompleteInput.on("keyup", function (event) {
        var searchTermN = $(this).val().trim();
        if (searchTermN.length > 0 && URL != "") {
            $.ajax({
                type: "POST",
                url: URL,
                dataType: "json",
                data: {
                    text: searchTermN
                },
                success: function (jsonData) {
                    myData = JSON.parse(jsonData);
                    const autocompleteItems = myData.map(function (item) {
                        return `<li>${item}</li>`;
                        autocompleteList.appendChild(`<li>${item}</li>`);
                    }).join("");
                    event.target.nextElementSibling.innerHTML = autocompleteItems;
                    event.target.nextElementSibling.style.display = 'block';
                },
            });
        } else {
            $(this).find(".autocompleteList").hide();
        }
    });
    autocompleteList.on("click", "li", function () {
        autocompleteInput.val($(this).text());
        var url = window.location.href;
        if (window.location.href.indexOf("?") > -1 && !$('.js-input-search-header').length) {
            var indexV = url.indexOf("?");
            var substringV = url.substring(indexV);
            var NewURL = url.replace(substringV, "");
            url = NewURL;
            var encodedSearchText = encodeURIComponent($(this).text());
            window.location.href = NewURL + '?q=' + encodedSearchText;
        } else if ($(event.currentTarget.classList.contains('js-input-search-header'))) {
            var urlSearch = $('.site-search-box').attr('href')
            var encodedSearchText = encodeURIComponent($(this).text());
            window.location.href = urlSearch + '?q=' + encodedSearchText;
        } else {
            var encodedSearchText = encodeURIComponent($(this).text());
            window.location.href = url + '?q=' + encodedSearchText;
        }
        $(this).find(".autocompleteList").hide();
    });
    // Hide the dropdown if clicked outside	
    $(document).on("click", function (event) {
        if (!autocompleteInput.is(event.target) && !autocompleteList.is(event.target)) {
            $(this).find(".autocompleteList").hide();
        }
    });
});

$(document).ready(function () {
    $(document).on("click", ".RecommendedProduct a.Button--default, .ConsiderProduct-container a.Button--default", function (event) {
        event.preventDefault();
        var parentContainer = $(this).closest(".RecommendedProduct, .ConsiderProduct-container");
        var productName = parentContainer.find("h4").first().text().trim();
        var productGpimID = parentContainer.find(".articleNumber").val();
        var productUrl = $(this).attr("href");
        var goToProductTextElement = $(this).find('span');
        var goToProductText = goToProductTextElement.length > 0 ? goToProductTextElement.text() : '';
        var protocol = window.location.protocol;
        var hostname = window.location.hostname;
        var externalUrl = /^(?:(https?|ftp):\/\/|www\.)/;

        if (!externalUrl.test(productUrl)) {
            productUrl = protocol + "//" + hostname + productUrl;
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "ga4_product_click",
            "ga4_data": {
                "single_item_id": productGpimID || "Unknown ID",
                "single_item_name": productName || "Unknown Product",
                "link_text": goToProductText,
                "link_url": productUrl || "Unknown URL"
            }
        });
        if (productUrl) {
            window.location = productUrl;
        }
        return false;
    });
});

//ga4 external link
$(document).ready(function () {
    // Function to check if a link is external   
    function isExternal(link) {
        return link.hostname && link.hostname !== window.location.hostname;
    }

    // Add a click event listener to all anchor tags
    $('a').click(function () {
        var textfile_name = $(this).text().trim();
        var linkUrl = $(this).attr('href');

        // Skip the event if the URL ends with '.pdf'
        if (linkUrl && linkUrl.toLowerCase().endsWith('.pdf')) {
            return; // Do not fire GA4 event for PDF links
        }

        // Extract the link domain
        var linkDomain = $(this).prop('href', linkUrl).prop('hostname');
        if (linkDomain && linkDomain.indexOf('www') > -1) {
            linkDomain = linkDomain.replace('www.', '');
        }

        // Check if the link is external and not a JavaScript link
        if (isExternal(this) && linkUrl && linkUrl.toLowerCase() !== 'javascript(void)' && linkUrl.toLowerCase() !== "javascript:void(0)") {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': "ga4_click",
                'ga4_data': {
                    "link_url": linkUrl,
                    "link_domain": linkDomain,
                    "link_text": textfile_name,
                    "outbound": 'true'
                }
            });
        }
    });
});

